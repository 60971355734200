/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';
import {useTheme} from '@anywhere-expert/colors';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => {
        const {
            colors: {
                neutral: {black},
            },
        } = useTheme();
        return (
            <BaseIcon {...baseIconProps} ref={ref}>
                <circle cx="12" cy="12" r="9" stroke={black} strokeOpacity=".4" strokeWidth="2" />
                <circle cx="12" cy="16" fill={black} fillOpacity=".4" r="1" />
                <path d="M11 7h2v7h-2z" fill={black} fillOpacity=".4" />
            </BaseIcon>
        );
    })
);

export default Icon;
