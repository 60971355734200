/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} viewBox="0 0 16 16" ref={ref}>
            <path
                d="M8 13.167H1.905l.891-2.186.15-.368-.192-.35A4.66 4.66 0 0 1 2.166 8c0-2.773 2.527-5.167 5.833-5.167 3.307 0 5.834 2.394 5.834 5.167 0 2.773-2.527 5.167-5.834 5.167z"
                fill="none"
                stroke={color}
                strokeWidth="1.6667"
            />
        </BaseIcon>
    ))
);

export default Icon;
