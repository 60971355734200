import React, {forwardRef} from 'react';
import styled from 'styled-components';

const TextArea = styled.textarea<{isInputValid: boolean}>`
    padding: 10px;
    font-size: 13px;
    resize: none;
    transition: border 200ms linear;
    border: ${({theme, isInputValid}) =>
        `1px solid ${isInputValid ? theme.colors.contrast.caption : theme.colors.semantic.yellow.normal}`};
    background-color: ${({theme}) => theme.colors.neutral.white};
    color: ${({theme}) => theme.colors.neutral.black};
    line-height: 20px;
    margin-top: 14px;
    min-height: 10px;
    outline: none;
    border-radius: 4px;
    margin-bottom: 4px;
    &:focus {
        outline-width: 0;
    }
    &::placeholder {
        color: ${({theme}) => theme.colors.neutral.grey.deep};
    }
    ${({disabled}) =>
        disabled &&
        `
        opacity: 0.6;
    `};
` as React.ComponentType<any>;

export type CoreTextAreaProps = {
    isInputValid?: boolean;
    disabled?: boolean;
};

export const CoreTextArea = forwardRef(
    (
        {
            disabled,
            isInputValid,
            ...others
        }: CoreTextAreaProps &
            React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
        ref
    ) => <TextArea ref={ref} isInputValid={isInputValid} disabled={disabled} {...others} />
);
