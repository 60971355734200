import * as React from 'react';
import styled from 'styled-components';
import {useTheme} from '@anywhere-expert/colors';
import CoreText from './CoreText';
import {CheckMarkIcon} from '@anywhere-expert/icons';
import CoreIcon, {CoreIconProps} from './CoreIcon';
import {useCallback, useEffect, useRef} from 'react';

interface CoreListItemContainerProps {
    isActive?: boolean;
    disabled?: boolean;
    isSelectable?: boolean;
    hasSelectedIcon?: boolean;
}

export const CoreListItemContainer = styled.li<CoreListItemContainerProps>`
    text-align: left;
    padding-right: 8px;
    outline: none;
    align-items: center;
    display: flex;
    flex-direction: row;
    cursor: ${({isSelectable}) => (isSelectable ? 'pointer' : 'default')};
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 4px;
    margin: 0px 4px;
    background-color: ${({isActive, theme}) => (isActive ? theme.colors.contrast.hover : 'unset')};
    ${({disabled}) =>
        disabled &&
        `
        pointer-events: none;
        cursor: default;
        opacity: 0.2;
    `};
    ${({hasSelectedIcon}) =>
        hasSelectedIcon &&
        `
        justify-content: space-between;
    `};
`;

const IconAndTextContainer = styled.div`
    display: flex;
`;

const CoreListItemText = styled(CoreText)`
    margin-left: ${({hasIcon}) => (hasIcon ? '8px' : '12px')};
`;

export type PropsFromList = {
    index: number;
    itemType: string;
    activeIndex: number;
    selectedIndex: number;
};

export type ExternalProps = React.LiHTMLAttributes<HTMLLIElement> & {
    text?: string;
    Icon?: CoreIconProps['icon'];
    tag?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
    hasSelectedIcon?: boolean;
    id?: string;
    children?: any;
    disabled?: boolean;
};

export type CoreListItemProps = PropsFromList & ExternalProps & {forwardedRef: any};

const CoreListItem = ({
    activeIndex,
    hasSelectedIcon,
    index,
    children,
    tag,
    text,
    Icon,
    disabled,
    onClick,
    selectedIndex,
    ...others
}: CoreListItemProps) => {
    const ref = useRef<HTMLLIElement>(null);

    const handleSelection = useCallback(
        (e?) => {
            // We don't want the href to open twice
            if (tag && tag === 'a') {
                e && e.preventDefault();
            }
            if (!disabled && onClick) {
                onClick(e);
            }
        },
        [disabled, onClick, tag]
    );
    useEffect(() => {
        const isActive = activeIndex === index;

        if (!isActive || disabled) {
            return;
        }

        //@ts-ignore
        if (ref.current?.scrollIntoViewIfNeeded) {
            //@ts-ignore
            ref.current.scrollIntoViewIfNeeded();
        } else {
            ref.current?.scrollIntoView();
        }
    }, [activeIndex, index, disabled, handleSelection]);

    useEffect(() => {
        if (selectedIndex !== index) {
            return;
        }

        handleSelection();
    }, [selectedIndex, index, handleSelection]);
    const {
        colors: {
            neutral: {black},
        },
    } = useTheme();

    return (
        <CoreListItemContainer
            ref={ref}
            tabIndex={index}
            isActive={activeIndex === index && !disabled}
            isSelectable={!!onClick}
            hasSelectedIcon={hasSelectedIcon}
            disabled={disabled}
            onClick={handleSelection}
            as={tag}
            {...others}
        >
            <IconAndTextContainer>
                {Icon && (
                    <CoreIcon size="s" style={{marginLeft: '12px'}} icon={Icon} iconProps={{color: black, size: 's'}} />
                )}
                {text && (
                    <CoreListItemText hasIcon={!!Icon} textType="primary" size="m">
                        {text}
                    </CoreListItemText>
                )}
            </IconAndTextContainer>
            {hasSelectedIcon && (
                <CoreIcon
                    size="s"
                    icon={CheckMarkIcon}
                    iconProps={{color: black, size: 's'}}
                    style={{alignSelf: 'flex-end', marginRight: '4px'}}
                />
            )}
            {children}
        </CoreListItemContainer>
    );
};

CoreListItem.defaultProps = {
    itemType: 'CoreListItem',
};

export default CoreListItem as React.ComponentType<ExternalProps & any>;
