/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.59 15L12 10l-4.59 5L6 13.59l6-6 6 6L16.59 15z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
