import React, {FunctionComponent} from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getIconSize} from '@anywhere-expert/icons';
import {CoreButtonProps, IconProps, IconSide} from './types';
import {getVariantStyle, getSizeStyle} from './utils';
import {getTextSize, TextSizeProps} from '../CoreText';

const defaultIconProps: CoreButtonProps['iconProps'] = {
    side: 'left',
    color: 'currentcolor',
};

const BaseButton = styled.button<{textSize: TextSizeProps}>`
    position: relative;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    cursor: pointer;
    font-family: Roboto;
    min-width: 80px;
    border-radius: 100px;
    font-size: ${({textSize}) => textSize.size};
    line-height: ${({textSize}) => textSize.height};
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
    user-select: none;
    background-color: transparent;

    &:disabled {
        cursor: default;
        pointer-events: none;
    }
`;

const LoadingOverlay = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
`;

const Container = styled(
    ({variant, color, colorOverride, innerRef, iconSide, sizeStyle, isInProgress, textSize, ...rest}) => (
        <BaseButton ref={innerRef} textSize={textSize} {...rest} />
    )
)`
    height: ${({sizeStyle}) => sizeStyle.height}px;
    padding: 0 ${({sizeStyle}) => sizeStyle.paddingWithoutIcon}px;

    ${({iconSide, sizeStyle}) => iconSide && `padding-${iconSide}: ${sizeStyle.paddingWithIcon}px`};

    ${p => getVariantStyle(p)};

    ${({isInProgress}) =>
        isInProgress &&
        `
        cursor: default;
        pointer-events: none;
    `};
`;

const TextContainer = styled(({isInProgress, ...rest}) => <span {...rest} />)`
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
    width: 100%;
    opacity: ${({isInProgress}) => (isInProgress ? 0 : 1)};
    transition: opacity 0.2s;
`;

const Text = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const CoreButton: FunctionComponent<CoreButtonProps> = React.forwardRef(
    (
        {
            variant = 'contained',
            text,
            Text: CustomTextComponent,
            disabled = false,
            icon,
            iconProps,
            className,
            style,
            onClick,
            color = 'default',
            size = 'm',
            isActive = false,
            isInProgress = false,
            colorOverride,
            ...rest
        }: CoreButtonProps,
        ref
    ) => {
        const {iconSize, ...sizeStyle} = getSizeStyle(variant, size);
        const mergedIconProps = {...defaultIconProps, size: iconSize, ...iconProps};
        const renderedIcon = renderIcon(icon, mergedIconProps);
        const textSize = getTextSize(size);
        const iconSide =
            (renderedIcon &&
                ((mergedIconProps.side === 'left' && 'left') || (mergedIconProps.side === 'right' && 'right'))) ||
            undefined;

        return (
            <Container
                variant={variant}
                color={color}
                colorOverride={colorOverride}
                disabled={disabled}
                className={className}
                style={style}
                onClick={onClick}
                innerRef={ref}
                sizeStyle={sizeStyle}
                iconSide={iconSide}
                data-is-active={isActive}
                isInProgress={isInProgress}
                textSize={textSize}
                {...rest}
            >
                <>
                    <TextContainer isInProgress={isInProgress}>
                        {iconSide === 'left' ? renderedIcon : undefined}
                        {CustomTextComponent ? <CustomTextComponent /> : <Text className="buttonText">{text}</Text>}
                        {iconSide === 'right' ? renderedIcon : undefined}
                    </TextContainer>
                    {isInProgress && (
                        <LoadingOverlay>
                            <CircularProgress size={getIconSize(size)} color="inherit" />
                        </LoadingOverlay>
                    )}
                </>
            </Container>
        );
    }
);

export default CoreButton;

function renderIcon(Icon: CoreButtonProps['icon'], iconProps: IconProps) {
    if (!Icon) {
        return undefined;
    }

    const {side, style, ...rest} = iconProps;

    return <Icon style={{...getIconMargin(side!), ...style}} {...rest} />;
}

function getIconMargin(side: IconSide) {
    return side === 'left' ? {marginRight: '4px'} : {marginLeft: '4px'};
}
