/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.166 18h-6.973L8 16.323V11h1c.4 0 .762-.238.919-.606L12.66 4H14v4a1 1 0 0 0 1 1h5.802l-1.636 9zM6 18H4v-8h2v8zM22.768 7.359A1.002 1.002 0 0 0 22 7h-6V3a1 1 0 0 0-1-1h-3a.999.999 0 0 0-.918.605L8.341 9H8a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-.522l3.629 1.451c.118.047.244.071.371.071h8a1 1 0 0 0 .984-.821l2-11a.997.997 0 0 0-.216-.82z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
