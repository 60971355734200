/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.774 6.633l-9.7 11.855-4.781-4.78 1.414-1.415 3.219 3.219 8.3-10.145 1.548 1.266z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
