/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 4H4v16h16V4H7zM2 4v16c0 1.102.897 2 2 2h16c1.103 0 2-.898 2-2V4c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2zm6.442 8.304a.7.7 0 0 0 .7.7h.213a1.021 1.021 0 0 1-1.955-.412V11.42a1.02 1.02 0 0 1 1.81-.649c.268.326.794.326 1.056.099s.262-.71 0-1.017A2.42 2.42 0 0 0 6 11.42v1.17a2.42 2.42 0 0 0 4.842 0v-.287a.7.7 0 0 0-.7-.7h-1a.7.7 0 0 0-.7.7zM12.5 9.016a.7.7 0 0 0-.7.7v4.596a.7.7 0 1 0 1.4 0V9.716a.7.7 0 0 0-.7-.7zm3.2 2.283h1.191a.7.7 0 1 1 0 1.4H15.7v1.613a.7.7 0 1 1-1.4 0V9.7A.7.7 0 0 1 15 9h2.487a.7.7 0 1 1 0 1.4H15.7v.899z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
