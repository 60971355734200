/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<SVGSVGElement>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M14.2752 9.25078C14.2752 10.8258 14.2752 11.5383 14.2752 12.9258C14.2752 13.6383 13.6752 14.2383 12.9627 14.2383H8.16274C7.86274 14.2383 7.56275 14.3508 7.33775 14.5383C6.66275 15.1008 5.23774 16.3008 4.93774 16.6008C4.86274 16.7133 4.78774 16.7508 4.71274 16.7883C4.56274 16.9008 4.45024 16.7133 4.48774 16.5258L4.93774 14.2008H3.73774C3.06274 14.2008 2.53774 13.7133 2.46274 13.0383C2.38774 12.3633 2.31274 11.5008 2.31274 10.9008C2.31274 9.28828 2.42524 7.18828 2.50024 5.87578C2.53774 5.23828 3.06274 4.71328 3.73774 4.67578C5.72524 4.56328 7.71274 4.52578 9.70024 4.56328C10.7127 4.56328 11.4627 4.45078 12.4752 4.52578C13.0752 4.56328 13.7127 4.75078 14.0127 5.23828C14.2002 5.53828 14.2377 5.91328 14.2377 6.25078C14.3127 7.11328 14.2752 8.46328 14.2752 9.25078Z"
                stroke={color}
                strokeWidth="0.75"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.6128 8.38818C16.4128 8.38818 17.8378 8.42568 19.6378 8.50068C20.2753 8.53818 20.8003 9.06318 20.8753 9.70068C20.9503 10.9757 21.0628 13.0757 21.0628 14.7257C21.0628 15.3257 20.9878 16.1882 20.9128 16.8632C20.8378 17.5382 20.2753 18.0257 19.6378 18.0257H18.4378L18.8878 20.3507C18.9253 20.5382 18.8128 20.6882 18.6628 20.6132C18.5878 20.5757 18.5128 20.5007 18.4378 20.4257C18.1753 20.1257 16.7128 18.9257 16.0378 18.3632C15.8128 18.1757 15.5128 18.0632 15.2128 18.0632H10.7878C10.0753 18.0632 9.47534 17.4632 9.47534 16.7507C9.47534 15.7382 9.47534 15.4757 9.47534 14.6132"
                stroke={color}
                strokeWidth="0.75"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.5127 17.126C9.7002 17.7635 10.5252 18.101 11.0877 18.1385C12.3252 18.176 13.8252 18.176 15.0627 18.2135C15.2877 18.2135 15.5127 18.251 15.7377 18.2885C15.9627 18.3635 16.8252 18.926 17.2752 19.376"
                stroke={color}
                strokeWidth="0.75"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.62524 14.6135C11.0877 14.5385 11.8377 14.501 13.0377 14.3135"
                stroke={color}
                strokeWidth="0.75"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.6252 18.2507C19.4502 18.0632 20.2002 18.2507 20.6127 17.5757"
                stroke={color}
                strokeWidth="0.75"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.48779 7.41336C6.55029 7.26336 9.70029 7.22586 11.9503 7.33836"
                stroke={color}
                strokeWidth="0.5625"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.60034 9.32598C6.77534 9.25098 9.92534 9.28848 12.0628 9.25098"
                stroke={color}
                strokeWidth="0.5625"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.90015 11.2757C6.92515 11.2007 8.65014 11.3132 10.6751 11.2007"
                stroke={color}
                strokeWidth="0.5625"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.3877 11.1632C15.6252 11.1257 17.2377 11.2007 18.5127 11.1632"
                stroke={color}
                strokeWidth="0.5625"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3252 13.1881C17.0127 13.2256 15.5502 13.0756 14.2002 13.1881"
                stroke={color}
                strokeWidth="0.5625"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M14.5752 15.4383C15.8127 15.3633 16.5627 15.4008 18.4377 15.4383"
                stroke={color}
                strokeWidth="0.5625"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </BaseIcon>
    ))
);

export default Icon;
