import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.33337 7.3335C5.33337 6.23016 6.23071 5.3335 7.33337 5.3335C8.43604 5.3335 9.33337 6.23083 9.33337 7.3335C9.33337 8.43616 8.43604 9.3335 7.33337 9.3335C6.23071 9.3335 5.33337 8.43683 5.33337 7.3335ZM6.66671 7.3335C6.66671 7.70083 6.96604 8.00016 7.33337 8.00016C7.70137 8.00016 8.00004 7.70083 8.00004 7.3335C8.00004 6.96616 7.70137 6.66683 7.33337 6.66683C6.96604 6.66683 6.66671 6.96616 6.66671 7.3335Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.33337 12.0002C5.33337 10.8975 6.23071 10.0002 7.33337 10.0002C8.43604 10.0002 9.33337 10.8975 9.33337 12.0002C9.33337 13.1028 8.43604 14.0002 7.33337 14.0002C6.23071 14.0002 5.33337 13.1028 5.33337 12.0002ZM6.66671 12.0002C6.66671 12.3675 6.96604 12.6668 7.33337 12.6668C7.70137 12.6668 8.00004 12.3675 8.00004 12.0002C8.00004 11.6328 7.70137 11.3335 7.33337 11.3335C6.96604 11.3335 6.66671 11.6328 6.66671 12.0002Z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.33337 14.6668C6.23071 14.6668 5.33337 15.5642 5.33337 16.6668C5.33337 17.7695 6.23071 18.6668 7.33337 18.6668C8.43604 18.6668 9.33337 17.7695 9.33337 16.6668C9.33337 15.5642 8.43604 14.6668 7.33337 14.6668ZM7.33337 17.3335C6.96604 17.3335 6.66671 17.0342 6.66671 16.6668C6.66671 16.2995 6.96604 16.0002 7.33337 16.0002C7.70137 16.0002 8.00004 16.2995 8.00004 16.6668C8.00004 17.0342 7.70137 17.3335 7.33337 17.3335Z"
                fill={color}
            />
            <path d="M10.6667 6.66683H18.6667V8.00016H10.6667V6.66683Z" fill={color} />
            <path d="M18.6667 11.3335H10.6667V12.6668H18.6667V11.3335Z" fill={color} />
            <path d="M10.6667 16.0002H18.6667V17.3335H10.6667V16.0002Z" fill={color} />
        </svg>
    </BaseIcon>
));

export default Icon;
