/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M8.223 8.884L13.2 13.86l9.874-12.068L21.14.208l-8.126 9.931L9.99 7.117 8.223 8.883z"
                fill={color}
            />
            <path
                d="M20.633 12.42V9.953h-2.036l-.001 2.467a4.103 4.103 0 0 0 1.171 2.876l1.083 1.101a1.56 1.56 0 0 1 0 2.173 1.453 1.453 0 0 1-2.085 0l-.623-.632a2.643 2.643 0 0 0-3.778 0 2.701 2.701 0 0 0-.773 1.897c0 .848-.67 1.528-1.484 1.528-.815 0-1.484-.68-1.484-1.528a2.661 2.661 0 0 0-2.649-2.678 2.633 2.633 0 0 0-1.88.79l-.61.623a1.453 1.453 0 0 1-2.088 0 1.56 1.56 0 0 1 0-2.173l1.051-1.068a4.104 4.104 0 0 0 1.17-2.876V9.649c0-3.662 2.91-6.622 6.49-6.622V.99C7.392.989 3.58 4.87 3.581 9.648v2.806c0 .545-.212 1.065-.588 1.447L1.944 14.97a3.595 3.595 0 0 0 0 5.03 3.492 3.492 0 0 0 4.993 0l.611-.624a.595.595 0 0 1 .426-.182.626.626 0 0 1 .611.64c0 1.965 1.572 3.566 3.522 3.566 1.949 0 3.52-1.6 3.52-3.565a.67.67 0 0 1 .19-.469.606.606 0 0 1 .872 0l.623.633a3.49 3.49 0 0 0 4.991 0 3.595 3.595 0 0 0 0-5.03l-1.082-1.101a2.069 2.069 0 0 1-.588-1.448z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
