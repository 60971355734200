/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 10c0-1.654-1.346-3-3-3s-3 1.346-3 3 1.346 3 3 3 3-1.346 3-3zm-3 1a1.001 1.001 0 0 1 0-2 1.001 1.001 0 0 1 0 2z"
            fill={color}
        />
        <path d="M12 15a1 1 0 1 1 2 0 1 1 0 0 1-2 0z" fill={color} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23 13.124v-2h-2.454l-3.817-5.937 1.552-1.938L16.719 2l-1.7 2.124H7.98L6.281 2 4.719 3.249 6.27 5.187l-3.816 5.937H0v2h2.454l3.816 5.937L4.719 21l1.562 1.249 1.699-2.125h7.039l1.7 2.125L18.281 21l-1.552-1.939 3.817-5.937H23zM14.765 18H7.857L4 12l3.857-6h6.908l3.857 6-3.857 6z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
