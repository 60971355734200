/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {useTheme} from '@anywhere-expert/colors';

const Icon = () => {
    const {
        colors: {
            neutral: {black},
        },
    } = useTheme();

    return (
        <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.3011 25.8605C19.3067 25.8605 19.0865 25.7419 19.0357 25.708C18.8436 25.5499 18.6629 25.3804 18.4765 25.2054C16.5846 23.3925 14.992 21.2746 13.71 18.9986C12.3941 16.6662 11.2589 14.0287 11.722 11.2953C12.3207 7.72601 15.6923 4.83443 19.2503 4.62547C23.5481 4.37697 26.6769 8.2117 26.5583 12.2272C26.4905 14.4975 25.5191 16.6323 24.559 18.6541C23.2375 21.4441 21.3681 23.6014 19.3011 25.8605Z"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.3467 14.7082C17.3026 14.6739 17.2535 14.6395 17.2093 14.6052C15.786 13.5156 16.1443 10.2863 18.4068 9.76602C18.8436 9.66786 19.57 9.70712 20.0117 9.79547C21.0473 10.0065 21.6902 11.0813 21.7884 11.9598C21.8031 12.0874 21.808 12.2101 21.8129 12.3377C21.8374 13.2506 21.4743 14.4481 20.6546 15.0371C19.835 15.6211 18.4608 15.3266 18.1075 15.1794C17.7541 15.0322 17.5774 14.8849 17.3467 14.7082Z"
                stroke={black}
                strokeWidth="0.9375"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.56664 32.4794C4.51581 32.2366 4.47628 31.9937 4.45934 31.7396C4.34639 30.4576 4.40851 29.1699 5.0241 28.0065C5.39684 27.3005 6.35694 25.8209 8.63857 25.6853L8.55386 12.549C8.60469 12.4982 6.85815 13.3795 6.7339 13.4755C4.66687 15.0682 3.82116 16.6266 3.56702 18.6597C3.30158 20.8171 3.42018 22.9462 3.375 25.1206C3.37488 28.0065 3.40888 28.718 3.56702 30.1525C3.72515 31.5644 4.64006 32.7674 4.64006 32.7674"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.6588 31.7699C33.4103 30.3803 32.6339 28.5101 31.3236 27.3184C30.7206 26.77 30.0713 26.555 29.6421 26.2952C29.6421 26.2952 29.3823 14.5369 29.4726 14.4465C29.4726 14.4465 32.4656 15.878 33.2901 17.3124C34.2107 19.2835 33.8974 18.6716 34.0998 21.5346C34.1037 23.3503 34.0998 26.5665 34.0998 28.5041C33.5713 31.4553 31.8416 33.773 28.0438 33.9025C27.7162 33.9138 27.3887 33.9138 27.0668 33.9082C21.4248 33.8404 15.7828 33.7444 10.1409 33.7162C9.80767 33.7162 9.46881 33.7162 9.1356 33.7105C8.49177 33.6936 5.30087 33.5355 4.64575 32.7617"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.8193 20.5403C24.2486 20.5572 26.4236 20.5513 27.7452 20.5513"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.7998 20.3311C9.18384 20.3198 13.3655 20.3313 14.5176 20.3313"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.1015 25.7727C17.4637 24.4095 14.394 21.5065 13.3347 20.3887"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
