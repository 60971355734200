/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {useTheme} from '@anywhere-expert/colors';

const Icon = () => {
    const {
        colors: {
            neutral: {black},
        },
    } = useTheme();

    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.11523 19.0298C9.37031 17.1168 9.24277 16.0965 10.0717 15.14C11.4746 13.5458 14.7906 13.3545 16.8949 13.1632C21.4224 12.5893 25.8224 12.7806 28.6919 12.7806C29.8397 12.7806 31.7528 12.7806 33.2194 13.1632C34.6223 13.5458 33.9846 15.5226 33.8571 16.798C33.0919 20.8153 32.518 24.2588 31.689 28.1486C31.4339 29.6153 31.2426 32.4211 30.3499 32.8674C29.5847 33.3776 27.034 33.3776 24.6108 33.5051C22.8253 33.5689 21.9325 33.6964 19.9557 33.824"
                stroke={black}
                strokeWidth="1.92938"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.7499 34.5254C11.9209 34.7167 8.79627 35.0993 8.15859 34.4616C7.71221 34.0152 8.34989 30.9544 8.34989 30.2529"
                stroke={black}
                strokeWidth="1.92938"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.1123 17.3081C35.1326 17.2443 35.4514 17.2443 36.4717 17.1168C38.1297 16.9893 39.9152 17.053 39.8514 18.9661C39.7876 20.8153 39.7239 21.453 41.7644 21.6443C42.3383 21.7081 42.9122 21.7081 43.4862 21.7719C45.5905 21.9632 45.0803 23.5574 44.4427 25.4066C43.9325 26.8095 43.4862 28.2762 42.976 29.6791C42.8485 29.9979 42.7847 30.3167 42.5296 30.6356C42.2108 31.0819 41.7007 31.337 41.1905 31.4646"
                stroke={black}
                strokeWidth="1.92938"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.3853 34.143C33.4926 32.8039 33.6201 30.9546 34.8317 29.6793C35.7244 28.7227 37.2549 28.2764 38.3389 28.7227C38.594 28.8503 38.9128 28.9778 39.1041 29.1691C42.165 31.8474 36.8085 37.9053 34.3853 34.143Z"
                stroke={black}
                strokeWidth="1.92938"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.555 30.6993C16.7666 30.3167 18.1057 29.8703 18.9984 30.8906C19.4448 31.337 19.7636 31.9109 19.8912 32.5486C20.1463 33.8877 19.3173 35.4819 18.1695 36.4384C16.384 37.8413 13.897 37.3311 13.3231 35.2268C12.8767 33.5051 13.8332 31.5283 15.555 30.6993Z"
                stroke={black}
                strokeWidth="1.92938"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.29004 20.1772C5.29004 20.1772 9.48345 19.6033 10.5318 19.4758C12.943 19.2845 12.8906 19.2207 15.2494 19.2207"
                stroke={black}
                strokeWidth="1.92938"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.9107 22.7402C20.9107 22.7402 13.2577 23.3141 12.419 23.3779C8.90704 23.6967 5.76198 24.0156 2.25 24.2707"
                stroke={black}
                strokeWidth="1.92938"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.867 26.5901C16.078 26.9229 14.7155 26.8849 13.1386 27.096C10.6696 27.3461 7.78019 27.6482 5.2612 27.7693"
                stroke={black}
                strokeWidth="1.92938"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30.7139 32.5889C30.7139 32.5889 31.4932 31.4814 32.6827 26.7235C33.8721 21.9655 33.9949 17.84 33.9949 17.84"
                stroke={black}
                strokeWidth="1.92938"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
