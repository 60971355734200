import bugsnag, {Bugsnag} from 'bugsnag-js';
import {configuration as config} from '@anywhere-expert/core';
import {isValidBugsnagErrorObject, createBugsnagErrorObject} from '../utils/bugsnagErrorObject';
import {LogFunc, LogData} from '../types';

let _bugsnagClient: Bugsnag.Client;

function getDevelopmentLogger() {
    function noop() {}
    return {
        debug: noop,
        info: noop,
        warn: noop,
        error: function() {
            console.log.apply(console, arguments);
        },
    };
}

export const init = appVersion => {
    const isDev = process.env.NODE_ENV === 'development';

    _bugsnagClient = bugsnag({
        apiKey: config.bugsnag.appKey,
        appVersion,
        releaseStage: process.env.NODE_ENV,
        notifyReleaseStages: config.bugsnag.notifyReleaseStages,
        logger: isDev ? getDevelopmentLogger() : undefined,
    });
};

export const setUser = user => {
    _bugsnagClient.user = {
        id: user.uid,
        name: user.displayName,
        email: user.email,
    };
};

const createErrorFromFetchResponse = async (message: string, res: Response) => ({
    message,
    name: 'Fetch error',
    statusCode: res.status,
    url: res.url,
    responseBody: await res.text(),
});

const getErrorObj = async (message, logData) => {
    let errObj: Error | LogData | undefined;

    if (logData && isValidBugsnagErrorObject(logData.err)) {
        errObj = logData.err;
    } else if (isValidBugsnagErrorObject(logData)) {
        errObj = logData;
    } else if (logData && logData.err instanceof Response) {
        errObj = await createErrorFromFetchResponse(message, logData.err);
    } else if (logData && logData instanceof Response) {
        errObj = await createErrorFromFetchResponse(message, logData);
    } else {
        errObj = createBugsnagErrorObject(message);
    }

    (errObj as any).message = message;
    return errObj;
};

const getMetadata = (errObj, logData) => {
    const extraData = (logData && logData.extra) || {};
    return {...extraData, errorDetails: errObj};
};

const log: LogFunc = async (severity, message, logData) => {
    if (severity === 'error' && _bugsnagClient) {
        let errObj = await getErrorObj(message, logData);
        const metaData = getMetadata(errObj, logData);

        _bugsnagClient.notify(errObj, {metaData, severity});
    }
};

export default log;
