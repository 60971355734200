/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M5.299 9.853l-3.046 3.13a.902.902 0 0 0 0 1.253L4.69 16.74a.844.844 0 0 0 1.218 0l2.437-2.504a.902.902 0 0 0 0-1.253l-.61-.626c2.437-2.504 6.092-2.426 8.53 0l-.61.627a.902.902 0 0 0 0 1.252l2.437 2.504a.844.844 0 0 0 1.219 0l2.437-2.504a.903.903 0 0 0 0-1.253L18.7 9.854a9.297 9.297 0 0 0-13.402 0z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
