import {createMuiTheme, Theme as MuiTheme} from '@material-ui/core/styles';
import {darken, lighten} from '@material-ui/core/styles/colorManipulator';
import palette, {Palette} from '../palette';

const getLightContrastShade = (alpha: number) => `rgba(0,0,0,${alpha})`;
const getDarkContrastShade = (alpha: number) => `rgba(255,255,255,${alpha})`;
const getSplitFunction = (themeName: string) => (lightModeColor: string, darkModeColor: string) =>
    themeName === 'light' ? lightModeColor : darkModeColor;

const sharedPaperOverrides = {
    rounded: {
        borderRadius: '8px',
    },
};

const muiLightTheme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            main: palette.primary.blue.deeper,
            dark: palette.primary.blue.deep,
            light: palette.brand.blue,
        },
    },
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: getLightContrastShade(0.4),
            },
        },
        MuiPaper: {
            ...sharedPaperOverrides,
            elevation24: {
                boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.24)',
            },
        },
    },
});

const muiDarkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: palette.primary.blue.deeper,
            dark: palette.primary.blue.deep,
            light: palette.brand.blue,
        },
    },
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: getLightContrastShade(0.6),
            },
        },
        MuiPaper: {
            root: {
                backgroundColor: 'transparent',
                border: `1px solid ${getDarkContrastShade(0.4)}`,
            },
            elevation24: {
                boxShadow: undefined,
            },
            ...sharedPaperOverrides,
        },
    },
});

const solutoLightTheme = {
    themeName: 'light',
    colors: {
        ...palette,
        background: {
            primary: palette.neutral.grey.lightest,
            secondary: palette.neutral.white,
        },
        foreground: {
            primary: palette.neutral.white,
            secondary: palette.neutral.grey.deepest,
        },
        contrast: {
            primary: palette.neutral.whites.w80,
            secondary: palette.neutral.whites.w60,
            caption: palette.neutral.whites.w40,
            disabled: palette.neutral.whites.w20,
            border: palette.neutral.whites.w10,

            hover: palette.neutral.whites.w05,
            queuePanelHover: palette.neutral.grey.lightest,
            toolboxItemSelected: palette.neutral.grey.lighter,
            selected: palette.neutral.whites.w10,
        },
    },
    utils: {
        getContrastShade: getLightContrastShade,
        lighten,
        darken,
        split: getSplitFunction('light'),
        getLightContrastShade: getLightContrastShade,
        getDarkContrastShade: getDarkContrastShade,
    },
};

export const light: Theme = {
    ...solutoLightTheme,
    ...muiLightTheme,
};

export const dark: Theme = {
    ...solutoLightTheme,
    ...muiDarkTheme,
    themeName: 'dark',
    colors: {
        ...solutoLightTheme.colors,
        neutral: {
            ...solutoLightTheme.colors.neutral,
            black: solutoLightTheme.colors.neutral.white,
            white: solutoLightTheme.colors.neutral.black,
            grey: {
                ...solutoLightTheme.colors.neutral.grey,
                deepest: solutoLightTheme.colors.neutral.grey.deeper,
                deep: solutoLightTheme.colors.neutral.grey.deeper,
                light: solutoLightTheme.colors.neutral.grey.deepest,
                lighter: '#080B15',
                lightest: '#080B15',
            },
        },
        background: {
            primary: '#0C0C0D',
            secondary: '#161718',
        },
        foreground: {
            primary: '#2A2A2D',
            secondary: '#383840',
        },
        contrast: {
            primary: palette.neutral.blacks.b80,
            secondary: palette.neutral.blacks.b60,
            caption: palette.neutral.blacks.b40,
            disabled: palette.neutral.blacks.b20,
            border: palette.neutral.blacks.b20,
            hover: palette.neutral.blacks.b05,
            queuePanelHover: palette.neutral.blacks.b05,
            toolboxItemSelected: palette.neutral.blacks.b20,
            selected: palette.neutral.blacks.b20,
        },
    },
    utils: {
        ...solutoLightTheme.utils,
        getContrastShade: getDarkContrastShade,
        darken: lighten,
        lighten: darken,
        split: getSplitFunction('dark'),
    },
};

type CustomPalette = {
    background: {
        primary: string;
        secondary: string;
    };
    foreground: {
        primary: string;
        secondary: string;
    };
    contrast: {
        primary: string;
        secondary: string;
        caption: string;
        disabled: string;
        border: string;
        hover: string;
        queuePanelHover: string;
        toolboxItemSelected: string;
        selected: string;
    };
};
type Utils = {
    darken: (color: string, coefficient?: number) => string;
    lighten: (color: string, coefficient?: number) => string;
    getContrastShade: (alpha: number) => string;
    split: (lightModeColor: string, darkModeColor: string) => string;
    getDarkContrastShade: (alpha: number) => string;
    getLightContrastShade: (alpha: number) => string;
};

export type Theme = MuiTheme & {
    themeName: string;
    colors: Palette & CustomPalette;
    utils: Utils;
};
