import React, {useState, useRef, useEffect} from 'react';

const useCopyTextOnMinClicksInterval = (
    textToCopy: string,
    onClicksReached?: () => void,
    clicks: number = 5,
    interval: number = 1500
) => {
    const [clickCount, setClickCount] = useState(0);
    const timeoutId: React.MutableRefObject<number | any> = useRef(0);

    const clearClicksState = () => {
        setClickCount(0);
        if (timeoutId.current) clearTimeout(timeoutId.current);
        timeoutId.current = 0;
    };

    const onClick = async () => {
        if (clickCount === clicks - 1) {
            await window.navigator?.clipboard.writeText(textToCopy);
            onClicksReached && onClicksReached();
            clearClicksState();
        }
        setClickCount(clickCount + 1);
        if (!timeoutId.current) timeoutId.current = setTimeout(clearClicksState, interval);
    };

    useEffect(() => clearClicksState, [textToCopy]);

    return onClick;
};

export default useCopyTextOnMinClicksInterval;
