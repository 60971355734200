import * as React from 'react';
import styled from 'styled-components';
import CoreText from './CoreText';
import CoreIcon, {CoreIconProps} from './CoreIcon';
import CoreButton from './CoreButton';
import CoreIconButton from './CoreIconButton';
import CloseIcon from './images/CloseIcon';
import {InvertedThemeProvider} from '@anywhere-expert/colors';
import {useTheme} from '@anywhere-expert/colors';

const OFFSET = '80px';
export type ToastXPosition = 'right' | 'left' | 'center';

const CoreToastContainer = styled.div<{backgroundColor?: string; xPosition?: ToastXPosition}>`
    position: absolute;
    box-sizing: border-box;
    display: flex;
    width: fit-content;
    left: ${({xPosition}) => (xPosition === 'left' ? OFFSET : xPosition === 'center' ? '0' : '')};
    right: ${({xPosition}) => (xPosition === 'right' ? OFFSET : xPosition === 'center' ? '0' : '')};
    top: 4vh;
    margin-left: auto;
    margin-right: auto;
    background-color: ${({theme, backgroundColor}) => backgroundColor || theme.colors.neutral.grey.light};
    padding: 12px;
    border-radius: 8px;
    z-index: 1500;
`;

const AlignCenterContainer = styled.div`
    display: flex;
    align-items: center;
`;

const CoreToastTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ActionWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

const ExtendedCoreText = styled(CoreText)<{textColor?: string}>`
    color: ${({theme, textColor}) => textColor || theme.colors.neutral.black};
    margin-right: 8px;
    margin-left: 8px;
`;

export type CoreToastProps = {
    text?: string | null;
    textSecondLine?: string;
    textThirdLine?: string;
    textColor?: string;
    testId?: string;
    Icon?: CoreIconProps['icon'];
    MessageContent?: () => JSX.Element;
    onClose?: (wasActionClicked: boolean) => void;
    actionButtonText?: string;
    show: boolean;
    showTime?: number;
    showClose?: boolean;
    xPosition?: ToastXPosition;
    [x: string]: any;
};

const onCloseClicked = onClose => () => {
    onClose(false);
};

const onActionButtonClicked = (e, onClose) => {
    e.preventDefault();
    onClose(true);
};

const CoreToast = ({
    text,
    textSecondLine,
    textThirdLine,
    show,
    actionButtonText,
    Icon,
    testId,
    textColor,
    onClose,
    showClose = true,
    MessageContent,
    xPosition,
    ...others
}: CoreToastProps) => {
    if (!show) return null;
    const onCloseClick = onCloseClicked(onClose);
    const theme = useTheme();

    return (
        <InvertedThemeProvider>
            <CoreToastContainer data-test-id={testId} {...others} xPosition={xPosition || 'center'}>
                {Icon && (
                    <AlignCenterContainer>
                        <CoreIcon color={textColor || theme.colors.neutral.white} icon={Icon} />
                    </AlignCenterContainer>
                )}
                {(MessageContent && <MessageContent />) || (
                    <CoreToastTextContainer>
                        <ExtendedCoreText size="s" textColor={textColor}>
                            {text}
                        </ExtendedCoreText>
                        {textSecondLine && (
                            <ExtendedCoreText size="s" textColor={textColor}>
                                {textSecondLine}
                            </ExtendedCoreText>
                        )}
                        {textThirdLine && (
                            <ExtendedCoreText size="s" textColor={textColor}>
                                {textThirdLine}
                            </ExtendedCoreText>
                        )}
                    </CoreToastTextContainer>
                )}
                {actionButtonText && (
                    <ActionWrapper>
                        <CoreButton
                            variant="text"
                            color="secondary"
                            data-test-id={`${testId}_ActionButton`}
                            onClick={e => onActionButtonClicked(e, onClose)}
                            text={actionButtonText}
                        />
                    </ActionWrapper>
                )}
                {showClose && (
                    <CoreIconButton
                        variant="icon-fade"
                        colorOverride={theme.colors.neutral.white}
                        onClick={onCloseClick}
                        icon={CloseIcon}
                        size="xxs"
                    />
                )}
            </CoreToastContainer>
        </InvertedThemeProvider>
    );
};

export default CoreToast;
