/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon fill={color} {...baseIconProps}>
        <path
            d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm3.076-15.966l-.004.01c-1.453 3.24-3.006 6.446-4.49 9.652-.506-1.275-1.148-2.438-1.689-3.675-.641-.031-1.252 0-1.893 0 .915 2.005 1.897 3.973 2.774 5.979h1.553c1.89-3.984 3.749-8.026 5.673-12-.641 0-1.283 0-1.924.034z"
            fillRule="evenodd"
            stroke="none"
        />
    </BaseIcon>
));

export default Icon;
