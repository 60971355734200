/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M20 11.403l-1.588-2.665-2.584 6.518c-.585-.237-1.196-.493-1.826-.758l2.784-6.947-2.118-.85-2.775 6.92c-.676-.282-1.324-.565-1.917-.812l2.803-6.984-2.283-.922-2.775 6.93a.887.887 0 01-1.068.328.879.879 0 01-.448-.383L5.02 8.418l-1.224.192a.907.907 0 00-.602.374.908.908 0 00-.183.694l.913 9.988a.884.884 0 00.484.62l-.01.019 2.073.885 1.917.831v-.01l3.05 1.306c.11.055.237.083.365.083.2 0 .401-.073.557-.201l4.2-3.287c.118-.1.21-.219.273-.365l.968-2.483c.009-.028.009-.055.018-.082L20 11.402z"
                fill={color}
            />
            <path
                d="M10.14 3.059a4.454 4.454 0 014.4.913l1.17-1.406a6.399 6.399 0 00-6.145-1.26A6.26 6.26 0 005.43 5.688l1.78.43a4.494 4.494 0 012.931-3.06z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
