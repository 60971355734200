/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <g clipPath="url(#clip0)" fill={color}>
                <path d="M9.998 7h-8c-1.104 0-2 .897-2 2v9c0 1.103.896 2 2 2h1v4l5.334-4h6.666c1.104 0 2-.897 2-2v-3.069c-3.945-.493-7-3.853-7-7.931z" />
                <path d="M17.998.984c-3.308 0-6 2.692-6 6s2.691 6 6 6 6-2.692 6-6-2.691-6-6-6zm3 7h-4v-5h2v3h2v2z" />
            </g>
            <defs>
                <clipPath id="clip0">
                    <path d="M0 0h24v24H0z" fill="#fff" />
                </clipPath>
            </defs>
        </BaseIcon>
    ))
);

export default Icon;
