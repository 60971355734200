/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path d="M7 11V9h8v2H7zM7 15v-2h10v2H7zM17 19v-2H7v2h10z" fill={color} />
            <path
                d="M17 2h2c1.103 0 2 .896 2 2v18c0 1.103-.897 2-2 2H5c-1.103 0-2-.897-2-2V4c0-1.104.897-2 2-2h2V1a1 1 0 012 0v1h2V1a1 1 0 012 0v1h2V1a1 1 0 012 0v1zM5 4v18h14.001L19 4h-2v1a1 1 0 11-2 0V4h-2v1a1 1 0 11-2 0V4H9v1a1 1 0 11-2 0V4H5z"
                fill={color}
                fillRule="evenodd"
            />
        </BaseIcon>
    ))
);

export default Icon;
