import React, {ReactNode} from 'react';
import styled, {css} from 'styled-components';
import {ChevronDownIcon} from '@anywhere-expert/icons';
import {useDetectEscapeKey, useDetectOutsideClick} from '@anywhere-expert/utils';
import CoreButton, {CoreButtonVariant, CoreButtonColor} from './CoreButton';
import CoreIconButton, {CoreIconButtonVariant, CoreIconButtonColor} from './CoreIconButton';
import CoreSvgIconProps from './CoreSvgIconProps';

export type DropdownPosition = 'bottom' | 'top';

export type CoreIconButtonDropdownProps = {
    variant?: CoreIconButtonVariant;
    color?: CoreIconButtonColor;
    colorOverride: string;
    analytics?: any;
    size?: 's' | 'l';
    icon: React.ComponentType<CoreSvgIconProps>;
    [x: string]: any;
};

export type CoreButtonDropdownProps = {
    variant?: CoreButtonVariant;
    color?: CoreButtonColor;
    analytics?: any;
    Text?: React.ComponentType;
    text: string;
    hasArrow?: boolean;
    [x: string]: any;
};

export type CoreDropdownProps = (CoreIconButtonDropdownProps | CoreButtonDropdownProps) & {
    position?: DropdownPosition;
    children: ReactNode;
    onClickOut: () => void;
    childStyle?: any;
    contentStyle?: any;
    extend: boolean;
    disabled?: boolean;
};

const ExtendedCoreButton = styled(CoreButton)`
    justify-content: space-between;
    min-width: 100px;

    .buttonText {
        max-width: 210px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;
const ChildrenDiv = styled.div<{position: DropdownPosition}>`
    display: flex;
    position: absolute;
    ${({position}) =>
        position === 'bottom' ? `margin-top: 8px;` : `top: 0; left: 0; transform: translateY(calc(-100% - 8px));`};
    max-height: 250px;
`;

const Rotatable = styled.div<{open: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    ${({open}) =>
        open
            ? css`
                  transform: rotateX(180deg);
              `
            : css`
                  transform: rotateX(0deg);
              `};
`;

const RotatableIcon = ({open, ...others}: {open: boolean}) => (
    <Rotatable open={open}>
        <ChevronDownIcon {...others} />
    </Rotatable>
);

const CoreDropdown = ({
    disabled,
    text,
    Text,
    icon,
    children,
    childStyle,
    contentStyle,
    variant,
    color,
    colorOverride,
    position = 'bottom',
    extend,
    className,
    size = 'm',
    hasArrow = true,
    onClickOut,
    ...others
}: CoreDropdownProps) => {
    useDetectEscapeKey(onClickOut, extend);
    const containerRef = useDetectOutsideClick(onClickOut, extend);

    return (
        <div ref={containerRef} className={className} style={{position: 'relative'}}>
            {!text && icon ? (
                <CoreIconButton
                    variant={(variant as CoreIconButtonVariant) || 'contained'}
                    icon={icon}
                    size={size}
                    isActive={extend}
                    colorOverride={colorOverride}
                    disabled={disabled}
                    style={contentStyle}
                    {...others}
                />
            ) : (
                <ExtendedCoreButton
                    variant={(variant as CoreButtonVariant) || 'outlined'}
                    color={color}
                    icon={hasArrow && RotatableIcon}
                    disabled={disabled}
                    colorOverride={colorOverride}
                    Text={Text}
                    text={text}
                    title={text}
                    isActive={extend}
                    iconProps={{open: extend, side: 'right'}}
                    size={size}
                    style={contentStyle}
                    {...others}
                />
            )}
            {extend && (
                <ChildrenDiv position={position} style={childStyle}>
                    {children}
                </ChildrenDiv>
            )}
        </div>
    );
};

export default React.forwardRef<CoreDropdownProps, CoreDropdownProps>((props: CoreDropdownProps, ref) => (
    <CoreDropdown {...props} forwardedRef={ref} />
));
