/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M4.293 19H12c4.411 0 8-3.14 8-7s-3.589-7-8-7c-4.41 0-8 3.14-8 7 0 1.537.568 3 1.642 4.232a1 1 0 01-.02 1.338L4.292 19zM12 21H2a1 1 0 01-.733-1.68l2.306-2.484C2.541 15.392 2 13.735 2 12c0-4.962 4.486-9 10-9s10 4.038 10 9-4.486 9-10 9z"
                fill={color}
                fillRule="evenodd"
            />
            <path d="M13 11h-2v5h2v-5zM13 9a1 1 0 10-2 0 1 1 0 002 0z" fill={color} fillRule="evenodd" />
        </BaseIcon>
    ))
);

export default Icon;
