/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.906 17.32a8 8 0 1 1 1.414-1.414l3.387 3.387-1.414 1.414-3.387-3.387zM17 11a6 6 0 1 1-12 0 6 6 0 0 1 12 0z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
