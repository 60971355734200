import {CSSObject} from 'styled-components';
import {IconSize} from '@anywhere-expert/icons';
import {GetVariantStyle, CoreButtonSize, CoreButtonVariant} from './types';
import {getBaseButtonStyle, getButtonColor} from '../shared/button';

export function getVariantStyle({theme, variant, color: colorCategory, colorOverride}: GetVariantStyle): CSSObject {
    const color = colorOverride || getButtonColor(theme, colorCategory, variant);
    const baseStyle = getBaseButtonStyle(theme, variant, color);

    switch (variant) {
        case 'contained':
            return baseStyle;
        case 'outlined':
            return {
                ...baseStyle,
                borderWidth: '2px',
            };
        case 'text':
            return baseStyle;
        case 'floating':
            return baseStyle;
    }
}

interface GetSizeStyle {
    height: number | string;
    iconSize: IconSize;
    paddingWithIcon: number;
    paddingWithoutIcon: number;
}

export function getSizeStyle(variant: CoreButtonVariant, size: CoreButtonSize): GetSizeStyle {
    switch (size) {
        case 's': {
            return {
                height: variant === 'text' ? 'auto' : 32,
                iconSize: 's',
                paddingWithIcon: 8,
                paddingWithoutIcon: 16,
            };
        }
        case 'l': {
            return {
                height: variant === 'text' ? 'auto' : 56,
                iconSize: 'l',
                paddingWithIcon: 10,
                paddingWithoutIcon: 16,
            };
        }
        default: {
            return {
                height: variant === 'text' ? 'auto' : 40,
                iconSize: 'm',
                paddingWithIcon: 10,
                paddingWithoutIcon: 16,
            };
        }
    }
}
