import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import {CloseIcon} from '@anywhere-expert/icons';
import {LoggingErrorBoundary} from '@anywhere-expert/logging';
import {AnalyticsProvider} from 'react-shisell';
import CoreIconButton from '../CoreIconButton';
import {CoreModalProps, ModalSize} from './types';
import {ModalTitle, ModalContent, ModalActions} from './helpers';
import {getScrollerStyle} from '..';
import useModalAnalyticsDispatcher from './useModalAnalyticsDispatcher';

const StyledDialog = styled(Dialog)`
    .MuiDialog-paper {
        overflow-y: inherit;
    }
`;

const CloseButton = styled(CoreIconButton)<{modalSize: ModalSize}>`
    position: absolute;
    z-index: 2;
    top: -${({theme, modalSize}) => theme.spacing(modalSize === 'l' ? 2 : 1.5)}px;
    right: -${({theme, modalSize}) => theme.spacing(modalSize === 'l' ? 2 : 1.5)}px;
`;

const ContentContainer = styled.div<{modalSize: ModalSize}>`
    background-color: ${({theme}) => theme.colors.neutral.white};
    display: flex;
    flex-direction: column;
    min-width: 300px;
    min-height: 100px;
    border-radius: 8px;
    padding: ${({theme, modalSize}) => (modalSize === 's' ? theme.spacing(2, 2) : theme.spacing(3, 3))};
`;

const ModalHeader = styled.div`
    position: relative;
    padding: ${({theme}) => theme.spacing(0, 3)};
`;

const ChildrenContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    ${({theme}) => getScrollerStyle(theme)};
`;

const CoreModal = React.forwardRef((props: CoreModalProps, ref) => {
    const {
        showCloseButton = true,
        children,
        className,
        onClose,
        testId,
        size = 'l',
        name = 'CoreModal',
        sendViewAnalytics,
        analyticsExtras,
        analyticsScope,
        ...dialogProps
    } = props;

    const analyticsDispatcher = useModalAnalyticsDispatcher({analyticsScope, ...props});
    const handleClose = React.useCallback(() => {
        if (onClose) {
            analyticsDispatcher.dispatch('Close');
            onClose();
        }
    }, [onClose, analyticsDispatcher]);

    return ReactDOM.createPortal(
        <LoggingErrorBoundary boundaryName={name}>
            <AnalyticsProvider dispatcher={analyticsDispatcher}>
                <StyledDialog onClose={handleClose} maxWidth={false} {...dialogProps} ref={ref}>
                    <ContentContainer className={className} data-test-id={testId || name} modalSize={size}>
                        <ModalHeader>
                            {showCloseButton && onClose && (
                                <CloseButton
                                    icon={CloseIcon}
                                    onClick={handleClose}
                                    variant="icon"
                                    modalSize={size}
                                    size="s"
                                    data-test-id="ModalCloseButton"
                                />
                            )}
                        </ModalHeader>
                        <ChildrenContainer>{children}</ChildrenContainer>
                    </ContentContainer>
                </StyledDialog>
            </AnalyticsProvider>
        </LoggingErrorBoundary>,
        document.body
    );
});

interface CoreModalComponent extends React.FunctionComponent<CoreModalProps> {
    Title: typeof ModalTitle;
    Content: typeof ModalContent;
    Actions: typeof ModalActions;
}

const EnhancedCoreModal: any = CoreModal;

EnhancedCoreModal.Title = ModalTitle;
EnhancedCoreModal.Content = ModalContent;
EnhancedCoreModal.Actions = ModalActions;

export default EnhancedCoreModal as CoreModalComponent;
