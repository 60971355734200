/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.999 17V8.857a3.958 3.958 0 0 1-3-3.858A4.003 4.003 0 0 1 11.996 1a4.004 4.004 0 0 1 4.003 3.999 3.957 3.957 0 0 1-3 3.859V17h-2zm3-12.001a2 2 0 1 0-2.004 1.999h.009a1.996 1.996 0 0 0 1.995-1.999z"
            fill={color}
        />
        <path
            d="M20.192 11.76l1.566 7.047c.123.554 0 1.112-.337 1.532-.337.42-.855.66-1.422.66H4a1.807 1.807 0 0 1-1.423-.66 1.805 1.805 0 0 1-.337-1.532l1.567-7.046C4.026 10.774 4.988 10 6 10h2v2H6c-.08 0-.223.115-.24.194L4.244 19h15.507l-1.513-6.805c-.018-.08-.159-.195-.24-.195h-2v-2h2c1.01 0 1.974.774 2.193 1.76z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
