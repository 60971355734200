import React, {ComponentType, ClassAttributes, forwardRef} from 'react';
import styled from 'styled-components';
import CoreText, {CoreTextProps} from './CoreText';
import Markdown from '@soluto-private/soluto-markdown';

const Content = styled.div`
    overflow: hidden;
    max-height: 22px;
`;

const CoreTextWithMarkdown = styled(CoreText)`
    color: ${({textColor}) => textColor};
    & .text_message_content_link {
        font-weight: normal;
        color: ${({theme}) => theme.colors.primary.blue.brighter};
        &:hover {
            text-decoration: underline;
            font-weight: 500;
            color: ${({theme}) => theme.utils.lighten(theme.colors.brand.blue, 0.2)};
        }
        &:active {
            text-decoration: underline;
            color: ${({theme}) => theme.utils.darken(theme.colors.brand.blue, 0.2)};
        }
    }
`;

const CoreMarkdownText: ComponentType<CoreTextProps & ClassAttributes<HTMLSpanElement>> = forwardRef<
    HTMLSpanElement,
    CoreTextProps
>(({italic = false, size = 's', text, textType = 'primary', weight = 'normal', children, ...others}, ref) => {
    return (
        <CoreTextWithMarkdown dir="auto" ref={ref} size={size} {...others}>
            <Content>
                <Markdown
                    font-weight={weight}
                    content={text}
                    linkify
                    classNames={{link: 'text_message_content_link'}}
                />
            </Content>
        </CoreTextWithMarkdown>
    );
});

export default CoreMarkdownText;
