import React, {CSSProperties, ReactElement} from 'react';
import styled from 'styled-components';
import {useDetectEscapeKey, useDetectOutsideClick} from '@anywhere-expert/utils';
import CoreIconButton, {CoreIconButtonProps} from './CoreIconButton';
import {CoreIconProps} from './CoreIcon';
import {CoreActionMenuItemProps} from './CoreActionMenuItem';
import logger from '@anywhere-expert/logging';

type CustomButtonProps = {isActive: boolean; [x: string]: any};

export interface CoreActionMenuProps {
    directionUp?: boolean;
    analytics?: any;
    onClickOut: (e: KeyboardEvent) => void;
    extend: boolean;
    containerStyle?: CSSProperties;
    childStyle?: CSSProperties;
    children: ReactElement<CoreActionMenuItemProps>;
    disabled?: boolean;
    buttonIcon?: CoreIconProps['icon'];
    buttonIconProps?: Omit<CoreIconButtonProps, 'icon'>;
    customButton?: React.ComponentType<CustomButtonProps>;
    customButtonProps?: Omit<CustomButtonProps, 'isActive'>;
    [x: string]: any;
}

const Container = styled.div<{directionUp: boolean}>`
    display: flex;
    position: relative;
    flex-direction: ${({directionUp}) => (directionUp ? 'column-reverse' : 'column')};
`;

const ChildrenContainer = styled.div<{directionUp: boolean}>`
    display: flex;
    position: absolute;
    bottom: ${({directionUp}) => directionUp && '40px'};
    top: ${({directionUp}) => !directionUp && '40px'};

    ${({theme}) => theme.themeName === 'light' && 'box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.11);'}
`;

const CoreActionMenu = ({
    disabled,
    children,
    extend,
    directionUp = false,
    containerStyle,
    childStyle,
    onClickOut,
    buttonIcon: ButtonIcon,
    buttonIconProps = {},
    customButton: CustomButton,
    customButtonProps = {},
    ...others
}: CoreActionMenuProps) => {
    useDetectEscapeKey(onClickOut, extend);
    const containerRef = useDetectOutsideClick(onClickOut, extend);
    if (!ButtonIcon && !CustomButton) {
        logger.error('CoreActionMenu require buttonIcon or customButton');
        return null;
    }

    return (
        <Container ref={containerRef} style={containerStyle} directionUp={directionUp}>
            {(!!ButtonIcon && (
                <CoreIconButton
                    disabled={disabled}
                    isActive={extend}
                    variant="icon"
                    icon={ButtonIcon}
                    {...buttonIconProps}
                    {...others}
                />
            )) ||
                (!!CustomButton && (
                    <CustomButton isActive={extend} {...customButtonProps} disabled={disabled} {...others} />
                ))}

            {extend && !disabled && (
                <ChildrenContainer directionUp={directionUp} style={childStyle}>
                    {children}
                </ChildrenContainer>
            )}
        </Container>
    );
};

export default CoreActionMenu;
