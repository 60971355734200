import React, {memo} from 'react';

const Icon = () => (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
            <rect
                x="7.00006"
                y="7"
                width="36"
                height="36"
                rx="18"
                stroke="url(#paint0_linear)"
                strokeWidth="4"
                strokeLinecap="round"
            />
            <rect x="14.6516" y="14.6514" width="20.6968" height="20.6968" rx="10.3484" fill="#8223D2" />
        </g>
        <defs>
            <filter
                id="filter0_d"
                x="0.204082"
                y="0.204082"
                width="49.5919"
                height="49.5918"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset />
                <feGaussianBlur stdDeviation="2.39796" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
            <linearGradient
                id="paint0_linear"
                x1="41.3619"
                y1="22.903"
                x2="34.7276"
                y2="13.2727"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#8223D2" />
                <stop offset="1" stopColor="#8223D2" stopOpacity="0" />
            </linearGradient>
        </defs>
    </svg>
);

export default memo(Icon);
