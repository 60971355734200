import * as React from 'react';
import styled from 'styled-components';

type TagSize = 's' | 'l';

export type CoreTagProps = {
    text?: string;
    size?: TagSize;
    Icon?: React.ElementType;
    forceDarkMode?: boolean;
    showTitle?: boolean;
    [x: string]: any;
};

const CompoundTagContainer = styled.div<{hasIcon: boolean; size: TagSize; forceDarkMode?: boolean}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${({theme, forceDarkMode}) =>
        (forceDarkMode && theme.colors.neutral.blacks.b60) || theme.colors.contrast.secondary};
    background-color: ${({theme, forceDarkMode}) =>
        theme.themeName === 'dark' || forceDarkMode
            ? theme.utils.getDarkContrastShade(0.2)
            : theme.utils.getContrastShade(0.05)};
    font-size: ${({size}) => (size === 's' ? '10px' : '12px')};
    font-family: Roboto;
    box-sizing: border-box;
    line-height: 120%;
    border-radius: 50px;
    padding: ${({size}) => (size === 's' ? '2px 8px' : '4px 8px')};
    ${({hasIcon}) => hasIcon && 'padding-right: 4px;'};
    margin-right: 4px;
    user-select: none;
    height: ${({size}) => (size === 's' ? '16px' : '24px')};
    span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

const CompoundIconContainer = styled.div`
    margin: 0 2px 0 4px;
    display: flex;
`;

const CoreTag = ({text, Icon, forceDarkMode, size = 'l', showTitle = false, ...others}: CoreTagProps) => {
    const renderedIcon = React.useMemo(
        () =>
            Icon && (
                <CompoundIconContainer>
                    <Icon />
                </CompoundIconContainer>
            ),
        [Icon]
    );
    return (
        <CompoundTagContainer
            hasIcon={!!Icon}
            title={showTitle ? text : undefined}
            size={size}
            forceDarkMode={forceDarkMode}
            {...others}
        >
            <span>{text}</span>
            {renderedIcon}
        </CompoundTagContainer>
    );
};

export default CoreTag;
