import * as React from 'react';
import times from 'lodash.times';

interface Window {
    requestIdleCallback?: (fn: () => void) => number;
    cancelIdleCallback: (id: number) => void;
    addEventListener: (
        type: string,
        listener: EventListenerOrEventListenerObject,
        options?: boolean | AddEventListenerOptions
    ) => void;
    removeEventListener: (
        type: string,
        listener: EventListenerOrEventListenerObject,
        options?: boolean | EventListenerOptions
    ) => void;
    innerHeight: number;
}

declare var window: Window;

type Props = {
    style?: any;
    idleAmount?: number;
    scrollBuffer?: number;
    scrollToTopOnSizeChange?: boolean;
    initialAmount: number;
    isActive?: boolean;
    progressiveAmount?: number;
    data: any[];
    renderItem: (data: any[], index: number) => any;
    renderLoader?: () => any;
    useWindowScroll?: boolean;
};

type State = {
    numRenderRows: number;
};

class ReactProgressiveList extends React.PureComponent<Props, State> {
    state: State;
    requestId: number;
    ref?: HTMLDivElement | null;
    isLoading = false;

    static defaultProps = {
        className: undefined,
        idleAmount: 0, // load one extra row on idle by default
        initialAmount: 10,
        isActive: true,
        progressiveAmount: 10,
        renderLoader: () => null,
        useWindowScroll: false,
    };

    constructor(props: Props) {
        super(props);
        const {data, initialAmount, isActive} = props;
        this.requestId = 0;
        this.state = {
            numRenderRows: isActive ? initialAmount : data.length,
        };
    }

    componentDidUpdate(prevProps: Props) {
        const {data, scrollToTopOnSizeChange} = this.props;
        const {data: prevData} = prevProps;
        if (scrollToTopOnSizeChange && data.length !== prevData.length) {
            requestAnimationFrame(() => this.ref && this.ref.scrollIntoView());
        }
    }

    componentDidMount() {
        const {useWindowScroll} = this.props;
        this.progressivelyLoadMore(false);
        const scrollParent = useWindowScroll ? window : this.ref && this.ref.parentElement;
        scrollParent &&
            scrollParent.addEventListener('scroll', this.handleScroll, {
                passive: true,
            });
    }

    handleScroll = (e: any) => {
        const {data, progressiveAmount, useWindowScroll, scrollBuffer = 0} = this.props;
        const {numRenderRows} = this.state;
        let top, height, scrollHeight, reachedLimit;
        if (useWindowScroll) {
            const boundingClientRect = this.ref && this.ref.getBoundingClientRect();
            top = (boundingClientRect && boundingClientRect.top) || 0;
            height = (boundingClientRect && boundingClientRect.height) || 0;
            scrollHeight = window.innerHeight;
            reachedLimit = top + height <= scrollHeight + scrollBuffer;
        } else {
            top = e.target.scrollTop;
            height = e.target.offsetHeight;
            scrollHeight = e.target.scrollHeight;
            reachedLimit = top + height + scrollBuffer >= scrollHeight;
        }
        if (reachedLimit && numRenderRows !== data.length && !this.isLoading) {
            this.loadMore(progressiveAmount);
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.data.length !== this.props.data.length) {
            this.initializeList(nextProps);
        }
    }

    componentWillUnmount() {
        const {useWindowScroll} = this.props;
        if (window.requestIdleCallback) window.cancelIdleCallback(this.requestId);
        const scrollParent = useWindowScroll ? window : this.ref && this.ref.parentElement;
        scrollParent && scrollParent.removeEventListener('scroll', this.handleScroll);
    }

    initializeList(props: Props) {
        const {data, isActive, initialAmount} = props;
        if (window.requestIdleCallback) window.cancelIdleCallback(this.requestId);
        this.setState(
            {
                numRenderRows: isActive ? initialAmount : data.length,
            },
            () => {
                this.progressivelyLoadMore(false);
            }
        );
    }

    progressivelyLoadMore = (immediateLoad: boolean = true) => {
        const {data, idleAmount} = this.props;
        const {numRenderRows} = this.state;
        if (!window.requestIdleCallback || idleAmount === 0) return;
        if (immediateLoad) this.loadMore(idleAmount);
        if (numRenderRows < data.length) {
            this.requestId = window.requestIdleCallback(this.progressivelyLoadMore);
        }
    };

    loadMore(amount: number = 10) {
        const {data} = this.props;
        if (this.state.numRenderRows >= data.length) return;
        this.isLoading = true;
        this.setState(
            state => ({
                numRenderRows: Math.min(state.numRenderRows + amount, data.length),
            }),
            () => {
                this.isLoading = false;
            }
        );
    }

    render() {
        const {style, renderItem, data, renderLoader} = this.props;
        const {numRenderRows} = this.state;
        return (
            <div
                ref={ref => {
                    this.ref = ref;
                }}
                style={style}
            >
                {times(numRenderRows, (i: number) => renderItem(data, i))}
                {numRenderRows < data.length && renderLoader && renderLoader()}
            </div>
        );
    }
}

export default ReactProgressiveList;
