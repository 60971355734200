import {useState, useCallback} from 'react';
import styled from 'styled-components';
import CompoundButtonSet from '../compound-components/button-set/CompoundButtonSet';
import CoreText from '../CoreText';
import {ModalSize} from './types';

export const ModalActions = styled(CompoundButtonSet).attrs({size: 's'})`
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: flex-end;
    margin-top: ${({theme}) => theme.spacing(2)}px;
`;

export const ModalTitle = styled(CoreText)<{modalSize?: ModalSize}>`
    font-family: Apercu Pro, sans-serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 100%;
    flex: 0 0 auto;
    margin-bottom: ${({theme, modalSize}) => theme.spacing(modalSize === 's' ? 2 : 3)}px;
`;

export const ModalSubTitle = styled(CoreText)`
    font-family: Apercu Pro;
    font-weight: 300;
    font-size: 14px;
    line-height: 100%;
    flex: 0 0 auto;
    margin-bottom: 12px;
    margin-top: -8px;
`;

export const ModalContent = styled.div<{minHeight?: number}>`
    color: ${({theme}) => theme.colors.neutral.black};
    font-size: 14px;
    font-family: Roboto;
    min-height: ${({minHeight}) => minHeight}px;
`;

export const useModalState = () => {
    const [isOpen, setIsOpen] = useState(false);

    const open = useCallback(() => {
        setIsOpen(true);
    }, []);
    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    return {isOpen, open, close};
};
