import React, {memo} from 'react';

const Icon = ({color}) => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.28173 28.3084C5.01947 29.5918 3.89027 30.8746 2.82995 32.3256C2.5082 32.7649 1.74166 33.483 2.1526 34.0704C2.48788 34.5489 3.16553 34.3933 3.65978 34.4037C4.44574 34.4203 5.23907 34.445 6.03122 34.4403C7.74298 34.431 9.45474 34.4119 11.1662 34.3913C14.6286 34.3491 18.0907 34.2989 21.5533 34.3015C23.9975 34.3036 23.9835 34.3015 27.2462 34.3913M31.9161 29.7039C31.1801 28.978 30.2964 28.0715 30.297 28.0668C30.6514 26.1519 30.8602 23.7659 31.1634 21.8353C31.5095 19.6323 31.8642 17.4305 32.2611 15.2356C32.3514 14.7359 32.6002 13.7803 32.0632 13.503C31.3409 13.1299 30.1439 13.1139 29.365 13.102C28.2609 13.0849 24.8647 13.0724 23.7591 13.1067C18.2941 13.2757 18.0433 13.3064 11.4273 13.4101C9.94457 13.4333 7.84778 13.5048 5.75569 13.5309C4.27118 13.5495 2.35719 13.469 2.98714 15.6064C3.27886 16.596 3.44194 17.3893 3.74161 18.3797C4.23144 19.9993 4.34654 20.9549 4.98091 23.2192C5.44189 24.8647 5.93879 26.356 6.21108 28.048"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M6.81271 16.4505C10.8166 16.0568 14.3236 16.4191 18.3449 16.2565C20.0679 16.1869 22.3445 16.1293 24.0682 16.0761C25.0699 16.0451 26.0733 16.0385 27.075 16.0795C27.609 16.1013 28.1523 16.0345 28.6825 16.1216C29.4438 16.2468 29.3486 16.4485 29.2716 17.0423C28.9988 19.1459 28.674 20.5944 28.453 22.1981C28.2257 23.8481 28.2257 24.7554 27.7812 26.7672C27.6895 27.182 27.498 27.2303 26.9978 27.2303C25.1193 27.2305 23.4701 27.1496 21.5859 27.2303C19.5699 27.3166 17.46 27.177 15.4421 27.2407C14.0941 27.2832 12.7453 27.3057 11.3965 27.309C9.80669 27.3129 8.9425 27.4367 8.77795 26.8323C8.56211 26.0403 8.24912 25.2172 8.07066 24.4312C7.37947 21.8383 6.99417 20.0128 6.31008 17.8671C5.96789 16.7948 6.40476 16.4906 6.81271 16.4505Z"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M10.3055 19.8748C11.6714 21.4384 14.3942 20.8622 15.0303 19.4233"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M21.03 19.8218C22.0162 21.2167 24.9326 21.0433 25.7837 19.6989"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M19.2377 6.26311C20.7208 5.9665 22.9408 5.49685 23.6869 5.67013C23.7252 5.67927 20.4342 8.49562 19.9622 8.89409C19.8123 9.02003 19.6389 9.13442 19.494 9.263C21.314 9.263 23.6869 9.15208 24.8733 8.89409"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M28.0238 3C28.7937 3.01288 29.5674 3.07886 30.336 3.13604C31.527 3.22432 32.8027 3.50205 33.9893 3.62113C34.0373 3.62615 31.5418 4.64471 31.4986 4.6733C30.0183 5.46439 28.0402 6.29543 26.9496 7.08275C29.2511 7.71087 31.1039 8.02493 34.0682 8.33898"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M2.03434 34.5C1.53433 35.5 6.62965 35 8.53993 35C11.353 35 14.6527 35 17.4629 35C20.6034 35 24.0225 35 27.3564 35"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M12.7123 33.8478C12.9254 33.1647 13.5716 31.1906 14.0289 30.7097C14.9317 30.4442 18.3479 30.7101 21.314 30.7098C22.6365 30.8939 22.5004 31.4749 23.3903 33.6025"
            stroke="black"
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M29.4516 30.1796C30.1292 29.4243 35.6058 29.7232 36.5435 29.9432C37.0504 30.1632 37.0426 30.3148 37.0312 31.3731C36.9937 32.8763 37.1683 37.0943 36.7071 37.5795C36.2134 38.0978 30.6489 38.1857 30.0917 37.5692C29.7337 36.6963 29.9416 36.0293 29.7505 36.0513C28.1963 35.8311 27.8188 35.6448 27.6307 34.5118C27.053 30.9994 29.5137 31.9664 29.5137 31.2745L29.4516 30.1796Z"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M34.1161 30.2695C34.3325 31.4732 34.544 32.7653 34.708 30.3093"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path d="M29.9157 36.814L29.6191 30.8818" stroke={color} strokeWidth="1.3" strokeLinecap="round" />
        <path
            d="M2.93433 14.9996C2.93433 14.1425 3.13383 13.6372 4.23893 13.5205C6.43042 13.2894 29.028 12.7289 31.3655 13.191C32.5621 13.4276 32.4343 13.5205 32.4343 14.4996"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
    </svg>
);

export default memo(Icon);
