/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {useTheme} from '@anywhere-expert/colors';

const Icon = () => {
    const {
        colors: {
            neutral: {black},
        },
    } = useTheme();

    return (
        <svg width="48" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.9121 28.5576C35.2803 20.0759 34.9137 14.3636 34.3184 7.0545C34.2973 6.79506 34.0804 6.59424 33.8201 6.59424H4.4009C4.4009 14.5916 4.95373 20.178 5.59869 28.5576"
                stroke={black}
                strokeOpacity="0.8"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M36.766 5.83367C37.7705 14.3908 38.2374 19.7661 38.3997 28.7439C41.4508 32.5056 43.1933 35.0444 45.2475 38.3935C46.0585 39.7157 45.0956 41.4064 43.5446 41.4064H14.4333C12.375 41.4064 10.4603 40.3513 9.3609 38.6112L3.47943 29.3026C2.23446 20.1286 1.67992 14.7074 1.78695 6.02829C1.80036 4.94106 2.68988 4.0708 3.7772 4.0708H34.7818C35.7946 4.0708 36.6479 4.82784 36.766 5.83367Z"
                stroke={black}
                strokeOpacity="0.8"
                strokeWidth="1.5"
            />
            <path
                d="M9.86342 39.9618L10.4975 39.5612L9.86342 39.9618ZM45.2457 39.911C45.0312 39.5566 45.1446 39.0955 45.499 38.8811C45.8534 38.6666 46.3145 38.78 46.529 39.1344L45.2457 39.911ZM3.23951 27.9484L2.49449 28.0348L3.23951 27.9484ZM4.46126 31.4117L5.09531 31.0111L4.46126 31.4117ZM2.47113 9.66247C2.86537 18.1512 3.08932 20.1344 3.98452 27.8621L2.49449 28.0348C1.59655 20.2834 1.36902 18.2645 0.97274 9.73206L2.47113 9.66247ZM5.09531 31.0111L10.4975 39.5612L9.22937 40.3624L3.82721 31.8123L5.09531 31.0111ZM13.4088 41.175H44.5593V42.675H13.4088V41.175ZM44.5593 41.175C44.7543 41.175 45.0121 41.0339 45.189 40.7137C45.3654 40.3942 45.3535 40.0892 45.2457 39.911L46.529 39.1344C46.9899 39.8961 46.8591 40.7923 46.502 41.4389C46.1452 42.0848 45.4546 42.675 44.5593 42.675V41.175ZM10.4975 39.5612C11.134 40.5687 12.2325 41.175 13.4088 41.175V42.675C11.7132 42.675 10.1383 41.8009 9.22937 40.3624L10.4975 39.5612ZM3.98452 27.8621C4.11413 28.981 4.49414 30.0596 5.09531 31.0111L3.82721 31.8123C3.10542 30.6699 2.64989 29.3763 2.49449 28.0348L3.98452 27.8621Z"
                fill={black}
                fillOpacity="0.8"
            />
            <path d="M38.4919 28.7441H3.57163" stroke={black} strokeOpacity="0.8" strokeWidth="1.125" />
            <path
                d="M40.8057 36.6245L36.383 30.5239H7.49278L11.8442 36.6245H40.8057Z"
                stroke={black}
                strokeOpacity="0.8"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.55846 33.4456H38.2141"
                stroke={black}
                strokeOpacity="0.8"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M33.7014 36.5439L29.8344 30.5737"
                stroke={black}
                strokeOpacity="0.8"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M25.8181 36.5437L22.0777 30.5737"
                stroke={black}
                strokeOpacity="0.8"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.0615 36.5448L14.3908 30.6191"
                stroke={black}
                strokeOpacity="0.8"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.0776 41.3993L19.4783 36.6245"
                stroke={black}
                strokeOpacity="0.8"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.3187 41.4468L31.4079 36.6245"
                stroke={black}
                strokeOpacity="0.8"
                strokeWidth="1.125"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
