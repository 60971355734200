/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 19.132l-4.445-2.964a1 1 0 0 0-1.11 0L7 19.132V4h10v15.132zM17 2H7c-1.103 0-2 .898-2 2v17a1 1 0 0 0 1.555.832L12 18.203l5.445 3.629A.998.998 0 0 0 19 21V4c0-1.102-.897-2-2-2z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
