import React from 'react';

const useDetectEscapeKey = (onEscapePressed: (e: KeyboardEvent) => void, shouldAddListener: boolean = true) => {
    const handleKeyPress = event => {
        if (event.key === 'Escape') {
            onEscapePressed?.(event);
        }
    };

    React.useEffect(() => {
        shouldAddListener && document.addEventListener('keydown', handleKeyPress);

        return () => document.removeEventListener('keydown', handleKeyPress);
    }, [onEscapePressed, shouldAddListener]);
};

export default useDetectEscapeKey;
