import React, {memo} from 'react';

const Icon = ({color}) => (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M27.9631 27.4051C29.7904 27.3059 30.1635 27.43 30.7787 28.4581C31.333 29.9164 32.208 32.2631 33.3689 31.6475C33.6581 31.4941 33.6907 29.666 33.7099 29.2189C33.7344 28.6496 33.6036 27.4156 34.0927 27.0893C34.3326 26.9291 36.1632 26.8393 36.9604 26.7613C37.8207 26.6772 38.5204 26.8625 39.1998 26.2099C39.6383 25.789 39.7632 25.4102 39.7925 24.8118C39.8867 22.8808 39.8202 21.7499 40.0155 19.5847C40.0155 17.0833 40.9981 13.2475 37.3258 13.0589C34.1471 12.8958 28.0228 13.1075 24.8718 13.2576C23.614 13.3177 22.2092 13.2521 21.0521 13.8211C19.1635 14.7497 19.7908 17.934 19.8126 19.6137C19.8191 20.1123 19.8283 20.6108 19.8416 21.1091"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M27.7602 32.4683C28.0333 30.9998 28.0372 29.4831 27.9483 27.9942C27.8604 26.5199 27.7676 25.0535 27.706 23.5618C27.6194 21.4724 26.1765 21.1154 24.4755 21.0332C22.4675 20.9364 20.4599 21.0776 18.4523 21.122C16.6944 21.1609 13.7867 21.1484 12.0548 21.5407C10.8997 21.8023 10.5409 22.4285 10.5409 23.5542C10.5409 24.2005 10.5951 24.5185 10.5951 25.1627C10.5951 26.1455 10.5951 26.3645 10.0152 26.5714C8.86365 27.1905 8.00002 27.435 8.00002 28.0161C8.00002 29.0476 9.64342 29.5119 10.0152 29.6667C10.9883 30.0715 10.5951 30.2857 10.8128 31.7758C10.8601 32.7989 10.912 33.4719 12.0007 33.6821C13.97 34.0627 17.6131 34.0144 19.5964 33.9711C21.8674 33.9218 23.5117 33.9849 25.7597 33.6242C26.4334 33.5166 27.6106 33.272 27.7602 32.4683Z"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M24.0007 34.4242C23.9903 35.0702 24.0942 37.3158 24.1362 37.9581C24.2102 39.0773 26.0377 38.962 27.3267 38.9951C28.4693 39.0245 29.838 38.9122 30.9809 38.9112C31.9247 38.9105 32.9782 38.9333 35.2999 38.8913C37.1936 38.8573 38.5714 39.0205 38.9763 38.1771C38.9763 37.6709 38.9763 36.5633 38.9763 34.7911C38.9763 34.7911 41.1479 33.7297 40.992 32.9362C40.8615 32.2756 38.6767 32.2394 38.6775 31.6898C38.678 31.1524 38.6599 30.6443 38.6775 30.2657C38.7578 30.097 38.6686 29 37.6694 29"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M23.7665 15.9173C22.3849 15.887 22.0967 17.9689 23.5277 18.2332C24.7168 18.4525 25.591 16.4922 24.2147 16.1712"
            fill={color}
        />
        <path
            d="M23.7665 15.9173C22.3849 15.887 22.0967 17.9689 23.5277 18.2332C24.7168 18.4525 25.591 16.4922 24.2147 16.1712"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M27.2498 17.083C29.0792 17.083 29.9585 17.083 32 17.083"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M13.7101 23.7992C12.6181 23.934 12.76 25.4284 13.849 25.541C15.1451 25.4515 14.8735 23.6556 13.7101 23.7992Z"
            fill={color}
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M16.7499 24.666C17.5582 24.666 18.6296 24.666 19.0832 24.666"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path d="M29 20C30.0104 20 31.3496 20 31.9166 20" stroke={color} strokeWidth="1.3" strokeLinecap="round" />
        <path d="M20.5 29C18.9326 29 15.9172 29 14 29" stroke={color} strokeWidth="1.3" strokeLinecap="round" />
        <path d="M30 35C32.1376 35 32.49 35 34.9583 35" stroke={color} strokeWidth="1.3" strokeLinecap="round" />
        <path
            d="M34.5 17.0747C34.6979 16.6905 35.3626 16.102 36.5 16.4995C37.5 16.849 37.4804 17.8745 37.2534 18.4548C37.0703 18.9227 36.4961 19.1308 36.225 19.4995C35.9881 19.8216 36.1 20.4995 36.225 20.9995"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M23.8433 30.0619C23.5149 30.3902 24.1347 30.6456 24.4264 30.062C24.4264 30.062 24.1716 29.7336 23.8433 30.0619Z"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M36.2467 23.5832C36.6062 23.5952 36.7533 22.9751 36.3038 23.0008C35.9508 23.0212 35.872 23.5706 36.2467 23.5832Z"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M22.2915 25.2501C22.6947 24.0516 25.1044 24.3178 24.903 25.8975C24.7442 27.1434 23.5924 26.878 23.7645 28.1668"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M24 35C25 35 26 34.5 26 34.5C26 34.5 27 34 27.5 33"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path d="M28.5 28.5C28.8843 28.5 29.6275 28 30 28" stroke={color} strokeWidth="1.3" strokeLinecap="round" />
    </svg>
);
export default memo(Icon);
