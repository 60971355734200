import {LogFunc} from '../types';
import {monitor} from '@anywhere-expert/monitor';

const log: LogFunc = async level => {
    if (!monitor.initializeTimestamp) return;
    switch (level) {
        case 'error': {
            monitor.increment('Error');
            break;
        }
        case 'warn': {
            monitor.increment('Warning');
            break;
        }
        default: {
            return;
        }
    }
};

export default log;
