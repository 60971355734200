/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M20.485 3.516A11.921 11.921 0 0 0 12 0a11.922 11.922 0 0 0-8.486 3.516A11.92 11.92 0 0 0 0 12.001c0 3.205 1.248 6.219 3.514 8.486l1.414-1.414A9.94 9.94 0 0 1 2 12.001 9.935 9.935 0 0 1 4.928 4.93 9.939 9.939 0 0 1 12 2a9.935 9.935 0 0 1 7.071 2.93A9.932 9.932 0 0 1 22 12a9.935 9.935 0 0 1-2.929 7.072l1.414 1.414A11.921 11.921 0 0 0 24 12c0-3.205-1.248-6.218-3.515-8.484z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.35 13.466c.397-.366.65-.885.65-1.466a2 2 0 1 0-4 0c0 .581.252 1.101.649 1.466l-4.544 9.087A1.001 1.001 0 0 0 7 24h10a1 1 0 0 0 .894-1.447l-4.543-9.087zM8.619 22l1-2h4.764l1 2H8.618zm2-4L12 15.236 13.382 18h-2.764z"
            fill={color}
        />
        <path
            d="M5 12c0-3.86 3.14-7 7-7s7 3.14 7 7c0 .893-.17 1.768-.503 2.6l-1.857-.745A4.95 4.95 0 0 0 17 12c0-2.758-2.243-5-5-5s-5 2.242-5 5c0 .635.122 1.258.36 1.854l-1.856.745A6.965 6.965 0 0 1 5 12z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
