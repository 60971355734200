import React, {ComponentType, ClassAttributes, forwardRef} from 'react';
import styled from 'styled-components';
import {Theme, useTheme, palette} from '@anywhere-expert/colors';

export type TextSize = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
export type TextType = 'primary' | 'secondary' | 'tertiary' | 'black' | 'white' | 'warning' | 'error' | 'soluto';
export type TextFamily = 'default' | 'apercu';
type Weight = 'light' | 'normal' | 'medium' | 'bold';

export type CoreTextProps = {
    textType?: TextType;
    weight?: Weight;
    family?: TextFamily;
    italic?: boolean;
    size?: TextSize;
    className?: any;
    disableTextOverflow?: boolean;
    [x: string]: any;
};

const CoreTextSpan = styled.span<any>`
    font-size: ${({textSize}) => textSize.size};
    line-height: ${({textSize}) => textSize.height};
    font-weight: ${({weight}) => weight};
    font-style: ${({italic}) => (italic ? 'italic' : 'normal')};
    color: ${({textColor}) => textColor};
    display: inline-block;
    font-family: ${({family}) => (family === 'default' ? `Roboto` : 'Apercu Pro')};
    max-width: 100%;
    ${({disableTextOverflow}) =>
        disableTextOverflow &&
        `
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;        
    `};

    & > svg {
        overflow: visible;
        fill: ${({textColor}) => textColor};
    }
`;
export type TextSizeProps = {size: string; height: string};

export const getTextSize = (size: TextSize): TextSizeProps => {
    switch (size) {
        case 'xs':
            return {size: '10px', height: '13px'};
        case 's':
            return {size: '12px', height: '16px'};
        case 'm':
            return {size: '14px', height: '20px'};
        case 'l':
            return {size: '16px', height: '24px'};
        case 'xl':
            return {size: '20px', height: '26px'};
        case 'xxl':
            return {size: '30px', height: '30px'};
        default:
            throw new Error(`unknown text size, given ${size}`);
    }
};

const getWeight = (weight: Weight): number => {
    switch (weight) {
        case 'light':
            return 100;
        case 'medium':
            return 500;
        case 'bold':
            return 700;
        default:
            return 400;
    }
};
export const getTextColor = (theme: Theme, textType: TextType) => {
    switch (textType) {
        case 'primary':
            return theme.colors.contrast.primary;
        case 'secondary':
            return theme.colors.contrast.secondary;
        case 'tertiary':
            return theme.colors.contrast.caption;
        case 'black':
            return palette.neutral.black;
        case 'white':
            return palette.neutral.white;
        case 'warning':
            return theme.colors.semantic.yellow.normal;
        case 'error':
            return theme.colors.semantic.red.normal;
        case 'soluto':
            return theme.colors.brand.blue;
        default:
            throw new Error(`unknown text type, given ${textType}`);
    }
};

const CoreText: ComponentType<CoreTextProps & ClassAttributes<HTMLSpanElement>> = forwardRef<
    HTMLSpanElement,
    CoreTextProps
>(
    (
        {italic = false, size = 's', textType = 'primary', weight = 'normal', family = 'default', children, ...others},
        ref
    ) => {
        const theme = useTheme();
        const textColor = getTextColor(theme, textType as TextType);
        const textSize = getTextSize(size as TextSize);
        const textWeight = getWeight(weight as Weight);

        return (
            <CoreTextSpan
                weight={textWeight}
                italic={italic}
                textColor={textColor}
                family={family}
                textSize={textSize}
                ref={ref}
                {...others}
            >
                {children}
            </CoreTextSpan>
        );
    }
);

export default CoreText;
