/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M13.361 5.132l3.011-1.205-.744-1.857-3.117 1.248L13.8 1.599a1 1 0 00-1.041-1.57L9 .965 5.242.029A1 1 0 004.2 1.599l1.289 1.719L2.372 2.07l-.744 1.858 3.011 1.205C1.876 6.67 0 9.619 0 13c0 4.962 4.038 9 9 9s9-4.038 9-9c0-3.381-1.876-6.33-4.639-7.868zM12 11H8.5a.5.5 0 000 1h1c1.378 0 2.5 1.122 2.5 2.5 0 1.207-.86 2.217-2 2.449V18H8v-1H6v-2h3.5a.5.5 0 000-1h-1A2.503 2.503 0 016 11.5c0-1.208.86-2.217 2-2.449V8h2v1h2v2z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
