import React, {memo} from 'react';

const Icon = ({color}) => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.46503 19.9559C2.34003 22.9559 2.77753 27.2684 5.52753 29.5184C8.34003 31.8309 12.7775 32.1434 15.7775 28.6434C18.59 25.3309 17.5275 20.0184 13.465 17.8934C10.465 16.2059 6.02753 17.7684 4.46503 19.9559Z"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22.5899 22.2059C22.1524 23.5809 22.0899 24.4559 22.1524 25.0184C22.5274 28.3934 25.5899 31.6434 29.3399 31.4559C32.7774 31.2684 36.4024 28.9559 36.1524 23.7684C35.9649 20.2059 32.9024 17.5184 29.5274 17.3309C26.2774 17.1434 23.4024 19.6434 22.5899 22.2059Z"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.9648 24.706C20.2148 24.1435 19.5898 24.1435 17.7148 24.6435C18.9023 24.331 20.7773 24.331 21.9648 24.706Z"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.9648 25.956C20.2148 25.3935 19.5898 25.3935 17.7148 25.8935C18.9023 25.581 20.7773 25.581 21.9648 25.956Z"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3.83984 20.956C5.08984 18.2685 7.02734 14.5185 8.33984 12.831C10.3398 10.1435 14.3398 10.3935 15.5898 12.5185C15.8398 13.0185 16.4648 14.0185 16.8398 18.1435"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M34.9023 20.081C34.5273 19.456 31.6523 13.5185 30.0273 12.1435C28.5273 10.8935 26.1523 10.8935 24.8398 11.956C23.5898 12.956 23.0273 13.956 22.9023 18.331"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.5345 11.1992C10.6867 11.004 10.848 10.8858 11.5328 10.4339C12.1652 10.0226 13.4996 10.1118 14.0935 10.3896C14.6589 10.6507 14.9208 11.1992 15.1793 11.7784"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.875 11.1979C28.705 11.0179 28.5333 10.9155 27.8089 10.5305C27.1403 10.1808 25.8204 10.3958 25.2554 10.7285C24.7172 11.0419 24.5083 11.6128 24.3058 12.2138"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.53735 18.9822C6.16235 20.6072 6.16235 23.3572 7.53735 24.9197C9.59985 27.3572 12.9749 26.8572 14.6624 24.9822C16.5374 22.8572 15.9749 19.8572 13.7249 18.3572C11.5999 16.9197 8.91235 17.3572 7.53735 18.9822Z"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M24.407 19.7162C22.9695 21.4662 22.8445 24.1537 24.782 26.0912C26.3445 27.6537 29.032 27.9037 30.8445 26.6537C34.7195 23.9662 32.157 17.4662 27.532 18.0287C26.2195 18.2162 25.157 18.8412 24.407 19.7162Z"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.6523 16.4442C17.0898 16.1317 18.4648 15.1317 19.5898 15.1317C20.9023 15.1317 21.9648 16.3192 22.5273 16.6942"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M22.0898 24.206C22.3398 22.2685 22.5898 19.206 22.9648 17.2685C22.9648 18.581 22.9648 19.8935 22.8398 21.206"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.5276 20.706C16.5901 19.206 16.7151 17.3935 16.4026 15.081C16.9651 17.8935 17.4651 21.6435 17.7151 24.3935"
            stroke={color}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.6526 23.9559C17.9651 23.8934 18.2776 23.7684 18.6526 23.7059C19.0276 23.6434 19.4026 23.5809 19.8401 23.6434C20.0276 23.6434 20.2151 23.7059 20.4026 23.7059C21.0276 23.8309 21.5901 24.0184 22.0276 24.2059"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
export default memo(Icon);
