import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 12.6668V11.3335H6V12.6668H11.3333V17.3335H9.33333V18.6668H14.6667V17.3335H12.6667V12.6668H18Z"
                fill={color}
                fillRule="evenodd"
            />
            <path
                d="M8.00008 6.66683H11.3334V10.0002H12.6667V6.66683H16.0001V8.00016H17.3334V6.00016C17.3334 5.63216 17.0354 5.3335 16.6667 5.3335H7.33341C6.96541 5.3335 6.66675 5.63216 6.66675 6.00016V8.00016H8.00008V6.66683Z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    </BaseIcon>
));

export default Icon;
