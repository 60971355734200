/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M12 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM17.657 9.757a1 1 0 1 1-1.414-1.414 1 1 0 0 1 1.414 1.414zM19 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM7.758 9.758a1 1 0 0 1-1.415-.001.999.999 0 1 1 1.415-1.413.999.999 0 0 1 0 1.414zM5 15a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 14C1 7.935 5.935 3 12 3s11 4.935 11 11v5H1v-5zm14.858 3H21v-3c0-4.963-4.038-9-9-9s-9 4.037-9 9v3h5.142A3.995 3.995 0 0 1 11 14.142V10h2v4.142A3.995 3.995 0 0 1 15.858 17zM12 16c-.739 0-1.385.402-1.731 1h3.462c-.346-.598-.992-1-1.731-1z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
