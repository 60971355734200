/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M20.526 8.149a1 1 0 00-.973-.044L17 9.382V8c0-1.103-.897-2-2-2H6c-1.103 0-2 .897-2 2v8c0 1.104.897 2 2 2h9c1.103 0 2-.896 2-2v-1.382l2.553 1.276a.992.992 0 00.973-.043c.294-.183.474-.504.474-.851V9c0-.347-.18-.668-.474-.851z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
