import React from 'react';
import styled from 'styled-components';
import {IconSize} from './types';

export interface BaseIconProps extends React.SVGProps<SVGSVGElement> {
    size?: IconSize;
}

const SVG = styled.svg`
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const BaseIcon = React.memo<BaseIconProps>(
    React.forwardRef(({size = 'l', children, ...svgProps}: BaseIconProps, ref) => {
        const boxSize = getIconSize(size);

        return (
            <SVG viewBox="0 0 24 24" width={boxSize} height={boxSize} ref={ref as any} {...svgProps}>
                {children}
            </SVG>
        );
    })
);

export default BaseIcon;

export function getIconSize(size: IconSize) {
    switch (size) {
        case 'xxs':
            return '12px';
        case 'xs':
            return '14px';
        case 's':
            return '16px';
        case 'm':
            return '20px';
        case 'l':
            return '24px';
        case 'xl':
            return '30px';
        case 'none':
            return undefined;
        default:
            return '24px';
    }
}
