import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.0047 11.0375C15.6181 10.4322 16.0001 9.59416 16.0001 8.66683C16.0001 6.82883 14.5047 5.3335 12.6667 5.3335H6.66675V6.66683H8.00008V17.3335H6.66675V18.6668H13.3334C15.5394 18.6668 17.3334 16.8728 17.3334 14.6668C17.3334 13.0582 16.3767 11.6722 15.0047 11.0375ZM12.6667 6.66683C13.7694 6.66683 14.6667 7.56416 14.6667 8.66683C14.6667 9.7695 13.7694 10.6668 12.6667 10.6668H9.33341V6.66683H12.6667ZM13.3334 17.3335H9.33341V12.0002H13.3334C14.8041 12.0002 16.0001 13.1962 16.0001 14.6668C16.0001 16.1375 14.8041 17.3335 13.3334 17.3335Z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    </BaseIcon>
));

export default Icon;
