import React, {memo} from 'react';

const Icon = () => (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)" stroke="#000" strokeLinecap={'round'} strokeLinejoin={'round'}>
            <path d="M25.83 12.68c-.438-2-.313-3.75.5-5 .812-1.25 2.187-1.876 3.625-1.626 1 .125 1.937.75 2.437 1.625.938 1.875.5 4.25-.312 6.125a4.455 4.455 0 01-1.063 1.313c-1.875 1.375-4.625.437-5.187-2.438z" />
            <path d="M30.268 15.992c-1.125.125-1.875-.063-2.375-.438" strokeMiterlimit={'10'} />
            <path d="M32.83 12.93c.188-.126.375-.188.438-.313.062-.063.062-.063.062-.125M25.518 12.68a.488.488 0 01-.25-.25" />
            <path d="M30.018 13.367c-.5.25-1.187.312-1.625 0M26.018 17.242c.187.687 1.562 1.625 3.062 1.687 1.438.063 2.938-.562 3.375-1.375" />
            <path d="M27.643 15.617c0 .562-.063.812-.125 1.437l-.5.063c-1.625.5-2.438.937-2.938 1.437s-.687.938-.875 1.375c-.187.5-.125 1.313 1.25 1.375 1.938.063 7.563.125 8.563.063 1.375-.063 2-.313 1.875-1.375-.125-.75-.75-1.563-1.813-2a24.977 24.977 0 00-2.062-.75l-.125-.625c-.063-.5-.063-.438-.063-.938M8.08 27.68c-.437-2-.312-3.75.5-5 .813-1.25 2.188-1.876 3.625-1.626 1 .125 1.938.75 2.438 1.625.937 1.875.5 4.25-.313 6.125a4.455 4.455 0 01-1.062 1.313c-1.875 1.375-4.625.437-5.188-2.438z" />
            <path d="M12.518 30.992c-1.125.125-1.875-.063-2.375-.438" strokeMiterlimit={'10'} />
            <path d="M15.08 27.93c.188-.126.375-.188.438-.313.375-.438.25-1.438-.188-1.75M7.768 27.68c-.25-.188-.375-.313-.5-.5-.375-.626-.188-1.626.562-1.813" />
            <path d="M12.268 28.367c-.5.25-1.188.312-1.625 0M9.268 22.18c.312.374 1.5 1.5 3.75 1.374M8.893 32.304c.125.688 1.25 1.625 2.437 1.688 1.188.062 2.313-.563 2.688-1.375" />
            <path d="M9.893 30.617c0 .562-.063.812-.125 1.437l-.5.063c-1.625.5-2.438.937-2.938 1.437s-.75 1.188-.937 1.625c-.125.625.25 1.063 1 1.063s9.25-.063 9.875-.063 1.062-.5.937-1.375c-.187-.875-.875-1.5-1.875-1.937a24.977 24.977 0 00-2.062-.75l-.125-.625c-.063-.5-.063-.438-.063-.938" />
            <path
                d="M6.893 17.93c-.375 0-.75-.063-1.063-.25-.563-.313-.75-1-.813-1.626-.062-1.125.063-.687.063-1.75 0-2.125 0-4.25.125-6.437a1.465 1.465 0 011.313-1.313c1.375-.062 6.125-.187 7.875-.187.624 0 1.562.062 2.312.125.688.062 1.25.687 1.25 1.375v3l2.5-.5c.188-.063.375.25.25.437l-.25.25c-.313.313-1.625 1.813-2.188 2.563-.187.25-.312.562-.312.875v2c0 .75-.625 1.437-1.438 1.437h-4c-.812 0-4.75.063-5.624 0z"
                strokeMiterlimit={'10'}
            />
            <path
                d="M15.643 9.93c-2.25-.188-5.625-.188-8.063-.126M15.518 12.304c-2.312-.062-5.75-.062-8.062-.125M15.143 14.68c-2.188-.063-4.063 0-6.25-.063"
                strokeMiterlimit={'10'}
            />
            <path
                d="M27.58 35.43h-4c-.75 0-1.437-.626-1.437-1.438v-1.375c0-.313-.125-.625-.313-.875-.624-.75-1.874-2.25-2.187-2.563l-.25-.25c-.125-.187.063-.562.25-.562l2.5.5v-2.25c0-.688.5-1.313 1.25-1.375.75-.063 1.625-.125 2.313-.125 1.75 0 6.5.125 7.875.187.687.063 1.25.625 1.312 1.313.125 2.125.25 5.812.188 6.937-.063.625-.25 1.313-.813 1.625-.313.188-.688.25-1.063.25-.874.063-4.75 0-5.624 0z"
                strokeMiterlimit={'10'}
            />
            <path d="M24.518 28.68c2.25-.188 5.625-.188 8.062-.126M24.518 31.43h7" strokeMiterlimit={'10'} />
            <path d="M27.58 6.554c1.063 1.313 2.438 2.438 4.563 2.438" />
            <path d="M32.83 9.742c.063 1.75 2.063 3.812 2.438 5.812.187.938-.25 1.938-.938 2.5M25.705 9.304a9.517 9.517 0 01-1.062 3c-.813 1.438-1.688 2.438-1.438 4.188.063.687.438 1.312.938 1.687" />
        </g>
        <defs>
            <clipPath id="clip0">
                <path fill="#fff" d="M0 0h40v40H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default memo(Icon);
