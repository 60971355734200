/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 8.09L16.59 12 18 10.59l-6-6-6 6L7.41 12 11 8.09V20h2V8.09zM12 7z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
