import React from 'react';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {CheckboxUncheckedIcon, CheckboxCheckedIcon} from '@anywhere-expert/icons';
import {useTheme} from '@anywhere-expert/colors';
import CoreText from '../CoreText';
import {CoreCheckboxProps} from './types';

const StyledFormControlLabel = styled(FormControlLabel)`
    &.MuiFormControlLabel-root {
        padding-left: 3px;
    }

    ${({disabled}) => disabled && `opacity: 0.2;`}
`;

const CheckedIcon = () => {
    const theme = useTheme();
    return <CheckboxCheckedIcon size="s" color={theme.colors.neutral.black} />;
};
const UncheckedIcon = () => {
    const theme = useTheme();
    return <CheckboxUncheckedIcon size="s" color={theme.colors.contrast.secondary} />;
};

const CoreCheckbox = ({checked, label, onChange, disabled, value, ...props}: CoreCheckboxProps) => {
    const checkbox = (
        <Checkbox
            checked={checked}
            value={value}
            onChange={onChange}
            disabled={disabled}
            color="default"
            icon={<UncheckedIcon />}
            checkedIcon={<CheckedIcon />}
            {...props}
        />
    );
    return (
        <StyledFormControlLabel disabled={disabled} label={<CoreText size="m">{label}</CoreText>} control={checkbox} />
    );
};

export default CoreCheckbox;
