/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path d="M15.998 3v5h4V6h-2V3h-2z" fill={color} />
            <path
                d="M16.998 14c-3.859 0-7-3.14-7-7 0-3.859 3.141-7 7-7s7 3.141 7 7c0 3.86-3.141 7-7 7zm0-12c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
                fill={color}
                fillRule="evenodd"
            />
            <path
                d="M14.998 16v2H7.664l-2.666 2v-2h-3V9h6V7h-6a2 2 0 00-2 2v9c0 1.103.896 2 2 2h1v4l5.334-4h6.666c1.104 0 2-.897 2-2v-2h-2z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
