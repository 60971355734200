import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M4.325 18.23a10.29 10.29 0 0 1-1.208-1.68l-.017-.027.017-.027c1.443-2.174 1.9-3.986 1.633-6.457a9.97 9.97 0 0 0-1.11-3.594l-.03-.056.019-.027c.415-.586.898-1.138 1.434-1.644l.095-.086.025.115c.07.329.192.652.354.96a11.54 11.54 0 0 1 1.287 4.168c.308 2.857-.294 5.157-2.022 7.69a3.8 3.8 0 0 0-.358.65l-.043.1-.076-.085z"
            fill="#93C01F"
        />
        <path
            d="M11.404 22c-.384 0-.773-.022-1.158-.063l-.108-.012.07-.085c.094-.118.184-.242.265-.371.786-1.227 1.6-2.032 2.832-3.087a15.688 15.688 0 0 1 7.48-3.56l.03-.007c.256-.037.51-.1.755-.186l.115-.041-.039.117c-.237.68-.543 1.342-.912 1.965l-.013.024-.027.005a13.921 13.921 0 0 0-6.201 3.069c-.912.781-1.552 1.388-2.125 2.166l-.017.024-.026.002c-.311.027-.62.04-.92.04zM21.18 8.05c-.053-.011-.108-.02-.161-.03-3.53-.566-6.831-2.463-9.301-5.341-.141-.165-.29-.345-.442-.537l-.088-.112.124-.012c.082 0 .171-.003.262-.003.737 0 1.481.078 2.213.23l.018.004.013.012c1.72 1.742 3.81 2.998 6.046 3.633l.018.006.012.015c.529.632.982 1.321 1.351 2.048l.055.111-.12-.025zM6.898 21.881c-.188 0-.374-.05-.538-.145l-.063-.041-.046-.033a1.075 1.075 0 0 1-.27-1.438l.136-.208.053-.076.005-.015c1.938-3.44 2.61-6.596 2.244-10.53a18.276 18.276 0 0 0-1.558-5.888 1.068 1.068 0 0 1 1.494-1.371l.018.01.053.034.044.03.019.015c.08.062.144.123.196.19.197.248.393.489.587.714 3.29 3.837 7.722 6.37 12.479 7.135l.047.01c.036.008.069.015.099.027.443.136.75.555.75 1.015 0 .036 0 .067-.004.096l-.007.073-.008.042c-.006.027-.012.055-.02.08l-.005.017a1.037 1.037 0 0 1-.062.159l-.007.014c-.011.025-.025.047-.036.068l-.012.017c-.014.022-.025.043-.04.061l-.015.02a.494.494 0 0 1-.042.053l-.06.063a1.218 1.218 0 0 1-.112.097l-.016.011-.067.045-.019.01a.696.696 0 0 1-.069.036l-.015.008-.072.031-.09.03a1.24 1.24 0 0 1-.087.02l-.051.009a21.045 21.045 0 0 0-10.125 4.782c-1.456 1.25-2.568 2.339-3.613 3.89l-.014.022-.069.126c-.03.053-.059.105-.09.157a1.07 1.07 0 0 1-.923.528zm3.565-13.416c.036.29.064.522.084.732.164 1.77.127 3.4-.106 4.987l-.193 1.284 1.033-.787a23.324 23.324 0 0 1 4.9-2.87l1.122-.482-1.122-.486a23.127 23.127 0 0 1-4.874-2.865l-1.006-.769.162 1.256z"
            fill="#2FB3E8"
        />
    </BaseIcon>
));

export default Icon;
