import React, {useCallback} from 'react';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import Tabs, {TabsActions} from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReactResizeDetector from 'react-resize-detector';

const TabsContainer = styled.div`
    & .MuiTabs-root {
        min-height: 0px;
    }
    & .MuiTabs-indicator {
        background-color: ${({theme}) => theme.colors.neutral.black};
    }

    & .MuiTabs-flexContainer {
        justify-content: space-between;
        align-items: stretch;
    }

    & .MuiTab-root {
        border-bottom: ${({theme}) => `2px solid ${theme.colors.neutral.grey.light}`};
        min-width: auto;
        max-width: initial;
        min-height: 26px;
        color: ${({theme}) => theme.colors.neutral.grey.deeper};
        padding: 2%;
        white-space: nowrap;
        text-transform: none;
        font-size: 14px;
        flex: 1;
        & .Mui-disabled {
            opacity: 1;
        }
    }

    & .Mui-selected {
        color: ${({theme}) => theme.colors.neutral.black};
    }
    & .Mui-disabled {
        color: ${({theme}) => theme.colors.contrast.caption};
    }

    & .Mui-wrapper {
        flex-direction: row;
        width: auto;
    }

    & .MuiTab-textColorInherit {
        &:disabled {
            opacity: 0.7;
            color: ${({theme}) => theme.colors.neutral.grey.deeper};
        }
    }
`;

export type CoreTab = {
    label: React.ReactNode;
    disabled?: boolean;
};

export type CoreTabsProps = {
    [tabId: string]: CoreTab;
};

type Props = {
    tabs: CoreTabsProps;
    selectedTab: string;
    onTabSelected: (tabId: string) => void;
};

export default observer(({tabs, selectedTab, onTabSelected}: Props) => {
    const handleChange = useCallback(
        (_: React.ChangeEvent<{}>, value: string) => {
            onTabSelected(value);
        },
        [onTabSelected]
    );

    const tabsActions = React.useRef<TabsActions>(null);

    const onResize = () => {
        tabsActions.current?.updateIndicator();
    };

    return (
        <TabsContainer>
            <Tabs value={selectedTab} onChange={handleChange} action={tabsActions}>
                {Object.entries(tabs).map(([id, tab]) => (
                    <Tab
                        value={id}
                        key={id}
                        disabled={!!tab.disabled}
                        label={tab.label}
                        disableRipple
                        data-test-id={`Tab_${id}`}
                    />
                ))}
            </Tabs>
            <ReactResizeDetector handleWidth handleHeight onResize={onResize} />
        </TabsContainer>
    );
});
