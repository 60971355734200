/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M11 22c-.402 0-.77-.243-.925-.619l-7-17A1 1 0 014.38 3.075l17 7c.399.165.648.567.617.998a1.002 1.002 0 01-.756.898l-7.418 1.854-1.854 7.418A1 1 0 0111 22zM5.838 5.84l4.93 11.969 1.262-5.05c.09-.358.37-.639.729-.728l5.05-1.263L5.839 5.839z"
                fill="#000"
            />
        </BaseIcon>
    ))
);

export default Icon;
