import {BehaviorSubject} from 'rxjs';
import User from './User';

/**
 * An observable of the current logged in user profile
 * @memberof auth
 */
export const userProfile$ = new BehaviorSubject<User>(
    new User({
        uid: '',
        email: '',
        isLoggedIn: false,
        loginRequired: true,
        accessToken: undefined,
        displayName: '',
        imageUrl: undefined,
        roles: [],
        queues: [],
        groups: [],
        expertise: [],
    })
);

export const setUserProfile = (profile: User) => {
    userProfile$.next(profile);
};

export const removeUserProfile = () => {
    if (!userProfile$) return;

    setUserProfile(
        new User({
            isLoggedIn: false,
            loginRequired: true,
            accessToken: undefined,
            displayName: userProfile$.value.displayName,
            email: userProfile$.value.email,
            imageUrl: userProfile$.value.imageUrl,
            uid: userProfile$.value.uid,
            roles: [],
            queues: [],
            groups: [],
            expertise: [],
        })
    );
};
