import React from 'react';
import {useTheme} from '@anywhere-expert/colors';

const Icon = React.memo(() => {
    const {
        colors: {
            neutral: {black},
        },
    } = useTheme();

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0764 8.32805C12.0173 8.269 12.1158 8.49536 12.1453 8.56425C12.2142 8.70204 12.2831 8.83982 12.3618 8.9776C12.598 9.43032 12.8244 9.91257 13.0015 10.3948C13.5133 11.8022 14.399 13.2784 14.6352 14.7645C14.8911 16.3589 12.9621 16.3195 12.5193 17.5891C12.2831 18.2485 12.6078 19.1047 12.8047 19.7444C13.4837 21.9982 14.773 23.5926 16.4166 25.2755C17.6566 26.5451 19.2018 27.5784 20.7863 28.3756C21.5048 28.7398 22.2626 29.1334 23.0597 28.7693C23.7585 28.4544 23.7191 27.2537 24.1226 26.6533C24.2998 26.3974 24.8017 26.2892 25.0675 26.2104C26.1008 25.925 27.3409 25.9054 28.3546 26.2695C30.4214 27.0175 34.4368 29.2023 32.6849 31.9088C32.4979 32.2041 32.3011 32.4895 32.0354 32.7158C31.4449 33.2177 30.5788 33.4638 29.8604 33.7098C27.2425 34.5759 24.0636 34.2905 21.5146 33.3752C18.6211 32.332 15.9343 31.2297 13.5133 29.2614C10.0883 26.4663 7.67715 21.6636 7.10633 17.3234C6.80124 14.9909 6.57485 11.5857 7.94285 9.48937C8.45462 8.71188 9.6061 7.36356 10.6788 7.51119C11.289 7.58992 11.7516 8.11153 12.1059 8.56425"
                stroke={black}
                stroke-width="1.53125"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M18.8971 12.7964C18.7888 12.9046 18.8872 12.4913 18.8971 12.3338C18.9364 11.9795 18.9856 11.635 19.0447 11.2906C19.1726 10.4639 19.3399 9.64702 19.5072 8.82031"
                stroke={black}
                stroke-width="1.53125"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M23.2864 16.1424C23.2568 16.1129 23.4438 15.8373 23.4635 15.8177C23.68 15.4732 23.8965 15.1189 24.1131 14.7646C24.9102 13.4261 25.6779 12.0778 26.4455 10.7295"
                stroke={black}
                stroke-width="1.53125"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M26.209 20.7183C27.7049 19.8621 30.1358 19.1534 32.055 18.6318"
                stroke={black}
                stroke-width="1.53125"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M30.9722 23.4746C30.982 23.5041 31.7103 23.4746 31.7496 23.4746C32.4976 23.4746 33.2358 23.4943 33.9837 23.4746"
                stroke={black}
                stroke-width="1.53125"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
});
export default Icon;
