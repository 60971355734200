/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3zM6 0a6 6 0 0 0-6 6v12a6 6 0 0 0 6 6h12a6 6 0 0 0 6-6V6a6 6 0 0 0-6-6H6z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.014 6.394a1.5 1.5 0 0 1 .092 2.12l-8.25 9a1.5 1.5 0 0 1-2.258-.054l-3.75-4.5a1.5 1.5 0 0 1 2.304-1.92l2.652 3.182 7.09-7.736a1.5 1.5 0 0 1 2.12-.092z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
