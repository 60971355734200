import {useEffect, useMemo} from 'react';
import {useAnalytics} from 'react-shisell';
import {usePrevious} from '@anywhere-expert/utils';
import {CoreModalProps} from './types';

export default function useModalAnalyticsDispatcher({
    name = 'CoreModal',
    showCloseButton,
    sendViewAnalytics = true,
    disableBackdropClick,
    disableEscapeKeyDown,
    analyticsExtras,
    analyticsScope,
}: CoreModalProps) {
    const analytics = useAnalytics();

    const analyticsDispatcher = useMemo(
        () =>
            analytics.dispatcher.createScoped(analyticsScope || name).withExtras({
                ModalName: name,
                ShowCloseButton: showCloseButton,
                DisableBackdropClick: disableBackdropClick,
                DisableEscapeKeyDown: disableEscapeKeyDown,
                ...analyticsExtras,
            }),
        [analytics, analyticsExtras, showCloseButton, disableBackdropClick, disableEscapeKeyDown, analyticsScope]
    );

    useEffect(() => {
        sendViewAnalytics && analyticsDispatcher.dispatch('View');
    }, []);

    const prevShowCloseButton = usePrevious(showCloseButton);

    useEffect(() => {
        if (prevShowCloseButton !== showCloseButton) {
            analyticsDispatcher.dispatch('ChangeShowCloseButtonState');
        }
    }, [prevShowCloseButton, showCloseButton]);

    return analyticsDispatcher;
}
