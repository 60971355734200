/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 12h-5c0-1.102-.897-2-2-2h-4c-1.103 0-2 .898-2 2H3v-2c0-.552.449-1 1-1h16c.551 0 1 .448 1 1v2zm-11 2v-2h4l-.003 2H10zm10 5c0 .552-.449 1-1 1H5c-.551 0-1-.448-1-1v-5h4c0 1.103.897 2 2 2h4c1.103 0 2-.897 2-2h4v5zM9 5h6v2H9V5zm11 2h-3V5c0-1.102-.897-2-2-2H9c-1.103 0-2 .898-2 2v2H4c-1.654 0-3 1.346-3 3v4h1v5c0 1.654 1.346 3 3 3h14c1.654 0 3-1.346 3-3v-5h1v-4c0-1.654-1.346-3-3-3z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
