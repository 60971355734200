/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {useTheme} from '@anywhere-expert/colors';

const Icon = () => {
    const {
        colors: {
            neutral: {black},
        },
    } = useTheme();

    return (
        <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.9175 38.1524C25.2594 36.8715 27.2861 37.0821 30.5232 37.0973"
                stroke={black}
                strokeWidth="1.51172"
                strokeMiterlimit="9.4426"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M54.242 26.5947C55.653 29.5496 55.2932 34.892 51.3536 39.3698"
                stroke={black}
                strokeWidth="1.51172"
                strokeMiterlimit="9.4426"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.92529 19.3115C7.45036 13.3614 11.657 9.3503 15.9361 8.90283"
                stroke={black}
                strokeWidth="1.51172"
                strokeMiterlimit="9.4426"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30.4648 58.5818C30.4648 58.5818 29.9276 53.2152 29.3915 48.3857"
                stroke={black}
                strokeWidth="2.01562"
                strokeMiterlimit="9.4426"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.9754 55.1826C15.9754 55.1826 15.8101 53.7546 14.1865 45.7021C13.9738 44.645 12.4037 41.7062 11.7839 40.2297C9.99903 35.9697 11.284 27.9193 11.7839 23.2582C12.6607 15.0777 14.8718 12.4816 17.5919 14.5759C19.7154 16.2115 18.8003 25.2305 18.8003 25.2305C18.8003 25.2305 20.4631 23.5041 20.8874 23.0677C21.9194 22.0397 28.0681 15.2219 31.8454 11.1261C34.7076 8.02306 36.7524 8.273 37.859 9.35236C39.148 10.6091 39.0412 12.0352 36.8492 14.8258C34.6521 17.6225 32.0983 20.8062 29.5425 23.8961C28.2969 25.4018 31.2357 22.203 32.566 20.9986C33.34 20.2912 35.662 18.6152 37.731 16.8414C38.7378 15.9787 40.0591 14.8339 40.7544 14.3219C45.0376 11.1725 48.4179 15.2259 44.7857 18.857C44.0339 19.6089 41.7371 21.7797 40.7544 22.7623C38.8648 24.652 37.1203 26.3411 33.9517 28.9352C33.6645 29.171 39.2447 24.9845 44.0298 21.8805C48.691 18.857 50.9585 23.1402 47.4312 26.0377C45.5698 27.5656 36.6788 35.1866 37.345 34.4046C37.345 34.4046 40.1296 32.7256 44.9107 30.069C48.312 28.1793 50.8316 32.4625 48.3644 34.3149C46.9999 35.3388 43.152 37.9188 41.7612 39.2652C37.472 43.4154 33.7764 46.327 29.2079 47.7591C27.2296 48.3809 25.0789 48.3184 23.5037 48.0977C23.3001 48.0695 22.7489 47.9425 22.9232 48.005C24.9197 48.7427 29.9295 50.1738 29.3924 50.1738"
                stroke={black}
                strokeWidth="2.01562"
                strokeMiterlimit="9.4426"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M55.9888 22.3076C55.9888 22.3076 59.4214 24.6024 58.3763 31.0867"
                stroke={black}
                strokeWidth="1.51172"
                strokeMiterlimit="9.4426"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
