const getLightContrastShade = (alpha: number) => `rgba(0,0,0,${alpha})`;
const getDarkContrastShade = (alpha: number) => `rgba(255,255,255,${alpha})`;

const palette = {
    brand: {
        purple: '#8223D2',
        blue: '#4A68F9',
        blueBrighter: '#6B8BFF',
        green: '#4EFCC8',
        tennis: '#D2FA46',
    },
    primary: {
        purple: {
            deeper: '#6708B7',
            bright: '#A839F3',
            brighter: '#CB5AFF',
        },
        blue: {
            deeper: '#253BAA',
            deep: '#2743CC',
            brighter: '#7D99FF',
        },
        green: {
            deeper: '#0B8350',
            deep: '#00B574',
            bright: '#00E7A1',
        },
        tennis: {
            deeper: '#73AA00',
            deep: '#85C300',
            bright: '#A8E106',
        },
    },
    semantic: {
        red: {
            bright: '#FF5070',
            normal: '#EC5347',
            deep: '#D91B1B',
        },
        yellow: {
            normal: '#F9D51C',
            deep: '#D1A508',
        },
        green: {
            normal: '#00E678',
            deep: '#00BF63',
        },
    },
    neutral: {
        black: '#000',
        white: '#fff',
        blacks: {
            b80: getDarkContrastShade(0.8),
            b70: getDarkContrastShade(0.7),
            b60: getDarkContrastShade(0.6),
            b50: getDarkContrastShade(0.5),
            b40: getDarkContrastShade(0.4),
            b30: getDarkContrastShade(0.3),
            b20: getDarkContrastShade(0.2),
            b10: getDarkContrastShade(0.1),
            b05: getDarkContrastShade(0.05),
        },
        whites: {
            w80: getLightContrastShade(0.8),
            w70: getLightContrastShade(0.7),
            w60: getLightContrastShade(0.6),
            w50: getLightContrastShade(0.5),
            w40: getLightContrastShade(0.4),
            w30: getLightContrastShade(0.3),
            w20: getLightContrastShade(0.2),
            w10: getLightContrastShade(0.1),
            w05: getLightContrastShade(0.05),
        },
        grey: {
            lightest: '#F0F0F5',
            lighter: '#E6E6EB',
            light: '#D5D6DA',
            deep: '#A6AAAF',
            deeper: '#6C747B',
            deepest: '#333F48',
        },
    },
    categorical: {
        yellow: '#FFCB05',
        orange: '#FF9961',
        lightRed: '#EA3661',
        magenta: '#ED54B0',
        purple: '#B136EA',
        blue: '#433CE2',
        blueLight: '#4AA8FF',
        cyan: '#34DEE0',
        greenLight: '#68DC72',
        glacierBlue: '#7A86A6',
        pink: '#EF8BF8',
        lilac: '#ACB6E2',
    },
};

export default palette;

export type Palette = typeof palette;
