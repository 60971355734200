import {observable, action, makeObservable} from 'mobx';
import uuid from 'uuid';
import {ModalContentComponent, OpenModalOptions, UpdateModalOptions} from './types';

const defaultOptions: Partial<OpenModalOptions> = {disableClickOutside: false};

class ModalStore {
    id: string;
    name: string;
    Content: ModalContentComponent;
    options: OpenModalOptions;
    isOpen: boolean;

    constructor(component: ModalContentComponent, options?: OpenModalOptions) {
        makeObservable(this, {
            options: observable.ref,
            isOpen: observable,
            setIsOpen: action,
            open: action,
            close: action,
            update: action,
        });

        this.id = options?.id || uuid();
        this.name = options?.name || component.displayName || 'Modal';
        this.Content = component;
        this.options = {...defaultOptions, ...options};
        this.isOpen = true;
    }

    setIsOpen = (isOpen: boolean) => {
        this.isOpen = isOpen;
    };

    open = () => {
        this.setIsOpen(true);
    };

    close = () => {
        this.setIsOpen(false);
    };

    update = (options?: UpdateModalOptions, mergeOldOptions: boolean = true) => {
        if (mergeOldOptions) {
            this.options = {...this.options, ...options};
        } else {
            this.options = {...defaultOptions, ...options};
        }
    };
}

export default ModalStore;
