/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.09 11L12 7.41 10.59 6l-6 6 6 6L12 16.59 8.09 13H20v-2H8.09zM7 12z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
