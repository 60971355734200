/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path fillRule="evenodd" clipRule="evenodd" d="M16 19H8v-2h8v2z" fill={color} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 20V10h20l.001 10H2zM22 8h-9V7c0-.551.449-1 1-1h1c1.654 0 3-1.346 3-3V2h-2v1c0 .55-.449 1-1 1h-1c-1.654 0-3 1.346-3 3v1H2c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h20c1.103 0 2-.897 2-2V10c0-1.103-.897-2-2-2z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM10 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM13 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM16 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM19 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM10 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM13 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM16 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM19 15a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
