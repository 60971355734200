/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.91 11L12 7.41 13.41 6l6 6-6 6L12 16.59 15.91 13H4v-2h11.91zM17 12z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
