import React, {useMemo} from 'react';
import styled from 'styled-components';
import {Theme} from '@anywhere-expert/colors';
import Tooltip, {TooltipProps} from '@material-ui/core/Tooltip';
import {TransitionProps} from '@material-ui/core/transitions';
import {makeStyles} from '@material-ui/styles';
import {palette} from '@anywhere-expert/colors';
import CoreText from './CoreText';
import CoreKeyboardKeys from './CoreKeyboardKeys';

export type StyleOverride = {[x: string]: any};

const TooltipWithKeysContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const KeyboardSequenceText = styled(CoreText)`
    margin-bottom: 8px;
`;

const useClasses = (styleOverride?: StyleOverride) =>
    makeStyles((theme: Theme) => ({
        tooltip: {
            backgroundColor: palette.neutral.black,
            color: palette.neutral.white,
            fontSize: '12px',
            fontWeight: 'normal',
            borderRadius: '4px',
            border: `1px solid ${palette.neutral.grey.deeper}`,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
            padding: theme.spacing(0.75, 1),
            ...(!!styleOverride && styleOverride),
        },
        tooltipPlacementBottom: {
            margin: '8px 0',
        },
    }))();

const useGetTitle = (text: string | JSX.Element, keyboardSequence?: string) => {
    return useMemo(() => {
        if (typeof text !== 'string' || !keyboardSequence) return text;

        return (
            <TooltipWithKeysContainer>
                <KeyboardSequenceText size="s" textType="white">
                    {text}
                </KeyboardSequenceText>
                <CoreKeyboardKeys sequence={keyboardSequence.split('+')} />
            </TooltipWithKeysContainer>
        );
    }, [text, keyboardSequence]);
};
export interface CoreTooltipProps extends Omit<TooltipProps, 'title' | 'placement'> {
    text: string | JSX.Element;
    position?: TooltipProps['placement'];
    disabled?: boolean;
    keyboardSequence?: string;
    styleOverride?: StyleOverride;
}

const NoTransition = (props: TransitionProps & {children: any}) => {
    return props.in ? props.children : null;
};

const ENTER_DELAY_MS = 1200;

const CoreTooltip = ({
    text,
    position,
    classes,
    styleOverride,
    keyboardSequence,
    disabled = false,
    ...rest
}: CoreTooltipProps) => {
    const defaultClasses = useClasses(styleOverride);
    const title = useGetTitle(text, keyboardSequence);
    return (
        <Tooltip
            title={title}
            placement={position}
            classes={{...defaultClasses, ...classes}}
            disableFocusListener={disabled}
            disableHoverListener={disabled}
            disableTouchListener={disabled}
            enterDelay={ENTER_DELAY_MS}
            enterNextDelay={ENTER_DELAY_MS}
            TransitionComponent={NoTransition}
            {...rest}
        />
    );
};

export default CoreTooltip;
