import logComposite from './logComposite';
import {LogFunc, LogData} from './types';
import safeParseError from './utils/safeParseError';

const doLog: LogFunc = async (level, message, data) => {
    if (data?.err) {
        data.err = await safeParseError(data.err);
    }

    return logComposite(level, message, data);
};

/**
 * the logger object used throughout the app. This is the default export of logging package
 * @memberof logging
 * @example
 * import logger from '@anywhere-expert/logging'
 *
 * logger.info("some meesage", { extra : {expertId: "123456"} })
 */

type Write = (message: string, data?: LogData) => void;

interface Logger {
    error: Write;
    warn: Write;
    debug: Write;
    info: Write;
}

const logger: Logger = {
    /** log an error message
     * @memberof logging.logger
     * @param {string} message the message to log
     * @param {object} data the metadata to add to the message
     */
    error(message, data) {
        doLog('error', message, data);
    },
    /** log a warning message
     * @memberof logging.logger
     * @param {string} message the message to log
     * @param {object} data the metadata to add to the message
     */
    warn(message, data) {
        doLog('warn', message, data);
    },
    /** log an info message
     * @memberof logging.logger
     * @param {string} message the message to log
     * @param {object} data the metadata to add to the message
     */
    info(message, data) {
        doLog('info', message, data);
    },
    /** log a debug message
     * @memberof logging.logger
     * @param {string} message the message to log
     * @param {object} data the metadata to add to the message
     */
    debug(message, data) {
        doLog('debug', message, data);
    },
};

export default logger;
