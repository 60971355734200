/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M13.123 6.605L12.088 2.74l-1.932.518 1.035 3.863 1.932-.517zM15.314 7.192l2.829-2.829 1.414 1.414-2.83 2.83-1.413-1.415zM3.293 19.212L13.9 8.606l1.414 1.414L4.708 20.626l-1.415-1.414zM17.314 10.798l-.518 1.931 3.865 1.036.518-1.932-3.865-1.035zM14.298 18.714l-1.036-3.865 1.932-.518 1.036 3.866-1.932.517zM5.723 7.691l3.865 1.036-.517 1.93-3.865-1.035.517-1.93z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
