import logger from '@anywhere-expert/logging';
import {homeUserManager, removeStoredTokens} from './homeUserManager';
import {userProfileCreator, User} from '../api';
import {
    getAccessToken,
    HomeTokens,
    AuthResult,
    getAuthResult,
    getUsernamePasswordToken,
    getAccessToken$,
} from '../shared';
import {User as OIDCUser} from 'oidc-client';

interface RedirectCallbackResult {
    tokens: HomeTokens;
    redirectTo?: string;
    queryParams?: any;
    userProfile: User;
}

export const redirectToAuthProvider = async (provider, originPath, queryParams) => {
    await homeUserManager.signinRedirect({acr_values: `idp:${provider}`, data: {originPath, queryParams}});
};

export const usernamePasswordLogin = async (username: string, password: string) => {
    const {access_token} = await getUsernamePasswordToken(
        'tech-app-web',
        'c0818058-2218-47d6-959b-751e154c281f',
        username,
        password
    );
    window.location.replace(`/auth_username_password?access_token=${access_token}`);
};

export const usernamePasswordLoginCallback = async () => {
    const queryParam = window.location.search && window.location.search.split('=');
    if (queryParam[0].includes('access_token')) {
        const userProfile = userProfileCreator(queryParam[1]);
        await homeUserManager.storeUser(
            new OIDCUser({
                access_token: queryParam[1],
                id_token: queryParam[1],
                refresh_token: queryParam[1],
                token_type: 'Bearer',
                session_state: '',
                state: '',
                profile: userProfile as any,
                scope: 'openid profile email expert-identity expert-resource home',
                expires_at: Date.now() + 60 * 1000,
            })
        );
        return {
            tokens: {
                accessToken: queryParam[1],
            },
            userProfile,
        };
    } else {
        logger.error('missing access_token on result of usernamePassword authentication');
        return null;
    }
};

export const getHomeAccessToken = () => getAccessToken();

export const getHomeAccessToken$ = () => getAccessToken$();

export const signinRedirectCallback = async (): Promise<RedirectCallbackResult> => {
    const {access_token, id_token, state} = await homeUserManager.signinRedirectCallback();
    return {
        tokens: {
            access_token,
            id_token,
        },
        redirectTo: state && state.originPath,
        queryParams: state && state.queryParams,
        userProfile: userProfileCreator(access_token, id_token, true),
    };
};

export const logout = async () => await removeStoredTokens();

export const tryLoginWithExistingTokens = async (): Promise<AuthResult> => {
    logger.debug('Try to login with existing tokens');
    const user = await homeUserManager.getUser();

    if (!user) {
        logger.debug('Empty user');
        return getAuthResult(false);
    }

    if (!user.expired) {
        logger.debug('Got valid user');
        return getAuthResult(true, user);
    }

    return reauthenticateSilently();
};

export const reauthenticateSilently = async () => {
    try {
        logger.debug('Reauthenticate silently');
        const reauthenticatedUser = await homeUserManager.signinSilent();

        logger.debug('Reauthenticate silently: success');
        return getAuthResult(true, {
            access_token: reauthenticatedUser.access_token,
            id_token: reauthenticatedUser.id_token,
        });
    } catch (err) {
        if (err.error === 'login_required') {
            logger.info('Failed to reauthenticate silently - login required', {err});
        } else {
            logger.warn('Failed to reauthenticate silently', {err});
        }

        await removeStoredTokens();
        return getAuthResult(false);
    }
};
