/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M2.3 3.312a1.099 1.099 0 000 1.507l3.167 3.306v7.643c0 1.12.872 2.03 1.945 2.03h7.324l4.686 4.89a.991.991 0 001.444 0 1.099 1.099 0 000-1.507L3.743 3.312a.991.991 0 00-1.444 0zM18.11 14.366v.925L8.843 5.62h7.322c1.073 0 1.945.91 1.945 2.03v1.402l2.483-1.296c.3-.156.66-.141.946.045.286.185.461.511.461.863v6.09c0 .352-.175.677-.461.863a.933.933 0 01-.946.044l-2.483-1.295z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
