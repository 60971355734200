import * as React from 'react';
import lottie from 'lottie-web';
import {css} from 'aphrodite';

type Props = {
    style?: object;
    animationOptions: object;
    onAnimationComplete?: () => void;
};

export default class extends React.Component<Props> {
    static displayName = 'Animation';

    container;
    animation;

    UNSAFE_componentWillReceiveProps({animationOptions}) {
        if (this.props.animationOptions !== animationOptions) {
            this.animation && this.animation.destroy();
            this.animation = lottie.loadAnimation({
                ...animationOptions,
                container: this.container,
            });
        }
    }

    componentDidMount() {
        const {onAnimationComplete, animationOptions} = this.props;
        this.animation = lottie.loadAnimation({
            ...animationOptions,
            container: this.container,
        });

        onAnimationComplete && this.animation.addEventListener('loopComplete', onAnimationComplete);
    }

    componentWillUnmount() {
        const {onAnimationComplete} = this.props;
        onAnimationComplete && this.animation.removeEventListener('loopComplete', onAnimationComplete);
        this.animation && this.animation.destroy();
        this.animation = null;
    }

    render() {
        const {onAnimationComplete, animationOptions, style, ...props} = this.props;
        return <div {...props} className={style && css(style)} ref={container => (this.container = container)} />;
    }
}
