import React from 'react';
import {observer} from 'mobx-react';
import {AnalyticsProvider} from 'react-shisell';
import ModalStore from './ModalStore';
import CoreModal from './CoreModal';

const ModalPlaceholder = observer(() => <>{Array.from(ModalStore.modals.keys()).map(renderModal)}</>);

export default ModalPlaceholder;

function renderModal(id: string) {
    return <Modal id={id} key={id} />;
}

const Modal = observer(({id}: {id: string}) => {
    const {Content, options, isOpen, close, name} = ModalStore.modals.get(id)!;
    const {disableClickOutside, contentProps, analyticsDispatcher, ...rest} = options;

    const modal = (
        <CoreModal
            key={id}
            open={isOpen}
            onClose={close}
            disableBackdropClick={disableClickOutside}
            disableEscapeKeyDown={disableClickOutside}
            showCloseButton={!disableClickOutside}
            name={name}
            {...rest}
        >
            <Content key={id} modalId={id} close={close} {...contentProps} />
        </CoreModal>
    );

    if (analyticsDispatcher) {
        return <AnalyticsProvider dispatcher={analyticsDispatcher}>{modal}</AnalyticsProvider>;
    }

    return modal;
});
