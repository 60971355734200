import React, {createContext, useContext} from 'react';
import {useObservable} from 'rxjs-hooks';
import {userProfile$} from '../api';

export const UserProfileContext = createContext(userProfile$.getValue());
UserProfileContext.displayName = 'UserProfile';

export const UserProfileProvider = ({children}) => {
    const userProfile = useObservable(() => userProfile$, userProfile$.getValue());

    return <UserProfileContext.Provider value={userProfile}>{children}</UserProfileContext.Provider>;
};

export const useUserProfile = () => useContext(UserProfileContext);
