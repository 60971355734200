import React, {FunctionComponent} from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getIconSize} from '@anywhere-expert/icons';
import {CoreIconButtonProps, GetVariantStyle} from './types';
import {getVariantStyle} from './utils';
import CoreTooltip from '../CoreTooltip';
import {getSize} from '../shared/icon';
import CoreIcon from '../CoreIcon';

const BaseButton = styled.button`
    position: relative;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
    user-select: none;
    background-color: transparent;

    &:disabled {
        cursor: default;
        pointer-events: none;
    }
`;

const TooltipChildrenContainer = styled.div`
    border-radius: 50%;
`;

interface ContainerProps extends GetVariantStyle {
    [key: string]: any;
}

const Container = styled(
    React.forwardRef<HTMLButtonElement, ContainerProps>(
        ({variant, color, colorOverride, size, ...rest}: ContainerProps, ref) => <BaseButton {...rest} ref={ref} />
    )
)`
    width: ${({size}) => size};
    height: ${({size}) => size};

    ${p => getVariantStyle(p as GetVariantStyle)};

    flex-shrink: 0;
`;

const CoreIconButton: FunctionComponent<CoreIconButtonProps> = React.forwardRef(
    (
        {
            variant = 'contained',
            color = 'default',
            size = 'm',
            disabled = false,
            icon,
            iconProps,
            className,
            style,
            onClick,
            isInProgress = false,
            colorOverride,
            isActive = false,
            tooltipProps,
            ...rest
        }: CoreIconButtonProps,
        ref
    ) => {
        const sizeInPx = variant === 'icon-fade' ? getIconSize(size) : getSize(size);

        const render = (
            <Container
                variant={variant}
                color={color}
                colorOverride={colorOverride}
                size={sizeInPx}
                className={className}
                disabled={disabled}
                style={style}
                onClick={onClick}
                data-is-active={isActive}
                ref={ref}
                {...rest}
            >
                {isInProgress ? (
                    <CircularProgress size={getIconSize(size)} color="inherit" />
                ) : (
                    <CoreIcon icon={icon} size={size} iconProps={iconProps} />
                )}
            </Container>
        );

        if (tooltipProps) {
            return (
                <CoreTooltip {...tooltipProps}>
                    <TooltipChildrenContainer>{render}</TooltipChildrenContainer>
                </CoreTooltip>
            );
        }

        return render;
    }
);

export default CoreIconButton;
