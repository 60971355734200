/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M20.822 3.179A4 4 0 0017.976 2a3.995 3.995 0 00-2.846 1.179l-9.983 9.982a.999.999 0 000 1.414l.104.104-1.733.347a1 1 0 00-.794.84l-.712 4.991a.997.997 0 001.131 1.13l4.991-.713a1 1 0 00.84-.793l.347-1.733.104.104a.997.997 0 001.414 0l9.983-9.982A4 4 0 0022 6.024a4.002 4.002 0 00-1.18-2.847l.002.002zm-4.284 1.42l.005-.006c.765-.764 2.1-.765 2.864 0 .764.764.764 2.099 0 2.864l-.006.005-2.863-2.863zM7.15 19.397l-2.972.423.424-2.971 2.348-.469.669.669-.47 2.348zm2.98-2.664L7.27 13.869l7.855-7.856 2.863 2.864-7.855 7.856z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
