import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.0857 4.66689C15.565 4.14619 14.7207 4.14619 14.2001 4.66689L11.4762 7.39072C10.9555 7.91142 10.9555 8.75564 11.4762 9.27634L11.9162 9.71629L12.859 8.77348L12.419 8.33353L15.1429 5.6097L18.3905 8.85734L15.6667 11.5812L15.2265 11.141L14.2837 12.0838L14.7239 12.524C15.2446 13.0447 16.0888 13.0447 16.6095 12.524L19.3333 9.80015C19.854 9.27945 19.854 8.43523 19.3333 7.91453L16.0857 4.66689Z"
                fill={color}
            />
            <path
                d="M9.71604 11.9164L9.2761 11.4765C8.7554 10.9558 7.91118 10.9558 7.39048 11.4765L4.66665 14.2003C4.14595 14.721 4.14595 15.5652 4.66665 16.0859L7.91429 19.3336C8.43498 19.8543 9.2792 19.8543 9.7999 19.3336L12.5237 16.6097C13.0444 16.089 13.0444 15.2448 12.5237 14.7241L12.0836 14.284L11.1408 15.2268L11.5809 15.6669L8.85709 18.3907L5.60946 15.1431L8.33329 12.4193L8.77323 12.8592L9.71604 11.9164Z"
                fill={color}
            />
            <rect
                x="14.0952"
                y="8.85693"
                width="1.48155"
                height="7.40774"
                transform="rotate(45 14.0952 8.85693)"
                fill={color}
            />
        </svg>
    </BaseIcon>
));

export default Icon;
