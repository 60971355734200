/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M21.788 9.322a.91.91 0 0 0-.697-.326H15V3.905a.908.908 0 0 0-.909-.909h-.757a.907.907 0 0 0-.834.55L10 8.996h-.3a.7.7 0 0 0-.7.7v7.237c0 .387.31.758.657.926.261.126.56.291.86.456.62.34 1.239.681 1.518.681l3.276.002L19.5 19c.44 0 .816-.314.894-.746l1.59-8.187a.906.906 0 0 0-.197-.745zM7 9.905a.908.908 0 0 0-.909-.909H2.908A.909.909 0 0 0 2 9.905v8.183c0 .501.406.908.908.908H6.09A.908.908 0 0 0 7 18.088V9.905z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
