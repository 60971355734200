import * as React from 'react';
import {compose} from 'recompose';
import {
    withErrorOverlayStatus,
    ErrorOverlayStatusProps,
    ErrorOverlayPublicProps,
} from '../behaviours/with-error-overlay-control';
import {useTheme} from '@anywhere-expert/colors';
import {InfoIcon} from '@anywhere-expert/icons';
import styled from 'styled-components';

const Overlay = styled.div`
    background: rgba(32, 40, 50, 0.8);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
`;

const Modal = styled.div`
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    line-height: 1.5;
    width: 400px;
    height: 180px;
    border-radius: 6px;
    background-color: ${({theme}) => theme.colors.neutral.white};
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
`;

const Plate = styled(({color, ...rest}: {color: string}) => <div {...rest} />)`
    background-color: ${({color}) => color};
    width: 100%;
    height: 7px;
    margin-bottom: 40px;
`;

const ChildrenContainer = styled.div`
    margin-top: ${({theme}) => theme.spacing(1)}px;
`;

const ErrorOverlay: React.SFC<ErrorOverlayStatusProps & ErrorOverlayPublicProps> = ({
    children,
    errorOverlayStatus,
    plateColor,
    containerStyle,
    errorIcon: ErrorIcon = InfoIcon,
}) => {
    const theme = useTheme();

    return errorOverlayStatus.overlayEnabled ? (
        <Overlay>
            <Modal style={containerStyle}>
                <Plate color={plateColor || theme.colors.brand.blue} />
                <ErrorIcon color={plateColor || theme.colors.brand.blue} size="l" />
                <ChildrenContainer>{children}</ChildrenContainer>
            </Modal>
        </Overlay>
    ) : null;
};

const enhance = compose<ErrorOverlayStatusProps & ErrorOverlayPublicProps, ErrorOverlayPublicProps>(
    withErrorOverlayStatus
);

export default enhance(ErrorOverlay);
