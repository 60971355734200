import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 6.66683V5.3335H11.3333V6.66683H13.6407L8.90067 17.3335H6V18.6668H12.6667V17.3335H10.3593L15.0993 6.66683H18Z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    </BaseIcon>
));

export default Icon;
