import {Theme} from '@anywhere-expert/colors';

export const getBoxShadowStyle = (theme: Theme) => ({
    boxShadow: theme.themeName === 'light' ? '0px 0px 14px rgba(0, 0, 0, 0.11)' : undefined,
});

export const getBorderStyle = (theme: Theme) => ({
    border: theme.themeName === 'dark' ? '1px solid rgba(255, 255, 255, 0.2)' : undefined,
});

export const getScrollerStyle = (theme: Theme) => `
  &::-webkit-scrollbar {
      width: 16px;
      display: inherit;
  }
  &::-webkit-scrollbar-thumb {
      background: ${theme.colors.contrast.border};
      border-radius: 10px;
      width: 4px;
      border: 6px solid transparent;
      background-clip: padding-box;
      min-height: 100px;
  }
  &::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.contrast.disabled};
      border-radius: 10px;
      width: 4px;
      border: 6px solid transparent;
      background-clip: padding-box;
  }
`;
