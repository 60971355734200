import * as React from 'react';
import styled from 'styled-components';
import {ExternalProps, CoreListItemProps} from './CoreListItem';
import CoreText from './CoreText';
import Children from 'react-children-utilities';
import {getScrollerStyle, getBoxShadowStyle, getBorderStyle} from './shared/styles';

const List = styled.ul`
    display: flex;
    outline: none;
    overflow: hidden;
    overflow-y: auto;
    margin: unset;
    position: relative;
    flex-direction: column;
    padding: unset;
    color: ${({theme}) => theme.colors.neutral.black};

    ${({theme}) => getScrollerStyle(theme)};
`;

export const Divider = styled.div<{hasIcons: boolean}>`
    display: flex;
    margin: 6px 0 6px ${({hasIcons}) => (hasIcons ? '42px' : '16px')};
    border-radius: 4px;
    background-color: ${({theme}) => theme.colors.neutral.white};
    border: ${({theme}) => `1px solid ${theme.colors.neutral.grey.light}`};
`;

const CoreListContainer = styled.div<{hasHeader: boolean; hasOutline: boolean}>`
    position: relative;
    border-radius: 4px;
    padding: ${({hasHeader}) => (hasHeader ? '0px 0px 6px' : '6px 0px')};
    z-index: 5;
    display: flex;
    flex-direction: column;
    ${({hasOutline, theme}) => hasOutline && getBoxShadowStyle(theme)};
    ${({hasOutline, theme}) => hasOutline && getBorderStyle(theme)};
    background-color: ${({theme}) => theme.colors.background.secondary};
`;

const Header = styled.div`
    display: flex;
    margin-left: 16px;
    align-items: center;
    height: 44px;
    z-index: 6;
`;

export type CoreListProps = {
    dividers?: boolean;
    hasIcons?: boolean;
    header?: string;
    children: React.ReactElement<ExternalProps>[] | React.ReactElement<ExternalProps> | any;
    style?: React.CSSProperties;
    hasOutline?: boolean;
    listStyle?: React.CSSProperties;
};

type After = CoreListProps;

type State = {
    activeIndex: number;
    listSize: number;
};

class CoreList extends React.Component<After, State> {
    // We put selectedIndex here instead of in the state because it caused an infinite loop when toggling dark mode
    // It re-renders because of the theme change, and then renders the children with the same selectedIndex - causing a loop
    selectedIndex: number;

    constructor(props) {
        super(props);
        this.selectedIndex = -1;
        this.state = {
            listSize: this.calculateListSize(),
            activeIndex: -1,
        };
    }

    calculateListSize = (list?) => {
        let size = 0;
        Children.deepForEach(list || this.props.children, (child: React.ReactElement<CoreListItemProps>) => {
            if (React.isValidElement(child) && child.props.itemType === 'CoreListItem' && !child.props.disabled) size++;
        });
        return size;
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    componentDidUpdate(_prevProps: After, prevState: State) {
        this.selectedIndex = -1;
        const prevActiveIndex = prevState.activeIndex;
        const newActiveIndex = this.state.activeIndex;

        if (prevActiveIndex !== newActiveIndex) return;

        const previousListSize = prevState.listSize;
        const newListSize = this.calculateListSize();

        if (previousListSize !== newListSize) {
            this.setState({
                activeIndex: -1,
                listSize: newListSize,
            });
        }
    }

    setActiveIndex = (index: number) => this.state.activeIndex !== index && this.setState({activeIndex: index});

    handleKeyPress = (event: KeyboardEvent) => {
        const {listSize, activeIndex} = this.state;
        const minReached = activeIndex <= 0;
        const maxReached = activeIndex >= listSize - 1;

        switch (event.key) {
            case 'ArrowDown':
                this.setActiveIndex(maxReached ? 0 : activeIndex + 1);
                break;
            case 'ArrowUp':
                this.setActiveIndex(minReached ? listSize - 1 : activeIndex - 1);
                break;
            case 'Enter':
                this.selectedIndex = activeIndex;
                this.forceUpdate();
                event.preventDefault();
                break;
        }
    };

    onItemHover = (index: number) => {
        this.setActiveIndex(index);
    };

    createListItemProps = (index: number) => {
        const {activeIndex} = this.state;

        const onMouseOver = () => this.onItemHover(index);

        return {
            activeIndex,
            selectedIndex: this.selectedIndex,
            index,
            onMouseOver,
        };
    };

    renderChildren = (hasDividers: boolean) => {
        const {children, hasIcons} = this.props;
        let nextIndex = 0;
        return Children.deepMap(children, (child: React.ReactElement<CoreListItemProps>) => {
            if (!React.isValidElement(child)) return child;
            if (!child.props.itemType || child.props.itemType !== 'CoreListItem') return child;

            const childProps = this.createListItemProps(nextIndex);

            if (!child.props.disabled) nextIndex++;
            if (!hasDividers) return React.cloneElement(child, childProps);
            return (
                <>
                    {nextIndex != 1 && <Divider hasIcons={!!hasIcons} />}
                    {React.cloneElement(child, childProps)}
                </>
            );
        });
    };

    render() {
        const {header, dividers = false, hasOutline = true, listStyle, ...others} = this.props;
        return (
            <CoreListContainer hasHeader={!!header} hasOutline={hasOutline} {...others}>
                {header && (
                    <Header>
                        <CoreText textType="primary" size="m" weight="bold">
                            {header}
                        </CoreText>
                    </Header>
                )}
                <List style={listStyle}>{this.renderChildren(dividers)}</List>
            </CoreListContainer>
        );
    }
}

export default CoreList as React.ComponentType<CoreListProps & React.HTMLProps<HTMLUListElement>>;
