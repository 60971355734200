/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 15.91V4h-2v11.91L7.41 12 6 13.41l6 6 6-6L16.59 12 13 15.91zM12 17z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
