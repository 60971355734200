import React, {useState, CSSProperties} from 'react';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import {UserIcon} from '@anywhere-expert/icons';
import {Theme, useTheme} from '@anywhere-expert/colors';

const AvatarContainer = styled.div<{size: number; borderVisible: boolean}>`
    & .MuiAvatar-root {
        flex-shrink: 0;
        width: ${({size}) => size}px;
        font-size: 14px;
        height: ${({size}) => size}px;
        ${({borderVisible, theme}) => borderVisible && `border: 1px solid ${theme.colors.neutral.white};`};
    }
`;

export type TechAvatarProps = {
    size: number;
    name: string;
    borderVisible?: boolean;
    imageUrl?: string;
    style?: CSSProperties;
    className?: string;
    tryNameInitialsImageFallback?: boolean;
};

const canExtractInitial = myVar => (typeof myVar === 'string' || myVar instanceof String) && myVar.length > 0;
const needImageFallback = (isFailedToRetrieveImg, imageUrl) => isFailedToRetrieveImg || !imageUrl;
const extractInitials = name => name.charAt(0);

const TechAvatar = ({
    size,
    imageUrl,
    name,
    style,
    borderVisible = true,
    className,
    tryNameInitialsImageFallback = true,
}: TechAvatarProps) => {
    const [isFailedToRetrieveImg, setFailedToRetrieveImg] = useState(false);
    const theme: Theme = useTheme();
    const imgProps = React.useMemo(
        () => ({
            onError: () => setFailedToRetrieveImg(true),
        }),
        [setFailedToRetrieveImg]
    );

    return (
        <AvatarContainer size={size} borderVisible={borderVisible}>
            <Avatar
                imgProps={imgProps}
                src={!isFailedToRetrieveImg ? imageUrl : ''}
                alt={name}
                title={name}
                style={style}
                className={className}
            >
                {needImageFallback(isFailedToRetrieveImg, imageUrl) ? (
                    canExtractInitial(name) && tryNameInitialsImageFallback ? (
                        extractInitials(name)
                    ) : (
                        <UserIcon color={theme.colors.neutral.white} />
                    )
                ) : (
                    ''
                )}
            </Avatar>
        </AvatarContainer>
    );
};

export default TechAvatar;
