import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3334 17.3335H6.66675V18.6668H17.3334V17.3335Z" fill={color} fillRule="evenodd" />
            <path
                d="M13.3334 5.3335V6.66683H14.6667V12.0002C14.6667 13.4708 13.4707 14.6668 12.0001 14.6668C10.5294 14.6668 9.33341 13.4708 9.33341 12.0002V6.66683H10.6667V5.3335H6.66675V6.66683H8.00008V12.0002C8.00008 14.2062 9.79408 16.0002 12.0001 16.0002C14.2061 16.0002 16.0001 14.2062 16.0001 12.0002V6.66683H17.3334V5.3335H13.3334Z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    </BaseIcon>
));

export default Icon;
