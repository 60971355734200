/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M24 20.825a4.731 4.731 0 00-.498-1.4L14.45 1.867C14.035.748 13.12.093 12.042 0c-1.08.093-1.993.747-2.409 1.868L.581 19.424c-.249.467-.415.934-.498 1.401 0 .187-.083.28-.083.467 0 .747.332 1.494.83 1.961A3.367 3.367 0 002.907 24h18.02c.748 0 1.495-.28 2.076-.747.499-.467.83-1.214.83-1.961.084-.187.084-.28.167-.467zM13.453 6.817l-.332 9.059h-2.076l-.332-9.059h2.74zm-.249 13.354c-.249.28-.664.467-1.08.467-.415 0-.83-.187-1.079-.467a1.78 1.78 0 010-2.334 1.434 1.434 0 012.16 0c.248.28.414.747.414 1.214 0 .373-.166.747-.415 1.027v.093z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
