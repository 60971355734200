/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 2h4v2.252c.734.189 1.427.48 2.064.856l1.593-1.593 2.828 2.828-1.593 1.593c.377.637.667 1.33.856 2.064H22v4h-2.252a7.954 7.954 0 0 1-.856 2.064l1.593 1.593-2.828 2.828-1.593-1.593a7.955 7.955 0 0 1-2.064.856V22h-4v-2.252a7.954 7.954 0 0 1-2.064-.856l-1.593 1.593-2.828-2.828 1.593-1.593A7.953 7.953 0 0 1 4.252 14H2v-4h2.252c.189-.734.48-1.427.856-2.064L3.515 6.343l2.828-2.828 1.593 1.593A7.952 7.952 0 0 1 10 4.252V2zm8 10a6 6 0 1 1-12 0 6 6 0 0 1 12 0z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
