/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path fillRule="evenodd" clipRule="evenodd" d="M13 3h-2v8H3v2h8v8h2v-8h8v-2h-8V3z" fill={color} />
    </BaseIcon>
));

export default Icon;
