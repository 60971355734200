/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {useTheme} from '@anywhere-expert/colors';

const Icon = () => {
    const {
        colors: {
            neutral: {black},
        },
    } = useTheme();

    return (
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.3322 20.3457C35.4365 28.3958 28.9952 35.0063 20.9452 35.1106C15.9718 35.1751 11.548 32.7413 8.86825 28.9732"
                stroke={black}
                strokeWidth="1.25014"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.99231 19.6673C4.88795 11.6172 11.3292 5.00671 19.3793 4.90234C24.4746 4.83629 28.9932 7.39263 31.6503 11.3195"
                stroke={black}
                strokeWidth="1.25014"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.9142 12.1226L26.9104 11.3698L32.1253 11.3022L31.3562 5.39728L32.9142 12.1226Z"
                stroke={black}
                strokeWidth="1.25014"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M7.9558 28.1673L14.2142 28.8814L9.21493 29.4752L9.82269 35.2012L7.9558 28.1673Z"
                stroke={black}
                strokeWidth="1.25014"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
