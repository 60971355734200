/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.999 20v-2H16v2H7.999zM16 16H7.999V8H16v8zm-.001-10h-8V4h8v2zm0-4h-8c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm-2.998 13h-2v-3h2v3zM12 11a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
