/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.41 9L12 14l4.59-5L18 10.41l-6 6-6-6L7.41 9z" fill={color} />
    </BaseIcon>
));

export default Icon;
