import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.6667 6.6665H9.33337V7.99984H18.6667V6.6665Z" fill={color} fillRule="evenodd" />
            <path d="M18.6667 11.3335H9.33337V12.6668H18.6667V11.3335Z" fill={color} fillRule="evenodd" />
            <path d="M18.6667 16H9.33337V17.3333H18.6667V16Z" fill={color} fillRule="evenodd" />
            <path
                d="M6.88 6.57918V8.97985H7.60933V5.69852C7.60933 5.55118 7.52 5.41785 7.384 5.36118C7.24733 5.30385 7.09133 5.33585 6.98733 5.44052L6 6.42785L6.51533 6.94318L6.88 6.57918Z"
                fill={color}
                fillRule="evenodd"
            />
            <path
                d="M7 10C6.44867 10 6 10.4487 6 11V11.3333H6.66667V11C6.66667 10.816 6.81667 10.6667 7 10.6667C7.184 10.6667 7.334 10.816 7.334 11C7.334 11.326 6.692 12.1693 6.098 12.764C6.00267 12.8593 5.974 13.002 6.026 13.1273C6.07733 13.2527 6.19867 13.3333 6.33333 13.3333H8V12.6667H7.092C7.50733 12.1773 8 11.492 8 11C8 10.4487 7.552 10 7 10Z"
                fill={color}
                fillRule="evenodd"
            />
            <path
                d="M7 14.9614L6 14.9621L6.00067 15.6288L7 15.6281C7.18333 15.6281 7.33333 15.7774 7.33333 15.9614C7.33333 16.1454 7.18333 16.2948 7 16.2948H6.66667V16.9614H7C7.18333 16.9614 7.33333 17.1108 7.33333 17.2948C7.33333 17.4794 7.18333 17.6281 7 17.6281H6L6.00067 18.2948H7C7.55133 18.2948 8 17.8461 8 17.2948C8 17.0388 7.904 16.8048 7.74467 16.6281C7.904 16.4514 8 16.2181 8 15.9614C8 15.4101 7.55133 14.9614 7 14.9614Z"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    </BaseIcon>
));

export default Icon;
