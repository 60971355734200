/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 3a1 1 0 1 0-2 0v1H9V3a1 1 0 0 0-2 0v1H5.3A2.3 2.3 0 0 0 3 6.3v12.4A2.3 2.3 0 0 0 5.3 21h13.4a2.3 2.3 0 0 0 2.3-2.3V6.3A2.3 2.3 0 0 0 18.7 4H17V3zM7 7V6H5.3a.3.3 0 0 0-.3.3V9h14V6.3a.3.3 0 0 0-.3-.3H17v1a1 1 0 1 1-2 0V6H9v1a1 1 0 0 1-2 0zm12 4H5v7.7a.3.3 0 0 0 .3.3h13.4a.3.3 0 0 0 .3-.3V11zm-8 4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-4a1 1 0 0 1-1-1zm-2 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
