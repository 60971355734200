/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M32.387 30.609c-4.64.248-7.584-7.167-8.45-10.652-.934-3.816-1.397-7.728-1.162-11.643.15-2.309.689-4.766 2.686-6.275 1.915-1.562 4.57-.58 6.099 1.044 3.908 3.974 5.282 12.26 5.527 15.456.4 4.615.007 11.808-4.7 12.07z"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <path
                d="M23.367 13.512c.041.2 2.929-.73 3.184-.851 2.698-1.168 4.142-.286 4.77 1.727.48 1.63 1.01 5.183-1.45 5.819-1.262.324-3.953.179-5.244.37"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <path
                d="M23.922 3.732c-1.826 3.34-4.41 5.727-8.104 6.82-3.224.998-6.885.565-9.918 2.146-4.669 2.464-4.366 8.963-1.284 12 1.758 1.715 3.693 1.255 5.952 1.005 2.326-.264 4.65-.872 7.003-1.004 5.124-.277 8.03 2.242 12.053 5.088"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <path
                d="M5.71 25.582c.945.5 3.312.435 3.312.435.932.778 1.719 4.555 4.187 11.03.108.186.15.385.312.49.96.91 1.471 1.014 2.574.929 1.506-.167 3.561-.72 3.189-2.51-.466-1.563-2.525-4.392-2.787-5.651-.166-.796 2.619-.876 2.207-2.519-.367-.754-.855-1.415-1.33-2.355"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <path
                d="M12.976 25.837c-.523.175-2.363.755-2.86 1.063-.103.85 6.17-.973 7.082-1.64"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <path
                d="M23.92 8.773c.684-.414 2.087 2.962 2.184 3.426-.498-.037-2.162.714-2.633.809.05-.77-.064-1.99-.148-2.732l.597-1.503zm1.934 15.011c-.354-.688.111-1.818-.121-2.6.55-.042 1.102-.085 1.707-.207.353.688.252 1.882.135 2.666-.078.637-.33 1.447-1.043 1.385l-.678-1.244z"
                fill={color}
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="2"
            />
            <path
                d="M10.75 11.439c-1.783 1.19-2.85 3.132-2.999 5.441-.15 2.31.62 5.33 2.229 7.008.622.633 1.392 1.306 2.254 1.408"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="1.5"
            />
            <path
                d="M30.505 2.056c3.953 2.17 5.711 9.272 6.426 12.373 1.417 6.136 1.105 16.075-4.007 16.074"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="2"
            />
        </BaseIcon>
    ))
);

export default Icon;
