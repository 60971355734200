export const isValidBugsnagErrorObject = obj => {
    if (obj && obj instanceof Error) return true;

    try {
        return obj && 'message' in obj && 'name' in obj;
    } catch (error) {
        return false;
    }
};

export const createBugsnagErrorObject = message => ({message, name: message});
