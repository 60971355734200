/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M11.268 1c.77-1.333 2.694-1.333 3.464 0l10.392 18c.77 1.333-.192 3-1.732 3H2.608c-1.54 0-2.502-1.667-1.732-3L11.268 1z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
