import React, {FunctionComponent} from 'react';
import styled from 'styled-components';
import {CoreIconProps, IconProps, GetVariantStyle} from './types';
import CoreTooltip from '../CoreTooltip';
import {getSize} from '../shared/icon';

const defaultIconProps: IconProps = {
    color: 'currentcolor',
};

const BaseIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 50%;
    color: 'currentcolor';
`;

const TooltipChildrenContainer = styled.div`
    border-radius: 50%;
`;

interface ContainerProps extends GetVariantStyle {
    [key: string]: any;
}

const Container = styled(
    React.forwardRef<HTMLDivElement, ContainerProps>(({variant, color, size, ...rest}: ContainerProps, ref) => (
        <BaseIcon {...rest} ref={ref} />
    ))
)`
    color: ${({color}) => color};

    ${({size, variant, color}) =>
        variant === 'bordered' &&
        `
        border: 2px solid ${color};
        width: ${size};
        height: ${size};
    `};
`;

const CoreIcon: FunctionComponent<CoreIconProps> = React.forwardRef(
    (
        {
            variant = 'icon',
            color = 'currentcolor',
            size = 'm',
            icon: Icon,
            iconProps,
            className,
            style,
            tooltipProps,
            ...rest
        }: CoreIconProps,
        ref
    ) => {
        const mergedIconProps = {...defaultIconProps, size, ...iconProps};
        const sizeInPx = getSize(size);

        const render = (
            <Container
                variant={variant}
                color={color}
                size={sizeInPx}
                className={className}
                style={style}
                ref={ref}
                {...rest}
            >
                {React.isValidElement(Icon) ? Icon : <Icon {...mergedIconProps} />}
            </Container>
        );

        if (tooltipProps) {
            return (
                <CoreTooltip {...tooltipProps}>
                    <TooltipChildrenContainer>{render}</TooltipChildrenContainer>
                </CoreTooltip>
            );
        }

        return render;
    }
);

export default CoreIcon;
