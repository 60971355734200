/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M1.879 2.879a3 3 0 0 0 0 4.242L6.12 2.88a3 3 0 0 0-4.242 0zM11 8v5.414l3.293 3.293 1.414-1.414L13 12.586V8h-2z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 13c0 5.523-4.477 10-10 10S2 18.523 2 13 6.477 3 12 3s10 4.477 10 10zm-2 0a8 8 0 1 1-16 0 8 8 0 0 1 16 0z"
            fill={color}
        />
        <path d="M22.121 2.879a3 3 0 0 0-4.242 0L22.12 7.12a3 3 0 0 0 0-4.242z" fill={color} />
    </BaseIcon>
));

export default Icon;
