import {BehaviorSubject} from 'rxjs';
import {Observable} from 'rxjs';

type ItemAction = () => Promise<void>;

export enum TrackedActionStates {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    FAILED = 'FAILED',
    COMPLETE = 'COMPLETE',
}

// type TrackedActionStatesType = $Keys<typeof TrackedActionStates>;

class TrackedAction {
    state: BehaviorSubject<TrackedActionStates> = new BehaviorSubject(TrackedActionStates.PENDING);

    setState(trackedActionState: TrackedActionStates) {
        this.state.next(trackedActionState);
    }

    getState(): TrackedActionStates {
        return this.state.value;
    }

    getState$(): Observable<TrackedActionStates> {
        return this.state.asObservable();
    }
}

const runTrackedAction = async (trackedAction: TrackedAction, action: ItemAction) => {
    if (trackedAction.getState() === TrackedActionStates.IN_PROGRESS) {
        return;
    }

    try {
        trackedAction.setState(TrackedActionStates.IN_PROGRESS);
        await action();
        trackedAction.setState(TrackedActionStates.COMPLETE);
    } catch (e) {
        trackedAction.setState(TrackedActionStates.FAILED);
        throw e;
    }
};

class ActionStateTracker {
    trackedActions: Map<string, TrackedAction> = new Map();

    /**
     * initiates an async flow, and tracks it's state using the provided id
     * @memberof utils.ActionStateTracker
     * @param {string} id the async flow identifier
     * @param {function} action the flow to track
     */
    async submit(id: string, action: ItemAction): Promise<void> {
        const trackedAction: TrackedAction = this._getOrCreateTrackedAction(id);

        return runTrackedAction(trackedAction, action);
    }

    _getOrCreateTrackedAction(queueItemId: string): TrackedAction {
        if (!this.trackedActions.has(queueItemId)) {
            this.trackedActions.set(queueItemId, new TrackedAction());
        }

        return this.trackedActions.get(queueItemId) as TrackedAction;
    }

    /**
     * get the current state of the flow with the provided id
     * @memberof utils.ActionStateTracker
     * @param {string} id the async flow identifier
     */
    getState$(id: string): Observable<TrackedActionStates> {
        return this._getOrCreateTrackedAction(id).getState$();
    }

    /**
     * stop tracking the async flow with the provided id
     * @memberof utils.ActionStateTracker
     * @param {string} id the async flow identifier
     */
    remove(id: string): void {
        if (this.trackedActions.has(id)) {
            this.trackedActions.delete(id);
        }
    }

    /**
     * sets the state of the async flow with the given id to 'PENDING'
     * @memberof utils.ActionStateTracker
     * @param {string} id the async flow identifier
     */
    reset(id: string): void {
        this._getOrCreateTrackedAction(id).setState(TrackedActionStates.PENDING);
    }
}

/**
 * A utility for tracking state changes of async flows using observable.
 * Any async flow can be in one of the following state: 'IN_PROGRESS', 'PENDING', 'COMPLETED', 'FAILED'.
 * This utility tracks the current state of flows, identified by some id.
 * @memberof utils
 * @name ActionStateTracker
 * @example
 * import {ActionStateTracker, combinePropFromObservable} from '@anywhere-expert/utils';
 * import {addAnExpert} from '@anywhere-expert/add-an-expert';
 * import {compose} from 'recompose';
 *
 *
 * const onClick = (id) => ActionStateTracker.submit(id, async () => {
 *      await addAnExpert(id);
 * })
 *
 * const AddAnExpertComponent = ({currentFlowStep, id}) => (
 *      {currentFlowStep === 'PENDING' ? <button onClick={()=>onClick(id)}>click here to add an expert</button> :
 *       currentFlowStep === 'IN_PROGRESS' ? <p>currently adding an expert</p> :
 *       currentFlowStep === 'COMPLETED'} ? <p>finished adding an expert </p> :
 *                                          <p>failed adding an expert</p>}
 *     )
 *
 *
 * export default compose(combinePropFromObservable({
 *       observableFn: (id) => ActionStateTracker.getState$(id),
 *       toProp: 'currentFlowStep',
 *       observeOnProps: ['id'],
 *   }))(AddAnExpertComponent);
 */
export default new ActionStateTracker();
