import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import {Size} from '../compoundTypes';

export type CompoundSpinnerProps = {
    size: Size;
    spinnerColor: string;
};

const SpinnerContainer = styled.div<CompoundSpinnerProps>`
    display: flex;
    justify-content: center;
    margin-top: 50%;
    color: ${p => p.spinnerColor};
`;

const Spinner = ({size, spinnerColor}: CompoundSpinnerProps) => {
    return (
        <SpinnerContainer size={size} spinnerColor={spinnerColor}>
            <CircularProgress variant="indeterminate" size={size === 's' ? 16 : 24} thickness={3} color="inherit" />
        </SpinnerContainer>
    );
};

export default Spinner;
