/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M17 6h-5v2h5c1.654 0 3 1.346 3 3s-1.346 3-3 3H6.414l2.293-2.293-1.414-1.414L2.586 15l4.707 4.707 1.414-1.414L6.414 16H17c2.757 0 5-2.243 5-5s-2.243-5-5-5z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
