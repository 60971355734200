/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.41 10.945L11 12.342l1.41 1.395c.369-.356.59-.851.59-1.395a1.93 1.93 0 0 0-.59-1.397zM15.233 7.972l-1.427 1.415c.751.738 1.194 1.717 1.194 2.787 0 1.069-.44 2.046-1.188 2.785l1.429 1.414A5.86 5.86 0 0 0 17 12.174c0-1.579-.653-3.094-1.767-4.202z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 12.001A9.832 9.832 0 0 0 18.06 5l-1.414 1.399A7.866 7.866 0 0 1 19 12.001a7.87 7.87 0 0 1-2.347 5.598L18.065 19A9.841 9.841 0 0 0 21 12.001z"
            fill={color}
        />
        <path
            d="M5 18v2h8.001v-1H15v1c0 1.103-.897 2-2 2H5c-1.103 0-2-.897-2-2V4c0-1.103.897-2 2-2h8c1.103 0 2 .897 2 2v1h-2V4H5v2h6v2H5v8h6v2H5z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
