/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path fillRule="evenodd" clipRule="evenodd" d="M15 7.41L10 12l5 4.59L13.59 18l-6-6 6-6L15 7.41z" fill={color} />
    </BaseIcon>
));

export default Icon;
