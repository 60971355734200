export type PixelCrop = {x: number; y: number; width: number; height: number};

export type Line = {points: {x: number; y: number}[]; brushRadius: number; brushColor: string};

export const getRadianAngle = degreeValue => {
    return (degreeValue * Math.PI) / 180;
};

const createImage = (url: string, isLocalImage?: boolean): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
        const isBase64 = url.includes('data:image');

        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.setAttribute('crossOrigin', '');
        image.src = isBase64 || isLocalImage ? url : `${url}?${new Date().getTime()}`;
    });

const createCanvas = async (
    imageSrc: string,
    pixelCrop?: PixelCrop,
    rotation?: number,
    lines?: Line[],
    drawDimensions?: {width: number; height: number},
    componentPropotionSizes?: any,
    isLocalImage?: boolean
) => {
    const image = await createImage(imageSrc, isLocalImage);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const safeArea = Math.max(image.width, image.height) * 2;
    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx?.translate(safeArea / 2, safeArea / 2);
    ctx?.rotate(getRadianAngle(rotation));
    ctx?.translate(-safeArea / 2, -safeArea / 2);

    /**
     * Paint the image in the middle of the canvas with a black margin from the edges inside the "safe area"
     *
     * canvas:
     * ____________________
     * |                  |
     * |   <-- width -->  |
     * |   _____________  |
     * |   |  Image..  |  |
     * |   |___________|  |
     * |                  |
     * |                  |
     * |__________________|
     *
     * <---- safe area --->
     */
    ctx?.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);

    const data = ctx?.getImageData(0, 0, safeArea, safeArea);

    const scaleX = componentPropotionSizes ? image.naturalWidth / componentPropotionSizes.width : 1;
    const scaleY = componentPropotionSizes ? image.naturalHeight / componentPropotionSizes.height : 1;

    canvas.width = (pixelCrop?.width ?? image.width) * scaleX;
    canvas.height = (pixelCrop?.height ?? image.height) * scaleY;

    const pointX = (pixelCrop?.x ?? image.x) * scaleX;
    const pointY = (pixelCrop?.y ?? image.y) * scaleY;

    /**
     * Paint the croped part of the image from the imageData returned from canvas
     * while fixing the black margin, from previous step, by start to paint from a fixed position
     * calculated by (0 - safeArea / 2 + image.width * 0.5 - pointX) for ecample
     *
     * Image:
     * ____________________
     * |                  |
     * |   <-- width -->  |
     * |   _____________  |
     * |   |  canvas.. |  |
     * |   |___________|  |
     * |                  |
     * |                  |
     * |__________________|
     *
     * <---- safe area --->
     */
    ctx?.putImageData(
        data!,
        0 - safeArea / 2 + image.width * 0.5 - pointX,
        0 - safeArea / 2 + image.height * 0.5 - pointY
    );

    if (ctx && lines?.length && drawDimensions) {
        const canvasProportionWidth = image.width / drawDimensions?.width;
        const canvasProportionHeight = image.height / drawDimensions?.height;
        lines.forEach(({points, brushColor, brushRadius}) => {
            ctx.beginPath();
            ctx.strokeStyle = brushColor;
            ctx.lineWidth = brushRadius * 2;
            points.forEach(({x, y}) => {
                const canvasX = x * canvasProportionWidth - pointX;
                const canvasY = y * canvasProportionHeight - pointY;
                ctx.lineTo(canvasX, canvasY);
            });
            ctx.stroke();
        });
    }

    return canvas;
};

export const imageToBlob = async (imageSrc: string) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;
    ctx?.drawImage(image, 0, 0);

    return new Promise(resolve => {
        canvas.toBlob(file => {
            resolve(file || undefined);
        }, 'image/jpeg');
    });
};

const canvasToBlob = async (
    imageSrc: string,
    pixelCrop?: PixelCrop,
    rotation?: number,
    lines?: Line[],
    drawDimensions?: {width: number; height: number},
    componentPropotionSizes?: any,
    isLocalImage?: boolean
): Promise<any> => {
    const canvas = await createCanvas(
        imageSrc,
        pixelCrop,
        rotation,
        lines,
        drawDimensions,
        componentPropotionSizes,
        isLocalImage
    );

    return new Promise(resolve => {
        canvas.toBlob(file => {
            resolve(file || undefined);
        }, 'image/jpeg');
    });
};

export default canvasToBlob;
