/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M20.7716 10.4599C20.5257 10.3083 20.2191 10.2949 19.9608 10.4233L16.9999 11.9041V11.1691C16.9999 10.2491 16.2524 9.50244 15.3333 9.50244H6.99992C6.08075 9.50244 5.33325 10.2491 5.33325 11.1691V16.1691C5.33325 17.0883 6.08075 17.8358 6.99992 17.8358H10.1849L6.01825 22.0024H8.37492L11.1666 19.2108L13.9583 22.0024H16.3149L12.1483 17.8358H15.3333C16.2524 17.8358 16.9999 17.0883 16.9999 16.1691V15.4341L19.9608 16.9149C20.0783 16.9733 20.2066 17.0024 20.3333 17.0024C20.4858 17.0024 20.6374 16.9608 20.7716 16.8783C21.0166 16.7258 21.1666 16.4583 21.1666 16.1691V11.1691C21.1666 10.8799 21.0166 10.6124 20.7716 10.4599Z"
            fill={color}
        />
        <path
            d="M7.83333 8.66911C9.67167 8.66911 11.1667 7.17411 11.1667 5.33577C11.1667 3.49827 9.67167 2.00244 7.83333 2.00244C5.995 2.00244 4.5 3.49827 4.5 5.33577C4.5 7.17327 5.995 8.66911 7.83333 8.66911Z"
            fill={color}
        />
        <path
            d="M14.5 8.66919C15.8783 8.66919 17 7.54752 17 6.16919C17 4.79086 15.8783 3.66919 14.5 3.66919C13.1217 3.66919 12 4.79086 12 6.16919C12 7.54752 13.1217 8.66919 14.5 8.66919Z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
