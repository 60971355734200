/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.003 18l-.001-2h1.999v2c0 1.103-.897 2-2 2h-5c-1.103 0-2-.897-2-2V6c0-1.103.897-2 2-2h5c1.103 0 2 .897 2 2v2h-2V6h-5v12h5.002zm5.998-14h5c1.103 0 2 .897 2 2v12c0 1.103-.897 2-2 2h-5c-1.103 0-2-.897-2-2v-2h2v2h5.001V6h-5v2h-2V6c0-1.103.896-2 2-2zM11 9h2v2h2v2h-2v2h-2v-2H9v-2h2V9z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
