/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.42 12.894L4.165 20.858c-.758.35-1.662.032-2.02-.709A1.457 1.457 0 0 1 2 19.516v-4.127c0-.998.76-1.84 1.772-1.963l7.63-.935a.497.497 0 0 0 .438-.553.5.5 0 0 0-.439-.429l-7.63-.935C2.76 10.45 2 9.61 2 8.611V4.484C2 3.664 2.68 3 3.517 3c.224 0 .445.048.648.142l17.255 7.963a.98.98 0 0 1 0 1.79z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
