import {useEffect, useRef} from 'react';

const useEventListener = (
    eventName,
    handler,
    element: Window | HTMLElement | null = window,
    shouldAddListener: boolean = true
) => {
    const savedHandler = useRef<any>(null);

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        if (!element) return;
        if (!shouldAddListener) return;

        const eventListener = event => savedHandler.current(event);

        element.addEventListener(eventName, eventListener);

        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element, shouldAddListener]);
};

export default useEventListener;
