/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M3 18h18v-2.721l-.684-.227A3.385 3.385 0 0118 11.838V9c0-3.308-2.691-6-6-6S6 5.692 6 9v2.838c0 1.46-.931 2.751-2.316 3.214L3 15.279V18zm5-6.162c0 1.648-.745 3.16-1.965 4.162h11.93A5.378 5.378 0 0116 11.838V9c0-2.205-1.794-4-4-4S8 6.795 8 9v2.838z"
                fill={color}
                fillRule="evenodd"
            />
            <path d="M12 21c-1.476 0-2.752-.81-3.445-2h6.89c-.693 1.19-1.968 2-3.445 2z" fill={color} />
        </BaseIcon>
    ))
);

export default Icon;
