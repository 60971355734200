/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path d="M10 12a2 2 0 1 1 4.001.001A2 2 0 0 1 10 12z" fill={color} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.581 11.944c.925 1.219 1.695 2.424 2.261 3.555 1.636 3.273 1.065 4.873.298 5.64-.344.345-.977.755-2.036.755-1.82 0-4.423-1.236-7.156-3.313-2.734 2.077-5.337 3.313-7.157 3.313-1.059 0-1.691-.41-2.036-.755-.767-.767-1.338-2.367.298-5.64.566-1.131 1.337-2.336 2.262-3.555-2.802-3.695-4.308-7.441-2.56-9.19C3.1 2.411 3.732 2 4.791 2c1.82 0 4.423 1.237 7.157 3.312C14.681 3.237 17.284 2 19.104 2c1.059 0 1.692.411 2.036.754 1.749 1.749.242 5.495-2.559 9.19zm.964 8.403c.207 0 .483-.029.622-.169.229-.229.383-1.22-.673-3.331-.452-.904-1.058-1.866-1.775-2.847A35.528 35.528 0 0 1 14 17.724c2.361 1.718 4.404 2.623 5.545 2.623zm-15.379-.169c.14.14.415.169.622.169 1.141 0 3.185-.905 5.545-2.623A35.518 35.518 0 0 1 6.615 14c-.718.981-1.324 1.943-1.776 2.847-1.055 2.111-.902 3.102-.673 3.331zM4.801 4c-.207 0-.482.03-.622.168-.53.533.07 2.936 2.443 6.182a35.996 35.996 0 0 1 3.724-3.727C7.986 4.905 5.942 4 4.801 4zm9.391 10.177a35.188 35.188 0 0 0 1.95-2.119 34.596 34.596 0 0 0-1.95-2.124A33.273 33.273 0 0 0 12.071 8 32.74 32.74 0 0 0 9.95 9.934 33.971 33.971 0 0 0 8 12.058a32.689 32.689 0 0 0 4.071 4.051 33.221 33.221 0 0 0 2.121-1.932zm5.975-10.009C20.028 4.03 19.752 4 19.545 4c-1.141 0-3.184.905-5.545 2.623a36.008 36.008 0 0 1 3.725 3.727c2.374-3.246 2.973-5.649 2.442-6.182z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
