import {CoreIconSize} from '../CoreIcon';

export function getSize(size: CoreIconSize) {
    switch (size) {
        case 'xxs':
            return '16px';
        case 'xs':
            return '26px';
        case 's':
            return '32px';
        case 'm':
            return '40px';
        case 'l':
            return '56px';
    }
}
