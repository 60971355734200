/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {useTheme} from '@anywhere-expert/colors';

const Icon = () => {
    const {
        colors: {
            neutral: {black},
        },
    } = useTheme();

    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.2799 23.3799C25.9059 24.3561 24.3065 25.914 23.4546 26.2672"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.2192 22.1094L24.7899 24.5099"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.8198 21.8076L25.3905 24.2081"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.85 22.5725L24.3109 25.1563"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="14.4531"
                cy="14.2188"
                r="10.9375"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                cx="14.6484"
                cy="14.375"
                rx="11.1328"
                ry="11.0938"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="14.4531"
                cy="14.2188"
                r="8.59375"
                stroke={black}
                strokeWidth="0.9375"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                cx="14.2578"
                cy="14.4922"
                rx="8.39844"
                ry="8.32031"
                stroke={black}
                strokeWidth="0.9375"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.40747 18.4829C7.82267 16.8117 7.56736 14.3191 8.54577 12.1498"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.49524 10.506C9.70549 10.2254 9.832 10.0621 10.1555 9.81592"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23.6677 26.8028C23.3263 26.4474 23.1438 25.957 23.3934 25.5321C23.7855 24.8648 24.5646 24.0509 25.3176 23.5191C25.8582 23.1373 26.5574 23.2906 27.0493 23.7334L36.5634 32.2968C36.697 32.417 36.8296 32.5546 36.8474 32.7334C36.9531 33.7949 34.8385 35.9186 33.5438 36.3932C33.1796 36.5267 32.8172 36.3272 32.5485 36.0474L23.6677 26.8028Z"
                stroke={black}
                strokeWidth="1.25"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
