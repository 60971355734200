import {userProfileCreator} from '../api';
import {HomeTokens, AuthResult} from './types';

export const getAuthResult = async (loggedIn: boolean, tokens?: HomeTokens): Promise<AuthResult> => {
    if (loggedIn) {
        const userProfile = userProfileCreator(tokens!.access_token, tokens!.id_token);
        return {tokens, userProfile, loggedIn};
    } else {
        return {loggedIn: false};
    }
};
