import * as React from 'react';
import styled from 'styled-components';

type TextSize = 's' | 'm';
type TextBubbleType = 'expert' | 'customer';

export type CoreTextBubbleProps = {
    textBubbleType?: TextBubbleType;
    size?: TextSize;
    [x: string]: any;
};

const CoreTextBubbleContainer = styled.div<any>`
    padding: 1em;
    border-radius: 4px;
    background-color: ${({textBubbleType, theme}) =>
        textBubbleType === 'expert' ? theme.colors.neutral.grey.deep : theme.colors.neutral.grey.light};
    max-width: ${({size}) => (size === 's' ? '228px' : '565px')};
    max-height: ${({size}) => (size === 's' ? '38px' : '1000px')};
    display: flex;
    text-align: left;
    line-height: 20px;
    margin: 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
`;

const CoreTextBubble = ({size = 's', children, textBubbleType = 'expert', ...others}: CoreTextBubbleProps) => {
    return (
        <CoreTextBubbleContainer textBubbleType={textBubbleType} size={size} {...others}>
            {children}
        </CoreTextBubbleContainer>
    );
};

export default CoreTextBubble;
