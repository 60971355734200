/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 15v1h16.002L20 4H4v5h2v2H4v4zM2 7v2H0v2h2v2h2v2H2v1c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V4c0-1.104-.897-2-2-2H4c-1.103 0-2 .896-2 2v1h2v2H2zm9.571 12c1.782 0 3.47.392 4.99 1.092A1 1 0 0 1 16.14 22h-9.14a1 1 0 0 1-.42-1.908A11.885 11.885 0 0 1 11.571 19z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
