import decodeJwt from 'jwt-decode';
import User from './User';

const extractToArray = (item?: string | string[]) => (item ? (Array.isArray(item) ? [...item] : [item]) : []);

// If we dont have idToken that means that the user used username + password login
export const userProfileCreator = (accessToken: string, idToken?: string, cameFromLogin?: boolean) => {
    const payload = decodeJwt(idToken || accessToken, {json: true});

    return new User({
        displayName: payload.name || '',
        email: payload.email || '',
        imageUrl: payload.picture,
        uid: payload.uid || payload.sub,
        queues: extractToArray(payload.queues),
        roles: extractToArray(payload.roles),
        expertise: extractToArray(payload.expertise),
        groups: extractToArray(payload.groups),
        isLoggedIn: true,
        loginRequired: false,
        cameFromLogin,
        accessToken,
    });
};
