/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.486 2 2 6.486 2 12v9a1 1 0 0 0 1 1h4c1.103 0 2-.897 2-2v-4c0-1.104-.897-2-2-2H4v-2c0-4.411 3.589-8 8-8s8 3.589 8 8v2h-3c-1.103 0-2 .896-2 2v4c0 1.103.897 2 2 2h4a1 1 0 0 0 1-1v-9c0-5.514-4.486-10-10-10zM7.001 20L7 16H4v4h3.001zM17 20h3v-4h-3v4zm-6.17-8.996L12.912 13 11.5 14.414 7 10.004 11.5 5.5l1.413 1.414-2.084 2.09h5.585v2H10.83z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
