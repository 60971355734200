/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 13.5v-.265a1 1 0 0 1 .702-.954L11 10.312V5a1 1 0 1 1 2 0v5.313l6.298 1.968a1 1 0 0 1 .702.954v.265l-7-.438v4.605l1.555 1.036a1 1 0 0 1 .445.832V20l-3-.5-3 .5v-.465a1 1 0 0 1 .445-.832L11 17.667v-4.605L4 13.5zm5 1.691l-4.875.305A2 2 0 0 1 2 13.5v-.265a3 3 0 0 1 2.105-2.863L9 8.842V5a3 3 0 1 1 6 0v3.842l4.895 1.53A3 3 0 0 1 22 13.235v.265a2 2 0 0 1-2.125 1.996L15 15.191v1.405l.664.443A3 3 0 0 1 17 19.535V20a2 2 0 0 1-2.329 1.973L12 21.528l-2.671.445A2 2 0 0 1 7 20v-.465a3 3 0 0 1 1.336-2.496L9 16.596v-1.405z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
