/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 10c-1.101 0-2-.899-2-2s.899-2 2-2c1.102 0 2 .899 2 2s-.898 2-2 2zm0 2c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0 1c4.253 0 7.346 2.01 7.908 5 .06.322.092.656.092 1v1H4v-1c0-.344.031-.678.092-1 .562-2.99 3.656-5 7.908-5zm4.567 3.275c.645.484 1.073 1.066 1.282 1.725H6.151c.21-.66.637-1.24 1.283-1.725C8.424 15.532 9.974 15 12 15s3.576.532 4.567 1.275z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
