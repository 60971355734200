/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {useTheme} from '@anywhere-expert/colors';

const Icon = () => {
    const {
        colors: {
            neutral: {black},
        },
    } = useTheme();

    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.366 41.835C19.0704 43.95 25.8337 38.6614 26.7812 37.1528C26.7812 37.1528 35.6323 37.7365 38.1628 36.0214C40.7171 34.2903 41.1909 31.5542 41.7355 28.7305C42.7878 19.584 42.5494 11.1941 39.0625 9.02785C33.6575 5.67047 14.1886 4.59041 7.9374 8.46544C4.26655 10.7505 4.91777 28.2344 6.58038 32.3534C7.2061 33.9138 7.70663 34.5202 8.92826 35.097C10.4005 35.7911 15.2411 36.0735 16.8974 36.8325C17.1116 36.9307 17.2725 37.0368 17.366 37.1528C17.3958 37.1824 16.7999 41.1325 17.366 41.835Z"
                stroke={black}
                strokeWidth="1.5"
                strokeMiterlimit="9.4426"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g clipPath="url(#clip0_831:65364)">
                <path
                    d="M10.8881 22.928C10.8155 22.9314 10.7443 22.9073 10.689 22.86C10.6417 22.8032 10.6198 22.7294 10.6282 22.656V15.72C10.6198 15.6466 10.6417 15.5728 10.689 15.516C10.745 15.4732 10.8145 15.4528 10.8847 15.4582H11.634C11.7757 15.4439 11.9033 15.5442 11.9242 15.686L11.9985 15.958C12.2141 15.7482 12.469 15.583 12.7477 15.4718C13.0329 15.3555 13.3377 15.2953 13.6455 15.295C14.2591 15.2746 14.8456 15.5497 15.225 16.0362C15.6428 16.6162 15.8504 17.3231 15.8122 18.0388C15.8237 18.5583 15.7248 19.0741 15.522 19.5518C15.3546 19.9496 15.0826 20.294 14.7356 20.548C14.4001 20.7785 14.0009 20.8975 13.5949 20.888C13.3191 20.8894 13.0451 20.8435 12.7849 20.752C12.5456 20.6711 12.3245 20.5439 12.1335 20.378V22.6628C12.1409 22.7356 12.1207 22.8087 12.0761 22.8668C12.0184 22.9117 11.9458 22.9321 11.8736 22.9246L10.8881 22.928ZM13.1595 19.7456C13.4929 19.7697 13.8156 19.6195 14.0134 19.3478C14.2236 18.9677 14.3188 18.5338 14.2867 18.1C14.3191 17.6621 14.2253 17.2242 14.0167 16.8386C13.8183 16.5642 13.4923 16.4136 13.1561 16.4408C12.7994 16.4411 12.4497 16.5384 12.1436 16.723V19.46C12.4474 19.6504 12.7984 19.7504 13.1561 19.749L13.1595 19.7456Z"
                    fill="#00A8E1"
                />
                <path
                    d="M17.0947 20.7791C16.9739 20.8019 16.8575 20.7217 16.8349 20.5996C16.8298 20.5724 16.8298 20.5445 16.8349 20.5173V15.7199C16.8264 15.6465 16.8484 15.5727 16.8956 15.5159C16.9516 15.4731 17.0212 15.4527 17.0914 15.4581H17.8372C17.979 15.4438 18.1066 15.5441 18.1275 15.6859L18.2625 16.2435C18.4842 15.9821 18.7492 15.7611 19.0455 15.5907C19.2871 15.4632 19.5558 15.3969 19.8285 15.3969H19.9736C20.0468 15.3905 20.1201 15.4109 20.1795 15.4547C20.2267 15.5115 20.2487 15.5853 20.2402 15.6587V16.5359C20.246 16.6066 20.2257 16.6767 20.1829 16.7331C20.1265 16.7807 20.0533 16.8028 19.9804 16.7943H19.7947C19.7181 16.7943 19.6216 16.7943 19.5045 16.7943C19.3094 16.7984 19.115 16.8212 18.924 16.8623C18.7249 16.8994 18.5305 16.9575 18.3435 17.0357V20.5207C18.3489 20.5914 18.3286 20.6615 18.2861 20.7179C18.2297 20.7655 18.1565 20.7876 18.0836 20.7791H17.0947Z"
                    fill="#00A8E1"
                />
                <path
                    d="M21.7354 14.6285C21.5052 14.6401 21.2794 14.5622 21.1043 14.4109C20.9416 14.2576 20.8542 14.04 20.8647 13.8159C20.8532 13.5905 20.9406 13.3716 21.1043 13.2175C21.4742 12.9194 22 12.9194 22.3699 13.2175C22.5326 13.3709 22.62 13.5885 22.6095 13.8125C22.62 14.0366 22.5326 14.2542 22.3699 14.4075C22.1944 14.5609 21.9673 14.6401 21.7354 14.6285ZM21.2393 20.7825C21.1185 20.8053 21.002 20.7251 20.9794 20.603C20.9743 20.5758 20.9743 20.5479 20.9794 20.5207V15.7199C20.971 15.6465 20.9929 15.5727 21.0402 15.5159C21.0962 15.4731 21.1657 15.4527 21.2359 15.4581H22.2315C22.3038 15.4507 22.3763 15.4711 22.434 15.5159C22.4786 15.5741 22.4988 15.6472 22.4914 15.7199V20.5207C22.4971 20.5915 22.4769 20.6615 22.434 20.7179C22.3777 20.7655 22.3044 20.7876 22.2315 20.7791L21.2393 20.7825Z"
                    fill="#00A8E1"
                />
                <path
                    d="M24.0338 20.7791C23.913 20.8019 23.7966 20.7217 23.7739 20.5996C23.7689 20.5724 23.7689 20.5445 23.7739 20.5173V15.7199C23.7655 15.6465 23.7874 15.5727 23.8347 15.5159C23.8907 15.4731 23.9602 15.4527 24.0304 15.4581H24.7763C24.9181 15.4439 25.0456 15.5442 25.0666 15.6859L25.1509 15.9681C25.4581 15.755 25.7902 15.5816 26.1398 15.4513C26.4284 15.35 26.7318 15.2973 27.0376 15.2949C27.5695 15.249 28.0804 15.5132 28.3538 15.9749C28.662 15.7614 28.9968 15.5901 29.3494 15.4649C29.6606 15.3606 29.9866 15.3079 30.3147 15.3085C30.7291 15.2834 31.1355 15.4309 31.4386 15.7165C31.718 16.0253 31.8618 16.435 31.8368 16.8521V20.5207C31.8422 20.5915 31.822 20.6615 31.7794 20.7179C31.7231 20.7655 31.6498 20.7876 31.5769 20.7791H30.5948C30.474 20.8019 30.3576 20.7217 30.3349 20.5996C30.3299 20.5724 30.3299 20.5445 30.3349 20.5173V17.1717C30.3349 16.6981 30.1247 16.4611 29.7038 16.4611C29.3116 16.4656 28.9252 16.5587 28.5732 16.7331V20.5207C28.5789 20.5915 28.5587 20.6615 28.5158 20.7179C28.4595 20.7655 28.3862 20.7876 28.3133 20.7791H27.3211C27.2002 20.8019 27.0838 20.7217 27.0612 20.5996C27.0561 20.5724 27.0561 20.5445 27.0612 20.5173V17.1717C27.0612 16.6981 26.8509 16.4611 26.4301 16.4611C26.0332 16.4635 25.6423 16.5604 25.2893 16.7433V20.5173C25.2947 20.5881 25.2745 20.6581 25.2319 20.7145C25.1756 20.7621 25.1023 20.7842 25.0294 20.7757L24.0338 20.7791Z"
                    fill="#00A8E1"
                />
                <path
                    d="M35.448 20.9357C34.7207 20.9836 34.0062 20.7266 33.4736 20.2251C32.9866 19.6634 32.7433 18.9286 32.7986 18.1851C32.75 17.4143 32.9964 16.6537 33.4871 16.0601C33.9984 15.5283 34.7143 15.2464 35.448 15.2883C35.9907 15.2556 36.5267 15.4226 36.9566 15.7575C37.3205 16.0703 37.5209 16.5344 37.5 17.0155C37.5246 17.4864 37.3016 17.9358 36.9128 18.1987C36.3893 18.4999 35.7896 18.6393 35.1881 18.5999C34.8473 18.6039 34.5071 18.5652 34.1756 18.4843C34.1777 18.8603 34.3282 19.22 34.5941 19.4839C34.909 19.7089 35.2924 19.816 35.6775 19.7865C35.8658 19.7865 36.0542 19.7739 36.2411 19.7491C36.4983 19.7089 36.7528 19.6542 37.0039 19.5859H37.0646H37.1153C37.2324 19.5859 37.2908 19.6664 37.2908 19.8273V20.3067C37.2972 20.3879 37.281 20.4692 37.2435 20.5413C37.1956 20.598 37.1321 20.6392 37.0613 20.6603C36.5449 20.8517 35.9981 20.9452 35.448 20.9357ZM35.1105 17.6615C35.3761 17.6808 35.6417 17.6244 35.8766 17.4983C36.038 17.3871 36.1291 17.1984 36.1163 17.0019C36.1163 16.5643 35.8574 16.3457 35.34 16.3457C34.6761 16.3457 34.2927 16.756 34.1891 17.5765C34.4905 17.6346 34.797 17.6632 35.1038 17.6615H35.1105Z"
                    fill="#00A8E1"
                />
                <path
                    d="M34.9046 26.6372C31.9481 28.8336 27.6619 29.9998 23.973 29.9998C19.0435 30.0264 14.2814 28.1995 10.6181 24.876C10.3414 24.6244 10.5878 24.281 10.9219 24.4748C14.9915 26.8157 19.5973 28.0452 24.2835 28.0414C27.7803 28.0227 31.2394 27.3098 34.4625 25.9436C34.9755 25.7294 35.394 26.2598 34.9046 26.6372Z"
                    fill="#00A8E1"
                />
                <path
                    d="M36.15 25.24C35.772 24.7538 33.6525 25.0088 32.6974 25.1244C32.4071 25.1584 32.3599 24.9068 32.6231 24.7232C34.3106 23.5264 37.0883 23.8732 37.4089 24.2744C37.7295 24.6756 37.3245 27.4738 35.7214 28.81C35.4784 29.014 35.2455 28.9052 35.3535 28.6332C35.7146 27.7186 36.5145 25.7126 36.15 25.24Z"
                    fill="#00A8E1"
                />
            </g>
            <defs>
                <clipPath id="clip0_831:65364">
                    <rect width="27" height="17" fill={black} transform="translate(10.5 13)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Icon;
