import {mapPropsStreamWithConfig} from 'recompose';
import rxjsconfig from 'recompose/rxjsObservableConfig';
import {Observable} from 'rxjs';
import {equals} from 'ramda';
import arrayFromProps from './utils/arrayFromProps';
import {CombinePropFromObservableParams} from './types';

// use this if you need a prop from an observable based on props values changing
export const combinePropFromObservable = ({observableFn, toProp, observeOnProps}: CombinePropFromObservableParams) => {
    return mapPropsStreamWithConfig(rxjsconfig)(props$ => {
        const observableFromProps$ = (props$ as Observable<{}>)
            .map(props => arrayFromProps(props, observeOnProps))
            .distinctUntilChanged(equals)
            .switchMap(changedProps => observableFn()(...changedProps));

        return Observable.combineLatest(props$, observableFromProps$, (props: any, observableFromProps) => ({
            ...props,
            [toProp]: observableFromProps,
        }));
    });
};
