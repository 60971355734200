/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M16.527 4.604L19 2h-7v7.37l3.102-3.266C17.408 7.25 19 9.625 19 12.37c0 3.52-2.613 6.433-6 6.92v2.02c4.493-.5 8-4.316 8-8.94 0-3.311-1.803-6.203-4.473-7.766zM3.032 13h2a6.957 6.957 0 001.369 3.554L4.975 17.98A8.945 8.945 0 013.032 13zM11 21.31a8.942 8.942 0 01-4.611-1.915l1.426-1.426A6.975 6.975 0 0011 19.29v2.02zM5.25 6.417l1.5 1.323A6.988 6.988 0 005.14 11H3.111A8.983 8.983 0 015.25 6.417z"
                fill="#000"
                fillOpacity=".6"
            />
        </BaseIcon>
    ))
);

export default Icon;
