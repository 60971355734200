/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M20 5.596V6h-2l3 4 3-4h-2v-.404C22 2.933 19.897 1 17 1h-1v2h1c1.445 0 3 .812 3 2.596zM4 18.404V18h2l-3-4-3 4h2v.404C2 21.067 4.103 23 7 23h1v-2H7c-1.445 0-3-.812-3-2.596zM15.314 20.971L4 9.658 9.657 4 20.97 15.315l-5.656 5.656zM11.07 2.567c-.756-.756-2.072-.756-2.828 0L2.585 8.225a2.001 2.001 0 000 2.828l11.314 11.313c.377.378.879.586 1.414.586.534 0 1.036-.208 1.414-.586l5.657-5.657a2.002 2.002 0 00-.001-2.828L11.07 2.567z"
                fill={color}
                fillRule="evenodd"
            />
        </BaseIcon>
    ))
);

export default Icon;
