/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M14.243 20.901c-.764 1.36-2.722 1.36-3.486 0L.677 2.981C-.073 1.647.89 0 2.42 0h20.16c1.53 0 2.493 1.647 1.744 2.98l-10.08 17.921z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
