import {default as Color} from 'color';

/**
 * Updates the color of a lottie animation json
 * @memberof utils
 * @param {Object} json the lottie animation json object
 * @param {string} name the name of the color to update. Use null if you want to change all the colors
 * @param {string} color the wanted color
 * @example
 *
 * import animation from './images/animation.json';
 *
 * const blueAnimation = updateLottieColor(animation, null, '#00aeef');
 */
const updateLottieColor = (json: Object, name: String | null, color: String) => {
    const obj = JSON.parse(JSON.stringify(json));
    const colorObj = Color(color);

    return findAndUpdateBrandedColor(obj, name, colorObj);
};

const findAndUpdateBrandedColor = (json, name, color) => {
    if (!json || typeof json === 'boolean' || typeof json === 'string' || typeof json === 'number') {
        return;
    }

    const keys = Object.keys(json);

    if (keys.length == 0) {
        return;
    }

    keys.forEach(key => {
        let didReplace = false;
        if (key === 'ty' && json[key] === 'fl') {
            didReplace = replaceColorIfNeeded(json, name, color);
        } else if (key === 'ty' && json[key] === 'st') {
            didReplace = replaceColorIfNeeded(json, name, color);
        }

        if (!didReplace) findAndUpdateBrandedColor(json[key], name, color);
    });

    return json;
};

const replaceColorIfNeeded = (json, name, color) => {
    let rgb = color.rgb();
    if (json.nm && (json.nm === name || !name)) {
        if (json.c && json.c.k) {
            json.c.k = [rgb.color[0] / 255, rgb.color[1] / 255, rgb.color[2] / 255, color.valpha];

            return true;
        }
    }

    return false;
};

export default updateLottieColor;
