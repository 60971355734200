/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M9 3.282A15.88 15.88 0 0 0 .7 7.688l1.414 1.414A13.91 13.91 0 0 1 9 5.324V3.282zM9 7.39a11.93 11.93 0 0 0-5.471 3.126l1.414 1.413A9.943 9.943 0 0 1 9 9.467V7.39zM15 9.459V7.382a11.926 11.926 0 0 1 5.499 3.134l-1.414 1.414A9.94 9.94 0 0 0 15 9.46zM9 11.587a7.97 7.97 0 0 0-2.642 1.758l1.414 1.414c.372-.372.785-.69 1.228-.948v-2.224zM15 13.795v-2.22a7.976 7.976 0 0 1 2.67 1.77l-1.414 1.414A6.032 6.032 0 0 0 15 13.795zM15 5.318V3.277a15.888 15.888 0 0 1 8.328 4.411l-1.414 1.414A13.91 13.91 0 0 0 15 5.318zM13 4h-2v11h2V4zM12 21a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
