/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18zm0 3c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z"
                fill={color}
            />
            <path d="M18 12a6 6 0 1 1-12 0 6 6 0 0 1 12 0z" fill={color} />
        </BaseIcon>
    ))
);

export default Icon;
