import {CSSProperties, ComponentType} from 'react';
import {withProps, compose} from 'recompose';
import {BehaviorSubject} from 'rxjs';
import {withPropFromObservable} from '@anywhere-expert/utils';
import {IconProps} from '@anywhere-expert/icons';

const enabledSubject = new BehaviorSubject({overlayEnabled: true});

export const disableErrorOverlay = () => enabledSubject.next({overlayEnabled: false});
export const enableErrorOverlay = () => enabledSubject.next({overlayEnabled: true});

export type ControlErrorOverlayProps = {
    disableErrorOverlay: () => void;
    enableErrorOverlay: () => void;
};

export type ErrorOverlayStatus = {
    overlayEnabled: boolean;
};

export type ErrorOverlayStatusProps = {
    errorOverlayStatus: ErrorOverlayStatus;
};

export type ErrorOverlayPublicProps = {
    plateColor?: string;
    containerStyle?: CSSProperties;
    errorIcon?: ComponentType<IconProps>;
};

export const withControlErrorOverlay = withProps<ControlErrorOverlayProps, any>(() => ({
    disableErrorOverlay,
    enableErrorOverlay,
}));

export const withErrorOverlayStatus = compose<{}, ErrorOverlayPublicProps>(
    withPropFromObservable<ErrorOverlayStatusProps, {}, ErrorOverlayStatus>({
        fromObservable$: enabledSubject,
        toProp: 'errorOverlayStatus',
    })
);
