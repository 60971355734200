/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path d="M18.586 4H15V2h7v7h-2V5.414l-9.293 9.293-1.414-1.414L18.586 4z" fill={color} />
        <path
            d="M19 19c0 1.103-.897 2-2 2H5c-1.103 0-2-.897-2-2V7c0-1.103.897-2 2-2h7v2H5v12h12v-7h2v7z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
