/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 12h-2V4h2v8zm-5-1a1 1 0 0 0-.919.605L11.341 18h-1.34v-4a1 1 0 0 0-1-1H3.197l1.636-9h6.974L16 5.677V11h-1zm6-9h-4a1 1 0 0 0-1 1v.522l-3.628-1.451A1.012 1.012 0 0 0 12 2H4c-.483 0-.897.346-.984.821l-2 11A1.001 1.001 0 0 0 2 15h6v4a1 1 0 0 0 1 1h3c.4 0 .762-.238.92-.606L15.66 13H16a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
