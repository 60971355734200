/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path d="M15 3v2h6v2H3V5h6V3h6zM9 18h2v-6H9v6zM15 18h-2v-6h2v6z" fill={color} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 22c-1.103 0-2-.897-2-2V8h14v12c0 1.103-.897 2-2 2H7zm10-12H7v10h10.001L17 10z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
