/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M13 1h6c1.103 0 2 .898 2 2v18c0 1.103-.897 2-2 2h-6c-1.103 0-2-.897-2-2V3c0-1.102.897-2 2-2zm0 17v3h6.001L19 3h-6v3h3v2h-3v3h3v2h-3v3h3v2h-3zM3 7c0-1.654 1.346-3 3-3s3 1.346 3 3v11.303l-3 4.5-3-4.5V7zm3 12.197l1-1.5V10H5v7.697l1 1.5zM5 7v1h2V7a1.001 1.001 0 00-2 0z"
                fill={color}
                fillRule="evenodd"
            />
        </BaseIcon>
    ))
);

export default Icon;
