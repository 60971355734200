/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 20v-1.062A8.001 8.001 0 0 1 4 11a1 1 0 1 1 2 0 6 6 0 0 0 12 0 1 1 0 1 1 2 0 8.001 8.001 0 0 1-7 7.938V20h2a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2h2zm1-16a2 2 0 0 0-2 2v5a2 2 0 1 0 4 0V6a2 2 0 0 0-2-2zm0-2a4 4 0 0 1 4 4v5a4 4 0 0 1-8 0V6a4 4 0 0 1 4-4z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
