import * as React from 'react';
import {StyleSheet, css} from 'aphrodite';
import logger from '../logger';
import {monitor} from '@anywhere-expert/monitor';
import {fullstory} from '@anywhere-expert/fullstory';
import {serializeError} from 'serialize-error';

type Props = {
    extraData?: any;
    boundaryName: string;
    onError?: (error: Error) => void;
    children: any;
};

class ProductionLoggingErrorBoundary extends React.Component<Props> {
    state = {hasError: false};

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const extra = {
            ...this.props.extraData,
            fullstoryUrl: fullstory.isInitialized() && fullstory.getCurrentSessionURL(),
            boundaryName: this.props.boundaryName,
            componentStack: errorInfo.componentStack,
        };

        const isChunkIssue = error?.name === 'ChunkLoadError';
        if (isChunkIssue) {
            logger.warn('ChunkLoad error caught in boundary', {
                err: error,
                extra,
            });
        } else {
            if (monitor.initializeTimestamp) {
                monitor.increment(`${this.props.boundaryName}.Crash`);
            }
            logger.error('Error caught in error boundary', {
                err: error,
                extra,
            });

            this.props.onError?.(error);
        }
    }

    render() {
        if (this.state.hasError) {
            return null;
        }

        return this.props.children;
    }
}

const styles = StyleSheet.create({
    overlay: {
        width: '100wh',
        height: '100vh',
        position: 'absolute',
        top: '0px',
        left: '0px',
        bottom: '0px',
        right: '0px',
        padding: '2em',
        zIndex: 9999,
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        color: 'white',
        lineHeight: '1.5em',
    },
    stackLine: {
        paddingLeft: '1em',
    },
});

interface DevelopmentLoggingErrorBoundaryState {
    didCatchError: boolean;
    error: Error | null;
    errorInfo: React.ErrorInfo | null;
}

class DevelopmentLoggingErrorBoundary extends React.Component<Props> {
    state: DevelopmentLoggingErrorBoundaryState = {
        didCatchError: false,
        error: null,
        errorInfo: null,
    };

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logger.error('Error caught in error boundary', {
            err: error,
            extra: {
                componentStack: errorInfo.componentStack,
                boundaryName: this.props.boundaryName,
            },
        });

        this.setState({
            didCatchError: true,
            error,
            errorInfo,
        });

        this.props.onError?.(error);
    }

    render() {
        if (!this.state.didCatchError) {
            return this.props.children;
        }

        return (
            <div className={css(styles.overlay)}>
                <h2>Error caught in error boundary.</h2>
                <br />
                <span>Error: {JSON.stringify(serializeError(this.state.error))}</span>
                <br />
                <span>
                    Component stack trace:
                    {this.state.errorInfo!.componentStack.split('\n').map((stack, i) => (
                        <span key={i} className={css(styles.stackLine)}>
                            {stack}
                            <br />
                        </span>
                    ))}
                </span>
            </div>
        );
    }
}

export const LoggingErrorBoundary = (props: Props) =>
    process.env.IS_BEHAVIOUR_TEST === 'true' || process.env.NODE_ENV !== 'production' ? (
        <DevelopmentLoggingErrorBoundary {...props} />
    ) : (
        <ProductionLoggingErrorBoundary {...props} />
    );
