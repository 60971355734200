/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.011 9L8.85 6.17 8.764 6H4v13h16V9h-8.989zM12 7h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a1 1 0 0 1 1-1h5.764a2 2 0 0 1 1.789 1.106L12 7z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
