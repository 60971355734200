import * as React from 'react';
import styled from 'styled-components';
import {TextSize, getTextSize} from './CoreText';

type LinkType = 'internal' | 'external';

export type CoreLinkProps = {
    linkType?: LinkType;
    size?: TextSize;
    disabled?: boolean;
    className?: any;
    fontStyle?: 'normal' | 'italic' | 'oblique' | 'initial' | 'inherit';
    [x: string]: any;
};

const CoreLink = styled.a<any>`
    font-size: ${({textSize}) => textSize};
    font-family: Roboto;
    cursor: pointer;
    font-style: ${({fontStyle}) => (fontStyle ? fontStyle : 'italic')};
    font-weight: normal;
    text-decoration: underline;
    display: inline;
    color: ${({theme}) => theme.colors.neutral.grey.deepest};
    &:hover {
        text-decoration: underline;
        font-weight: 500;
        color: ${({theme}) => theme.colors.neutral.grey.deep};
    }
    &:active {
        text-decoration: underline;
        color: ${({theme}) => theme.colors.neutral.grey.deep};
    }
    ${({disabled}) =>
        disabled &&
        `
        opacity: 0.2;
    `};
`;

const CoreText = ({size = 's', linkType = 'internal', children, ...others}: CoreLinkProps) => {
    const textSize = getTextSize(size);

    return (
        <CoreLink textSize={textSize.size} {...getAdditionalProps(linkType)} {...others}>
            {children}
        </CoreLink>
    );
};

export default CoreText;

type getAdditionalPropsReturn =
    | {
          as: 'span';
      }
    | ({
          as: 'a';
      } & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>);

function getAdditionalProps(linkType: LinkType): getAdditionalPropsReturn {
    if (linkType === 'internal') {
        return {as: 'span'};
    }

    return {
        as: 'a',
        rel: 'noopener',
        target: '_blank',
    };
}
