import {mapPropsStreamWithConfig} from 'recompose';
import rxjsconfig from 'recompose/rxjsObservableConfig';
import {Observable} from 'rxjs';
import getObservable from './utils/getObservable';
import {WithPropFromObservableParams} from './types';

/**
 * Add a prop whos value is the last value emitted by an observable
 * @memberof utils
 * @param {object} options
 * @param {Observable} options.fromObservable$ the source observable
 * @param {string} options.toProp the name of the created prop
 * @example
 * import myNamesObservable$ from './observables';
 * import {withPropFromObservable} from '@anywhere-expert/utils'
 *
 * const MyComp = ({myNewTextPropFromObservable}) => (
 *     <h1>{"Hello " + myNewTextPropFromObservable}</h1>
 * )
 *
 * export default withPropFromObservable({fromObservable$: myNamesObservable$, toProp: myNewTextPropFromObservable})(MyComp)
 */
export const withPropFromObservable = <TInner extends TOutter, TOutter extends {} = {}, TProp = any>({
    fromObservable$,
    toProp,
}: WithPropFromObservableParams<TProp>) =>
    mapPropsStreamWithConfig(rxjsconfig)<TInner, TOutter>(props$ =>
        Observable.combineLatest(props$, getObservable(fromObservable$), (props, val) => {
            return Object.assign({}, props, {[toProp]: val});
        })
    );
