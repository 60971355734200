/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path d="M15 3.999V2H9V3.999H3V5.999H21V3.999H15Z" fill={color || '#3C0B63'} />
        <path
            d="M5 6.999V19.999C5 21.101 5.897 21.999 7 21.999H17C18.103 21.999 19 21.101 19 19.999V6.999H5ZM11 18H9V11H11V18ZM15 18H13V11H15V18Z"
            fill={color || '#3C0B63'}
        />
    </BaseIcon>
));

export default Icon;
