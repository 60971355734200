import * as React from 'react';
import styled from 'styled-components';
import {Theme, useTheme} from '@anywhere-expert/colors';
import {getTextColor} from './CoreText';
import withForwardingRef from './behaviours/withForwardingRef';

export type TextSize = 's' | 'm' | 'l';
type InputTextType = 'primary' | 'secondary' | 'tertiary' | 'black';

export type CoreInputTextProps = {
    inputTextType?: InputTextType;
    size?: TextSize;
    isTransparent?: boolean;
    [x: string]: any;
};

const CoreTextInput = styled.input<any>`
    font-size: ${({textSize}) => textSize};
    font-weight: 400;
    display: flex;
    flex: 1;
    color: ${({textColor}) => textColor};
    background-color: ${({backgroundColor}) => backgroundColor};
    border: 0px;
    outline: none;
    &::placeholder {
        opacity: 0.5;
        font-weight: 500;
    }
`;

const getTextSize = (size: TextSize) => {
    switch (size) {
        case 's':
            return '12px';
        case 'm':
            return '14px';
        case 'l':
            return '30px';
        default:
            throw new Error(`unknown text size, given ${size}`);
    }
};

const getBackgroundColor = (theme: Theme, type: InputTextType) => {
    switch (type) {
        case 'primary':
            return theme.colors.neutral.white;
        case 'secondary':
            return theme.colors.neutral.grey.light;
        case 'tertiary':
        case 'black':
            return theme.colors.neutral.white;
        default:
            throw new Error(`unknown text type, given ${type}`);
    }
};

const CoreInputText = ({
    size = 's',
    inputType = 'primary',
    forwardedRef,
    children,
    isTransparent,
    ...others
}: CoreInputTextProps) => {
    const theme = useTheme();
    const textColor = getTextColor(theme, inputType);
    const backgroundColor = isTransparent ? 'transparent' : getBackgroundColor(theme, inputType);
    const textSize = getTextSize(size);

    return (
        <CoreTextInput
            ref={forwardedRef}
            backgroundColor={backgroundColor}
            textColor={textColor}
            textSize={textSize}
            {...others}
        >
            {children}
        </CoreTextInput>
    );
};

export default withForwardingRef(CoreInputText);
