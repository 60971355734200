import React from 'react';
import {useTheme} from '@anywhere-expert/colors';

export default () => {
    const {
        colors: {
            neutral: {black},
        },
    } = useTheme();

    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.33333 1.99935H2.66667V0.666016H0.666667C0.298 0.666016 0 0.964682 0 1.33268V3.33268H1.33333V1.99935Z"
                fill={black}
            />
            <path
                d="M13.3333 0.666016H15.3333C15.702 0.666016 16 0.964682 16 1.33268V3.33268H14.6667V1.99935H13.3333V0.666016Z"
                fill={black}
            />
            <path
                d="M0 12.666V14.666C0 15.034 0.298 15.3327 0.666667 15.3327H2.66667V13.9993H1.33333V12.666H0Z"
                fill={black}
            />
            <path
                d="M15.3333 15.3327H13.3333V13.9993H14.6667V12.666H16V14.666C16 15.034 15.702 15.3327 15.3333 15.3327Z"
                fill={black}
            />
            <path
                d="M4.55306 9.22887H2.77897L2.21257 10.3324H1.34668L3.90527 5.59281H4.62793L5.5459 10.3324H4.73861L4.55306 9.22887ZM3.11751 8.56481H4.44564L4.12337 6.60518L3.11751 8.56481Z"
                fill={black}
            />
            <path
                d="M6.10905 10.3324L6.93262 5.59281L8.3877 5.59606C8.89985 5.59823 9.28396 5.70999 9.54004 5.93135C9.79829 6.15053 9.91222 6.45544 9.88184 6.84606C9.84277 7.30613 9.56174 7.65227 9.03874 7.88447C9.23405 7.95392 9.38487 8.08087 9.49121 8.26533C9.59755 8.44979 9.64312 8.67006 9.62793 8.92614C9.59755 9.36017 9.42285 9.70305 9.10384 9.95478C8.787 10.2065 8.36816 10.3324 7.84733 10.3324H6.10905ZM7.28418 8.19372L7.02702 9.67484L7.87012 9.67809C8.12402 9.67809 8.33995 9.61082 8.5179 9.47627C8.69586 9.34172 8.80111 9.15726 8.83366 8.92288C8.86404 8.69936 8.8304 8.52466 8.73275 8.39879C8.63509 8.27293 8.47342 8.20565 8.24772 8.19697L7.28418 8.19372ZM7.38835 7.58825L8.12728 7.5915C8.39204 7.5915 8.60796 7.52857 8.77507 7.4027C8.94217 7.27683 9.04091 7.10648 9.07129 6.89163C9.12554 6.48582 8.92046 6.27531 8.45605 6.26012L7.61947 6.25687L7.38835 7.58825Z"
                fill={black}
            />
            <path
                d="M13.9834 8.78616C13.9031 9.28096 13.6807 9.67592 13.3161 9.97106C12.9515 10.264 12.5077 10.4062 11.9847 10.3975C11.6831 10.391 11.4183 10.3194 11.1904 10.1826C10.9647 10.0459 10.7879 9.84736 10.6598 9.58695C10.5318 9.32436 10.4602 9.02814 10.445 8.69827C10.432 8.45088 10.4623 8.11451 10.5361 7.68916C10.6099 7.26164 10.751 6.87861 10.9593 6.54007C11.1698 6.20153 11.427 5.94654 11.7308 5.7751C12.0368 5.60366 12.3764 5.52119 12.7497 5.5277C13.2206 5.53638 13.5928 5.68504 13.8662 5.97366C14.1418 6.26012 14.2883 6.65617 14.3057 7.16182H13.5081C13.4973 6.82327 13.4257 6.57913 13.2933 6.42939C13.1631 6.27748 12.9689 6.19827 12.7106 6.19176C12.3569 6.18091 12.0607 6.30787 11.8219 6.57262C11.5832 6.83738 11.4226 7.22258 11.3402 7.72822L11.2751 8.16116L11.2458 8.4932C11.2284 8.71672 11.2436 8.92722 11.2913 9.12471C11.3413 9.32002 11.4281 9.46976 11.5518 9.57393C11.6755 9.67592 11.8328 9.73018 12.0238 9.73669C12.6292 9.76056 13.0177 9.44589 13.1891 8.79268L13.9834 8.78616Z"
                fill={black}
            />
        </svg>
    );
};
