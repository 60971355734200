/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 22H3.293l1.328-1.43c.348-.375.356-.952.021-1.338C3.568 18 3 16.537 3 15c0-2.932 2.072-5.443 5-6.484V6.415C3.947 7.565 1 10.978 1 15c0 1.735.541 3.392 1.573 4.836L.267 22.32A1 1 0 0 0 1 24h10c5.514 0 10-4.038 10-9h-2c0 3.86-3.589 7-8 7z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 2c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 12c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z"
                fill={color}
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M20 8h-4V3h2v3h2v2z" fill={color} />
        </BaseIcon>
    ))
);

export default Icon;
