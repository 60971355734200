/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M17.414 7.99997H20V9.99997H14V3.99997H16V6.58597L21.293 1.29297L22.707 2.70697L17.414 7.99997Z"
            fill={color}
        />
        <path
            d="M7.99997 20V17.414L2.70697 22.707L1.29297 21.293L6.58597 16H3.99997V14H9.99997V20H7.99997Z"
            fill={color}
        />
        <path d="M16 17.414L21.293 22.707L22.707 21.293L17.414 16H20V14H14V20H16V17.414Z" fill={color} />
        <path
            d="M3.99997 9.99997V7.99997H6.58597L1.29297 2.70697L2.70697 1.29297L7.99997 6.58597V3.99997H9.99997V9.99997H3.99997Z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
