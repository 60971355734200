import {observable, action, makeObservable} from 'mobx';
import debounce from 'lodash.debounce';

class UIStore {
    pageDimensions = {width: 0, height: 0};

    constructor() {
        makeObservable(this, {
            pageDimensions: observable,
        });

        // Ensuring window size is not being read for every analytic sent, which can cause
        // unnecessary layout thrashing/repaints
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    private updateDimensions = debounce(
        action(() => {
            this.pageDimensions.width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            this.pageDimensions.height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        }),
        200
    );
}

export default new UIStore();
