import {useState, useEffect} from 'react';

const useIsOnline = (): boolean => {
    const [isOnline, setIsOnline] = useState(navigator.onLine ?? true);
    useEffect(() => {
        const setOffline = (): void => setIsOnline(false);
        const setOnline = (): void => setIsOnline(true);
        window.addEventListener('offline', setOffline);
        window.addEventListener('online', setOnline);

        return () => {
            window.removeEventListener('offline', setOffline);
            window.removeEventListener('online', setOnline);
        };
    });

    return isOnline;
};

export default useIsOnline;
