/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M4 18h8v2H4v-2zm8-12H4V4h8v2zM4 16V8h10V4c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2v-4H4z"
                fill={color}
                fillRule="evenodd"
            />
            <path
                d="M21.19 4.496a1.696 1.696 0 00-2.398 0l-7.195 7.195-.599 2.997 2.997-.599 7.195-7.195a1.696 1.696 0 000-2.398z"
                fill={color}
                fillRule="evenodd"
            />
        </BaseIcon>
    ))
);

export default Icon;
