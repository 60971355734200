import {AxiosRequestConfig} from 'axios';
import {getXApiClientHeaders} from '@anywhere-expert/utils';
import {getAccessToken} from './getAccessToken';
import {setContext} from 'apollo-link-context';
import pRetry, {FailedAttemptError} from 'p-retry';
import logger from '@anywhere-expert/logging';

export const getAuthHeaders = () => ({Authorization: `Bearer ${getAccessToken()}`});

const withRetry = (fetchRequest: () => Promise<Response>, onFailedAttempt: (err: FailedAttemptError) => void) =>
    pRetry(
        async () => {
            try {
                return await fetchRequest();
            } catch (error) {
                if (error instanceof TypeError && error.message === 'Failed to fetch') {
                    throw new Error('Failed to fetch');
                }
                throw error;
            }
        },
        {
            retries: 2,
            onFailedAttempt,
        }
    );

export const authorizedFetch = (url: RequestInfo, init?: RequestInit) =>
    withRetry(
        () =>
            fetch(url, {
                ...init,
                headers: {
                    ...getXApiClientHeaders(),
                    ...getAuthHeaders(),
                    ...init?.headers,
                },
            }),
        err => {
            logger.warn('failed attempt to make request', {err, extra: {url}});
        }
    );
export const createAxiosAuthHeadersInterceptor = () => (config: AxiosRequestConfig) => ({
    ...config,
    headers: {
        ...getXApiClientHeaders(),
        ...getAuthHeaders(),
        ...config.headers,
    },
});

export const apolloAuthLink = setContext((_, {headers}) => {
    return {
        headers: {
            ...headers,
            ...getAuthHeaders(),
        },
    };
});
