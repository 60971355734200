/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M.211 10.674A.91.91 0 0 0 .909 11h6.09v5.091c0 .502.407.909.91.909h.757a.907.907 0 0 0 .834-.55L12 11h.3a.7.7 0 0 0 .7-.7V3.063c0-.387-.31-.758-.658-.926-.26-.126-.56-.291-.86-.456-.62-.34-1.239-.681-1.517-.681L6.689.998 2.499.996a.908.908 0 0 0-.895.746L.014 9.929a.906.906 0 0 0 .197.745zM15 10.091c0 .502.406.909.908.909h3.184A.909.909 0 0 0 20 10.09V1.908A.909.909 0 0 0 19.092 1h-3.184a.908.908 0 0 0-.908.908v8.183z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
