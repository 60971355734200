/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.293 14.5a1 1 0 0 0 .707-.293l5.996-5.956.004-.004a2.998 2.998 0 0 0-.008-4.24L17.984 4a3 3 0 0 0-4.238.004l-6 5.958a1 1 0 0 0-.294.707V13.5a1 1 0 0 0 1 1h2.84zm-.414-2H9.452v-1.416l5.708-5.666a1 1 0 0 1 1.413-.001l.007.007a.999.999 0 0 1 .002 1.413L10.879 12.5z"
            fill={color}
        />
        <path
            d="M5.25 14a2.25 2.25 0 0 0 0 4.5h3.5a.25.25 0 1 1 0 .5H3a1 1 0 1 0 0 2h5.75a2.25 2.25 0 0 0 0-4.5h-3.5a.25.25 0 1 1 0-.5h1.125a1 1 0 1 0 0-2H5.25z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
