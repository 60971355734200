/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M18.64 12.842c-.08.381-.54.646-.845.811-.857.479-1.846.718-2.753 1.105a12.275 12.275 0 00-3.195 1.977c-.724.625-1.364 1.356-1.842 2.183-.389.672-.671 1.41-.778 2.18-.137.999-.052 2.02.285 2.978.285.821.657 1.617 1.145 2.344 1.953 2.922 5.387 3.67 8.68 4.021.898.098 1.803.103 2.698.007 2.569-.271 5.227-1.527 6.259-4.013.348-.837.346-1.66.468-2.439.075-.47-.027-.878.01-1.263.053-.575-.305-1.05-.436-1.59-.147-.61-.086-1.252-.313-1.843-.357-.924-1.07-1.48-1.714-2.179-.461-.497-.89-1-1.318-1.527-.134-.171-.28-.34-.397-.533-.05-.086-.19-.352-.144-.268-.077-.172-.16-.34-.231-.51M31.593 18.048c.556-.218 1.089-.623 1.624-.89 1.151-.59 2.32-1.146 3.448-1.773M31.689 24.71c.112.154.807.14.947.163.867.153 1.73.356 2.592.539M28.313 31.47c-.054.02.268.693.283.714.392.82.812 1.626 1.267 2.415M10.955 13.317c.11-.07-.206-.156-.3-.25-.232-.237-.465-.473-.704-.711a69.571 69.571 0 00-2.406-2.27M7.055 19.47c.118.156-1.096-.352-1.24-.413a24.162 24.162 0 01-2.294-1.08M7.553 26.781c.12.05-.246.091-.375.132-.29.089-.582.163-.874.207-.98.166-1.99.151-2.977.116M14.706 32.123l-.18.193c-.136.14-.274.285-.406.431"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="1.2"
            />
            <path
                d="M22.277 19.593c.038-.03-1.044-.684-1.096-.707-.839-.347-1.76-.044-2.522.35-.19.096-.574.27-.63.507-.103.421.863.882 1.12 1.037.954.57 3.83 1.462 3.562 2.958-.123.697-.802 1.108-1.422 1.331-1.555.567-3.043.251-3.994-1.136"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="1.2"
            />
            <path
                d="M20.53 18.9c.186-.073-.072-.402-.048-.603.063-.504.095-1.009.15-1.508M19.598 25.09c-.1.44-.397 1.038-.566 1.482M12.001 23.441c.14.554-.093 1.209.6 1.65.243.157 1.028.05 1.138.179.116.137.019.565.022.728.039.706.393 1.318 1.171 1.359M19.027 11.433c-.08-.042-.418-.669-.515-.777-.79-.897-1.616-2.139-1.769-3.343-.044-.353-.038-1.3.374-1.492.314-.145.692.297.866.483.392.439 1.088 1.073 1.73 1.098.506.02 1.08-.544 1.509-.759.895-.452 2.195-.705 3.175-.341.938.355.903 1.377 1.66 1.812.646.368 1.5.076 2.152.507.324.219.686.676.636 1.09-.123 1.052-2.113.585-2.732.983-.503.324-1.055.7-1.477 1.114-.03.025-.602.67-.635.62M23.997 12.398c-.378.207.234 1.447.288 1.751M19.104 11.583c.014-.01-.069.29-.084.331-.094.267-.23.494-.409.711"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="1.2"
            />
            <path
                d="M18.744 12.678c.09-.006-.075.233.012.27.356.132.772.127 1.146.144 1.269.07 2.575-.032 3.821.233a94.16 94.16 0 01-.205-.044c-.15-.032-.303-.052-.456-.072a19.27 19.27 0 01-1.17-.211c-.976-.207-2.191-.884-3.2-.817-.064.005.123.013.192.015.142.012.222.023.369.041.458.067.925.147 1.383.245 1.157.24 2.336.558 3.39 1.107M25.535 10.931c-.108.034-.049-.215-.084-.316a1.395 1.395 0 00-.369-.603c-.552-.541-1.317-.822-2.078-.91-1.143-.13-2.267.273-3.411.236-.444-.013-.835-.159-1.242-.314-.467-.174-.965-.348-1.234-.798"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="1.2"
            />
        </BaseIcon>
    ))
);

export default Icon;
