import React, {useMemo} from 'react';
import styled from 'styled-components';
import {palette} from '@anywhere-expert/colors';
import CoreText, {CoreTextProps} from './CoreText';

const Highlight = styled(CoreText)`
    background-color: ${({theme}) => theme.colors.brand.green};
    color: ${({theme}) => theme.themeName === 'dark' && palette.neutral.whites.w60};
`;

const Text = styled(CoreText)`
    white-space: pre-wrap;
    display: inline;
`;

type CoreHightlightedTextProps = CoreTextProps & {
    text: string;
    searchedText: string;
};

export default ({text, searchedText, ...coreTextProps}: CoreHightlightedTextProps) => {
    const indexOfMatch = text.toLowerCase().indexOf(searchedText.toLowerCase());
    if (indexOfMatch === -1) {
        return <Text {...coreTextProps}>{text}</Text>;
    }

    const matches = useMemo(() => {
        const searchStrLen = searchedText.length;
        if (searchStrLen == 0) {
            return <Text {...coreTextProps}>{text}</Text>;
        }

        let startIndex = 0,
            index;

        const loweredText = text.toLowerCase();
        const loweredSearchedText = searchedText.toLowerCase();
        let matchesJSX: JSX.Element[] = [];
        while ((index = loweredText.indexOf(loweredSearchedText, startIndex)) > -1) {
            const start = text.substring(startIndex, index);
            const highlightText = text.substring(index, index + searchStrLen);
            matchesJSX.push(
                <Text {...coreTextProps}>
                    {start}
                    <Highlight {...coreTextProps}>{highlightText}</Highlight>
                </Text>
            );
            startIndex = index + searchStrLen;
        }
        const textTail = text.substring(startIndex, text.length);
        matchesJSX.push(<Text {...coreTextProps}>{textTail}</Text>);
        return matchesJSX;
    }, [text, searchedText]);

    return <div>{matches}</div>;
};
