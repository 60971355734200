import React from 'react';
import styled from 'styled-components';
import {Size} from '../compoundTypes';
import {ScreenMinWidth} from '../../media-queries';

export type CompoundButtonSetProps = React.HTMLAttributes<HTMLDivElement> & {
    size: Size;
    responsive?: boolean;
    disabled?: boolean;
    row?: boolean;
};

const ButtonSetContainer = styled.div<any>`
    display: flex;
    justify-content: ${({row}) => (row ? 'center' : 'flex-end')};
    flex-direction: ${({row, responsive}) => (row ? 'row' : responsive ? 'column-reverse' : 'column')};
    max-width: 100%;
    & > button {
        margin: ${({margin}) => margin};
    }
`;

type State = {
    invert: boolean;
};

class CompoundButtonSet extends React.Component<CompoundButtonSetProps, State> {
    constructor(props) {
        super(props);
        this.state = {invert: false};
        this.calculateMargin = this.calculateMargin.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        if (this.props.responsive) {
            this.updateWindowDimensions();
            window.addEventListener('resize', this.updateWindowDimensions);
        }
    }

    componentWillUnmount() {
        if (this.props.responsive) {
            window.removeEventListener('resize', this.updateWindowDimensions);
        }
    }

    updateWindowDimensions() {
        this.setState({invert: window.innerWidth < ScreenMinWidth.large});
    }

    calculateMargin(row, size) {
        if (row) {
            return `0px ${getSize(size)}`;
        }
        return `${getSize(size)} 0px`;
    }

    render() {
        const {disabled, responsive, children, size, row = true, ...others} = this.props;
        const isRow = row && !this.state.invert;
        return (
            <ButtonSetContainer
                {...others}
                responsive={responsive}
                row={isRow}
                size={size}
                margin={() => this.calculateMargin(isRow, size)}
            >
                {children}
            </ButtonSetContainer>
        );
    }
}

export default CompoundButtonSet as React.ComponentType<CompoundButtonSetProps>;

function getSize(size: Size) {
    switch (size) {
        case 'xs':
            return '2px';
        case 'm':
            return '4px';
        case 'l':
            return '7px';
    }

    return '4px';
}
