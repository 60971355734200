/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V6h16v12z"
                fill={color}
                fillRule="evenodd"
            />
            <path
                d="M17.997 11h-4v2h4v-2zM17.997 14h-4v2h4v-2zM5.996 16c0-1.841 1.159-3 3-3s3 1.159 3 3h-6zM10.996 10a2 2 0 11-4.001 0 2 2 0 014.001 0z"
                fill={color}
                fillRule="evenodd"
            />
        </BaseIcon>
    ))
);

export default Icon;
