/**
 * support values are: error, warn, info, debug, trace
 * @memberof logging
 * @enum {number}
 */

import {LogFunc, LogLevel} from './types';
import {LOG_LEVELS} from './consts';

let _loggers: LogFunc[] = [];
let _maxLogLevel: LogLevel = 'warn';

export const init = (loggers: LogFunc[], maxLogLevel: LogLevel) => {
    _loggers = loggers;
    _maxLogLevel = maxLogLevel;
};

export default (levelName: LogLevel, message, data) => {
    if (LOG_LEVELS[_maxLogLevel] < LOG_LEVELS[levelName]) {
        return Promise.resolve();
    }
    return Promise.all(_loggers.map(log => log(levelName, message, data)));
};

export const updateLogLevel = (logLevel: LogLevel) => {
    _maxLogLevel = logLevel;
};
