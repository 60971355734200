/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <svg width="12" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4 .667h4V2H4V.667zm1.333 8.666v-4h1.334v4H5.333zm5.354-4.406l.946-.947c-.286-.34-.6-.66-.94-.94l-.946.947A5.975 5.975 0 006 2.667a6 6 0 106 6 5.975 5.975 0 00-1.313-3.74zm-9.354 3.74A4.663 4.663 0 006 13.333a4.663 4.663 0 004.667-4.666A4.663 4.663 0 006 4a4.663 4.663 0 00-4.667 4.667z"
                    fill="#000"
                    fill-opacity=".6"
                />
            </svg>
        </BaseIcon>
    ))
);

export default Icon;
