/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M19.6252 1.4866C20.5781 0.533653 22.1231 0.533631 23.076 1.48656L23.2854 1.69587C24.2382 2.64877 24.2382 4.1937 23.2854 5.14661L12.8109 15.6216L9.15085 11.9615L19.6252 1.4866Z"
                fill={color}
            />
            <path d="M7.3202 13.7911L10.98 17.4515L7.3202 17.4512V13.7911Z" fill={color} />
            <path
                d="M17.0805 22.3317V15.0115L19.5205 12.5715V22.3317C19.5205 23.6794 18.428 24.7718 17.0805 24.7718H2.44007C1.09245 24.7718 0 23.6794 0 22.3317V7.69135C0 6.34373 1.09245 5.25128 2.44007 5.25128H12.2003L9.76027 7.69135H2.44007V22.3317H17.0805Z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
