/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M9.2 12.72a2.8 2.8 0 105.6 0 2.8 2.8 0 00-5.6 0zm12.533 1.573c-.026-.506-.826-1.2-2.213-.88-1.013.24-2.4 1.094-3.813 2.507-1.04 1.04-1.974 2.613-1.974 5.093.027 2.96 8.134-2.666 8-6.72zM2.24 10.027c-.053.48.933.426 1.547.346 1.92-.346 5.146-2 8.373-2 2.267 0 4.693 1.067 6.32 1.547.56.16 1.36.453 2.507.507.426.026.853-.08.826-.454C21.653 7.333 18.24 2.08 12 2.08c-4.747 0-9.387 3.973-9.76 7.947zm.08 4.266c-.133 4.054 8 9.68 8 6.72 0-2.48-.933-4.053-1.973-5.093-1.414-1.387-2.774-2.267-3.814-2.48-1.386-.32-2.186.347-2.213.853zM0 12C0 5.36 5.36 0 12 0s12 5.36 12 12-5.36 12-12 12S0 18.64 0 12z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
