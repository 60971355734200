/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 14a1 1 0 1 1 0 2H6a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-2a1 1 0 1 1 0-2h2V5H6v9h2z"
            fill={color}
        />
        <path
            d="M11 12v7.586l-1.677-1.677a1 1 0 0 0-1.414 1.414l3.384 3.384a1 1 0 0 0 1.414 0l3.384-3.384a1 1 0 0 0-1.414-1.414L13 19.586V12a1 1 0 1 0-2 0z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
