import * as React from 'react';

export default ({color, ...props}) => (
    <svg {...props} viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Flex-Techs-App" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Queue" transform="translate(-10.000000, -55.000000)" fill={color}>
                <g id="Avatar" transform="translate(10.000000, 55.000000)">
                    <g id="avatar">
                        <path
                            d="M5.80437736,21.9793098 C6.2949434,20.8865476 7.2005283,19.7620247 8.24837736,18.783402 C9.62196226,19.7550771 11.3124528,20.3148572 13.1275472,20.2830967 C14.9357736,20.2533211 16.6046792,19.6329974 17.94,18.6176515 C18.9917736,19.5694763 19.9189434,20.6751412 20.4595472,21.7609559 C18.4462642,23.4720568 15.8433208,24.5112231 13,24.5112231 C10.2793208,24.5112231 7.77939623,23.5593984 5.80437736,21.9793098 M19.2036981,12.0729882 C19.2321509,13.7801191 18.5649811,15.3274546 17.4582642,16.4658727 C16.3495849,17.6042907 14.8141132,18.3169186 13.0932075,18.3476867 C11.3742642,18.3744847 9.81426415,17.7253779 8.66732075,16.6266606 C7.52233962,15.5249656 6.80218868,13.9915254 6.77177358,12.3052374 C6.75803774,11.4844251 6.89539623,10.7241564 7.15539623,10.0313788 C8.92830189,9.43983814 9.48460377,7.74660253 9.64943396,6.98037868 L9.672,6.9644984 C10.4735849,8.25179417 12.7655094,10.5177126 19.106566,11.0824553 C19.1644528,11.4040311 19.1978113,11.7345396 19.2036981,12.0729882 M1.47169811,13 C1.47169811,6.65284776 6.64324528,1.48877691 13,1.48877691 C19.3567547,1.48877691 24.5283019,6.65284776 24.5283019,13 C24.5283019,15.8395938 23.4873208,18.4380058 21.773283,20.4478546 C21.1404528,19.3679951 20.2554717,18.307986 19.2458868,17.3750191 C20.4605283,15.9348756 21.1826415,14.0629867 21.1483019,12.0402352 C21.1100377,9.80607726 20.1593208,7.79027332 18.6601509,6.35211483 C17.1609811,4.91197129 15.0986415,4.03756299 12.8498868,4.07428615 C10.5991698,4.11299435 8.56724528,5.06779661 7.12007547,6.55855856 C5.66996226,8.04535044 4.78988679,10.1177279 4.82716981,12.3379905 C4.86150943,14.3448618 5.64445283,16.1849901 6.90226415,17.5824553 C5.90543396,18.5372576 5.04890566,19.613147 4.45433962,20.6999542 C2.60686792,18.6573523 1.47169811,15.9626661 1.47169811,13 M13,0 C5.83184906,0 0,5.83203543 0,13 C0,20.1679646 5.83184906,26 13,26 C20.1681509,26 26,20.1679646 26,13 C26,5.83203543 20.1681509,0 13,0"
                            id="Fill-1"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
