/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.116 11.884l4.977 4.976 9.874-12.069-1.934-1.583-8.126 9.931-3.023-3.023-1.768 1.768zM2 13c0 4.963 4.038 9 9 9s9-4.037 9-9h-2c0 3.86-3.14 7-7 7s-7-3.14-7-7 3.14-7 7-7V4c-4.962 0-9 4.037-9 9z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
