/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M3.433 7.132c.18-1.656.574-3.098.832-4.931m2.947 7.302c.794-.748 1.691-1.667 2.663-2.22.356-.203.534-.348.876-.588m-1.688 6.934c1.512-.254 3.954-.56 5.133-.694"
                stroke={color}
                strokeLinecap="round"
                strokeWidth="1.143"
            />
        </BaseIcon>
    ))
);

export default Icon;
