import styled from 'styled-components';
import {getBoxShadowStyle} from './shared/styles';

export type CoreActionMenuItemProps = {
    [x: string]: any;
};

const MenuItemDiv = styled.div`
    display: flex;
    z-index: 6;
    padding: 6px 4px;
    width: max-content;
    height: max-content;
    position: relative;
    border-radius: 8px;
    flex-direction: column;
    ${({theme}) => getBoxShadowStyle(theme)};
    border: ${({theme}) => theme.themeName === 'dark' && `1px solid ${theme.colors.contrast.border};`};
    background-color: ${({theme}) => theme.colors.background.secondary};
    color: ${({theme}) => theme.colors.neutral.black};
`;

export default MenuItemDiv;
