/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 3v2.171c0 .79-.32 1.563-.879 2.122L13.414 12l4.707 4.707A2.98 2.98 0 0 1 19 18.829V21a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-2.171c0-.802.312-1.555.879-2.121L10.586 12 5.879 7.293A3.017 3.017 0 0 1 5 5.172V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-2 17v-1.171a.99.99 0 0 0-.293-.707L12 13.414l-4.707 4.707a.994.994 0 0 0-.293.708V20h10zm-.293-14.122c.186-.186.293-.444.293-.707V4H7v1.171c0 .263.107.521.293.707L12 10.585l4.707-4.707zM12 16l-3 3h6l-3-3z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
