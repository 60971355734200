import React from 'react';
import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {RadioOffIcon, RadioOnIcon} from '@anywhere-expert/icons';
import {useTheme} from '@anywhere-expert/colors';
import CoreText from '../CoreText';

export type CoreRadioButtonProps = {
    label?: string;
    checked?: boolean;
    value?: any;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    [x: string]: any;
};

const StyledFormControlLabel = styled(FormControlLabel)`
    &.MuiFormControlLabel-root {
        padding-left: 3px;
    }

    ${({disabled}) => disabled && `opacity: 0.2;`}
`;

const CheckedIcon = () => {
    const theme = useTheme();
    return <RadioOnIcon size="s" color={theme.colors.neutral.black} />;
};
const UncheckedIcon = () => {
    const theme = useTheme();
    return <RadioOffIcon size="s" color={theme.colors.contrast.secondary} />;
};

const CoreRadioButton = ({checked, label, onChange, disabled, value, ...props}: CoreRadioButtonProps) => {
    const radioButton = (
        <Radio
            checked={checked}
            value={value}
            onChange={onChange}
            disabled={disabled}
            color="default"
            icon={<UncheckedIcon />}
            checkedIcon={<CheckedIcon />}
            {...props}
        />
    );
    return (
        <StyledFormControlLabel
            disabled={disabled}
            label={<CoreText size="m">{label}</CoreText>}
            control={radioButton}
        />
    );
};

export default CoreRadioButton;
