/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M17 2H7C5.897 2 5 2.898 5 4V21C5 21.369 5.203 21.708 5.528 21.882C5.852 22.056 6.247 22.037 6.555 21.832L12 18.203L17.445 21.832C17.613 21.944 17.806 22 18 22C18.162 22 18.324 21.961 18.472 21.882C18.797 21.708 19 21.369 19 21V4C19 2.898 18.103 2 17 2Z"
            fill={color || '#3C0B63'}
        />
    </BaseIcon>
));

export default Icon;
