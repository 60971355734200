/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M12 5C5.2 5 1.833 9.726 1 12.088 1.833 14.392 5.2 19 12 19s10.167-4.667 11-7c-.833-2.333-4.2-7-11-7zm-9 7c1 1.667 4.2 5 9 5s8-3.333 9-5c-1-1.667-4.2-5-9-5s-8 3.333-9 5zm9 3a3 3 0 100-6 3 3 0 000 6z"
                fill={color}
                fillRule="evenodd"
            />
        </BaseIcon>
    ))
);

export default Icon;
