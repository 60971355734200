import {CSSObject} from 'styled-components';
import {GetVariantStyle} from './types';
import {getBaseButtonStyle, getButtonColor} from '../shared/button';

export function getVariantStyle({theme, variant, color: colorCategory, colorOverride}: GetVariantStyle): CSSObject {
    const color = colorOverride || getButtonColor(theme, colorCategory, variant);
    const baseStyle = getBaseButtonStyle(theme, variant, color);

    return baseStyle;
}
