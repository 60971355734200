import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {usernamePasswordLogin} from '../web-login-flow-manager';

type Props = {
    onClose?: () => void;
};

const UsernamePassword = ({onClose}: Props) => {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    const onSubmit = async () => {
        try {
            await usernamePasswordLogin(username, password);
            onClose && onClose();
        } catch (error) {
            setErrorMessage('Incorrect username/password');
            console.log(error);
        }
    };

    return (
        <div id="username_password_form">
            <Dialog open={true} onClose={onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Login</DialogTitle>
                <DialogContent style={{flexDirection: 'column', display: 'flex'}}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        label="username"
                        onChange={e => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        label="password"
                        type="password"
                        onChange={e => setPassword(e.target.value)}
                    />
                    {errorMessage && <DialogContentText style={{color: 'red'}}>{errorMessage}</DialogContentText>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} color="primary" id="submit">
                        Login
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UsernamePassword;
