import {useEventListener} from '../../hooks';
import React from 'react';

const useDetectOutsideClick = (onOutsideClick?: (e: KeyboardEvent) => void, shouldAddListener: boolean = true) => {
    const containerRef = React.useRef<HTMLDivElement>(null);

    const handleClickOutside = React.useCallback(
        e => {
            if (containerRef && containerRef.current && !containerRef.current.contains(e.target) && onOutsideClick) {
                onOutsideClick(e);
            }
        },
        [onOutsideClick]
    );

    useEventListener('mousedown', handleClickOutside, window, shouldAddListener);

    return containerRef;
};

export default useDetectOutsideClick;
