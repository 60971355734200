/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';
import {useTheme} from '@anywhere-expert/colors';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => {
        const {
            colors: {
                neutral: {black},
            },
        } = useTheme();
        return (
            <BaseIcon {...baseIconProps} ref={ref}>
                <path
                    d="M3 5.5h14a1 1 0 011 1v15a1 1 0 01-1 1H3a1 1 0 01-1-1v-15a1 1 0 011-1zm13 2H4v13h12v-13z"
                    fill={black}
                    fillRule="evenodd"
                />
                <path
                    d="M18.5 1.5H21a1 1 0 011 1v1h-3.5v-2zM16 1.5h-2.5v2H16v-2zM11 3.5v-2H8.5v2H11zM20 6v2.5h2V6h-2zM22 11h-2v2.5h2V11zM22 16h-2v2.5h2V16z"
                    fill={black}
                />
            </BaseIcon>
        );
    })
);

export default Icon;
