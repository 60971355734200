/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 12a8 8 0 1 1-16 0 8 8 0 0 1 16 0zm2 0c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zM8.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM17 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zM12 17a5.001 5.001 0 0 0 4.584-3H7.416A5.001 5.001 0 0 0 12 17z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
