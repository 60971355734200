import * as React from 'react';
import styled from 'styled-components';
import CoreText from './CoreText';
import {palette, useTheme} from '@anywhere-expert/colors';

type BadgeColor = 'primary' | 'secondary' | 'alert';

const ExtendedText = styled(CoreText)`
    color: ${({theme}) => theme.colors.neutral.white};
`;

const Container = styled.div<{badgeColor: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({badgeColor}) => badgeColor};
    height: 20px;
    border-radius: 10px;
    line-height: 18px;
    padding: 0 7px;
`;

type Props = {
    text?: any;
    color?: BadgeColor;
    [x: string]: any;
};

const useColor = (type: BadgeColor) => {
    const theme = useTheme();
    switch (type) {
        case 'primary':
            return theme.colors.neutral.black;
        case 'secondary':
            return theme.colors.brand.blue;
        case 'alert':
            return palette.semantic.red.normal;
    }
};

const CoreBadge = ({text, color = 'primary', ...others}: Props) => {
    const badgeColor = useColor(color);
    if (!text) return null;
    return (
        <Container badgeColor={badgeColor} {...others}>
            <ExtendedText size="s" weight="bold">
                {text}
            </ExtendedText>
        </Container>
    );
};

export default CoreBadge;
