interface UserProperties {
    uid: string;
    email: string;
    roles: string[];
    groups: string[];
    queues: string[];
    expertise: string[];
    displayName: string;
    imageUrl: string | undefined;
    isLoggedIn: boolean;
    cameFromLogin?: boolean;
    loginRequired: boolean;
    accessToken: string | undefined;
}

export default class User implements UserProperties {
    uid: string;
    email: string;
    roles: string[];
    queues: string[];
    groups: string[];
    expertise: string[];
    displayName: string;
    imageUrl: string | undefined;
    isLoggedIn: boolean;
    cameFromLogin?: boolean;
    loginRequired: boolean;
    accessToken: string | undefined;

    constructor(data: UserProperties) {
        this.uid = data.uid;
        this.email = data.email;
        this.roles = data.roles;
        this.queues = data.queues;
        this.groups = data.groups;
        this.expertise = data.expertise;
        this.displayName = data.displayName;
        this.imageUrl = data.imageUrl;
        this.isLoggedIn = data.isLoggedIn;
        this.cameFromLogin = data.cameFromLogin;
        this.loginRequired = data.loginRequired;
        this.accessToken = data.accessToken;
    }

    get isModerator() {
        return this.roles && this.roles.includes('moderator');
    }

    get isVoiceExpert() {
        return this.expertise.some(ex => ex.includes('voice'));
    }
}
