import React, {memo} from 'react';

const Icon = ({color}) => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.8597 35.3625C16.28 37.125 21.9161 32.7179 22.7057 31.4607C22.7057 31.4607 30.0815 31.9471 32.1904 30.5178C34.3189 29.0753 34.7138 26.7951 35.1675 24.4421C36.0445 16.82 35.8458 9.82839 32.9401 8.02321C28.4359 5.2254 12.2118 4.32534 7.0025 7.55453C3.94346 9.45877 4.48614 24.0286 5.87165 27.4612C6.39308 28.7615 6.81019 29.2668 7.82821 29.7475C9.05504 30.3259 13.0889 30.5613 14.4691 31.1937C14.6477 31.2756 14.7818 31.364 14.8597 31.4607C14.8845 31.4853 14.3879 34.7771 14.8597 35.3625Z"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M23.8331 27.6852C24.7845 27.3119 25.9381 27.0881 27.3737 24.584C28.4814 22.6475 28.32 18.7125 28.379 16.4486C28.4409 13.8627 27.7506 11.7066 26.1096 11.9434C24.8059 12.1315 24.8996 16.9788 24.8996 16.9788C24.8996 16.9788 24.2046 16.0529 24.0275 15.8185C23.3472 14.9505 18.2264 7.95805 16.5855 9.20591C14.9446 10.4538 18.0323 13.8172 19.4506 15.4432C20.0689 16.157 18.7756 14.5319 18.209 13.8757C17.5789 13.1385 14.5937 9.78032 13.4286 10.6914C11.7434 11.9997 13.951 14.2067 17.1047 17.5124C17.2935 17.7116 12.979 12.4235 11.4521 14.5353C10.1728 16.3114 15.4676 20.2773 15.4676 20.2773C15.4676 20.2773 12.6458 17.5621 11.7685 17.3951C10.5668 17.1733 9.99408 18.4726 10.674 19.641C12.3602 22.5528 15.7894 25.8926 18.5767 27.1602C21.7748 28.6177 23.8331 27.6852 23.8331 27.6852Z"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
        <path
            d="M22.6384 22.9873C21.1122 22.1759 20.1283 22.1569 18.5761 21.9724"
            stroke={color}
            strokeWidth="1.3"
            strokeLinecap="round"
        />
    </svg>
);

export default memo(Icon);
