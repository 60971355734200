import {CSSObject} from 'styled-components';
import {Theme} from '@anywhere-expert/colors';
import {CoreButtonColor, CoreButtonVariant} from '../CoreButton';
import {CoreIconButtonVariant} from '../CoreIconButton';
import {getBoxShadowStyle} from './styles';

type ButtonVariant = CoreButtonVariant | CoreIconButtonVariant;

export function getBaseButtonStyle(theme: Theme, variant: ButtonVariant, color: Color): CSSObject {
    const mainColor = typeof color === 'string' ? color : color.main;
    const hoverColor = typeof color === 'string' ? color : color.hover;

    switch (variant) {
        case 'contained':
            return {
                backgroundColor: mainColor,
                color: theme.palette.getContrastText(mainColor),
                '&:hover': {
                    backgroundColor: theme.utils.lighten(mainColor, 0.2),
                },
                '&:active': {
                    backgroundColor: theme.utils.darken(mainColor, 0.2),
                },
                '&:disabled': {
                    backgroundColor: theme.colors.neutral.grey.lighter,
                    color: theme.colors.neutral.grey.deep,
                },
            };
        case 'outlined':
            return {
                borderColor: mainColor,
                borderStyle: 'solid',
                borderWidth: 0,
                color: mainColor,
                '&:hover': {
                    backgroundColor: theme.utils.lighten(mainColor, theme.themeName === 'light' ? 0.9 : 0.8),
                },
                '&:active, &[data-is-active="true"]': {
                    opacity: 1,
                    backgroundColor: theme.utils.lighten(mainColor, theme.themeName === 'light' ? 0.8 : 0.9),
                },
                '&:disabled': {
                    borderColor: theme.colors.neutral.grey.light,
                    color: theme.colors.neutral.grey.light,
                },
            };
        case 'icon':
            return {
                borderColor: mainColor,
                borderStyle: 'solid',
                borderWidth: 0,
                opacity: 0.6,
                color: mainColor,
                '&:hover': {
                    opacity: 1,
                    backgroundColor: theme.utils.lighten(mainColor, theme.themeName === 'light' ? 0.9 : 0.8),
                },
                '&:active, &[data-is-active="true"]': {
                    opacity: 1,
                    backgroundColor: theme.utils.lighten(mainColor, theme.themeName === 'light' ? 0.8 : 0.9),
                },
                '&:disabled': {
                    borderColor: theme.colors.neutral.grey.light,
                    color: theme.colors.neutral.grey.light,
                },
            };
        case 'icon-fade':
            return {
                borderColor: mainColor,
                borderStyle: 'solid',
                borderWidth: 0,
                color: mainColor,
                opacity: 1,
                '&:hover': {
                    opacity: 0.8,
                },
                '&:active, &[data-is-active="true"]': {
                    opacity: 0.8,
                },
                '&:disabled': {
                    borderColor: theme.colors.neutral.grey.light,
                    color: theme.colors.neutral.grey.light,
                },
            };
        case 'text':
            return {
                backgroundColor: 'transparent',
                color: mainColor,
                '&:hover, &:active': {
                    opacity: 0.8,
                },
                '&:disabled': {
                    color: theme.colors.contrast.disabled,
                },
            };
        case 'floating':
            return {
                ...getBoxShadowStyle(theme),
                backgroundColor: theme.utils.split(theme.colors.background.secondary, theme.colors.foreground.primary),
                color: mainColor,
                '&:hover': {
                    color: theme.palette.getContrastText(hoverColor),
                    backgroundColor: hoverColor,
                },
                '&:active': {
                    backgroundColor: theme.utils.darken(hoverColor, 0.2),
                    color: theme.palette.getContrastText(hoverColor),
                },
                '&:disabled': {
                    backgroundColor: theme.colors.neutral.white,
                    color: theme.colors.neutral.grey.light,
                },
            };
        case 'hovered':
            return {
                borderColor: mainColor,
                borderStyle: 'solid',
                borderWidth: 0,
                opacity: 1,
                backgroundColor: theme.utils.lighten(mainColor, theme.themeName === 'light' ? 0.9 : 0.8),
                '&:active, &[data-is-active="true"]': {
                    opacity: 1,
                    backgroundColor: theme.utils.lighten(mainColor, theme.themeName === 'light' ? 0.8 : 0.9),
                },
                '&:disabled': {
                    borderColor: theme.colors.neutral.grey.light,
                    color: theme.colors.neutral.grey.light,
                },
            };
        case 'none': {
            return {};
        }
    }
}

const specialColorVariants: ButtonVariant[] = ['outlined', 'text', 'floating', 'icon', 'icon-fade'];

type Color = string | {main: string; hover: string};

export function getButtonColor(theme: Theme, color: CoreButtonColor, variant: ButtonVariant): Color {
    switch (color) {
        case 'primary':
            return theme.colors.brand.purple;
        case 'secondary':
            return theme.colors.neutral.black;
        case 'tertiary':
            return theme.colors.brand.blue;
        case 'danger':
            return specialColorVariants.includes(variant)
                ? {main: theme.colors.semantic.red.deep, hover: theme.colors.semantic.red.normal}
                : theme.colors.semantic.red.normal;
        default:
            return getDefaultColor(variant, theme);
    }
}

function getDefaultColor(variant: ButtonVariant, theme: Theme) {
    if (variant === 'icon') {
        return {main: theme.colors.neutral.black, hover: theme.colors.neutral.grey.lighter};
    }

    return specialColorVariants.includes(variant)
        ? {
              main: theme.colors.neutral.grey.deepest,
              hover: theme.utils.darken(theme.colors.background.secondary, 0.2),
          }
        : theme.colors.neutral.grey.deepest;
}
