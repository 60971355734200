const passwordGrantTokenRequestSettings = {
    grant_type: 'password',
    response_type: 'code',
    scope: 'openid profile email expert-identity expert-resource home',
};

export const getUsernamePasswordToken = async (clientId, clientSecret, username, password) => {
    const params = {
        ...passwordGrantTokenRequestSettings,
        username,
        password,
        client_id: clientId,
        client_secret: clientSecret,
    };
    return await fetchToken(process.env.AUTH_API_BASE_URL + '/openid/connect/token', params);
};

const fetchToken = async (url, params) => {
    const body = Object.keys(params)
        .map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        })
        .join('&');

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
    });

    return response.json();
};
