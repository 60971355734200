import * as React from 'react';
import styled from 'styled-components';
import CoreText from './CoreText';
import {CoreListItemContainer} from './CoreListItem';

const styles = {width: '100%'};

const Header = styled(CoreListItemContainer)`
    min-height: 28px;
    max-height: 28px;
    padding: unset;
    cursor: default;
`;

const Divider = styled.div<{hasIcons: boolean}>`
    display: flex;
    margin: 6px 0px 6px ${({hasIcons}) => (hasIcons ? '44px' : '0px')};
    border-radius: 4px;
    background-color: ${({theme}) => theme.colors.neutral.white};
    border: ${({theme}) => `0.5px solid ${theme.colors.neutral.grey.light}`};
`;

type Props = {
    isFirstHeader?: boolean;
    hasIcons?: boolean;
    text: string;
};

const CoreListItemHeader = ({isFirstHeader, hasIcons, text}: Props) => (
    <>
        {!isFirstHeader && <Divider hasIcons={!!hasIcons} style={styles} />}
        <Header>
            <CoreText textType="tertiary" size="s" weight="bold" style={{marginLeft: '12px'}}>
                {text && text.toUpperCase()}
            </CoreText>
        </Header>
    </>
);

export default CoreListItemHeader;
