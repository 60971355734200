export const ScreenMinWidth = {
    medium: 1000,
    large: 1300,
    xlarge: 1700,
};

export const toMediaQuery = (minWidth: number): string => `@media screen and (min-width: ${minWidth}px)`;

export const MediaQuery = {
    medium: toMediaQuery(ScreenMinWidth.medium),
    large: toMediaQuery(ScreenMinWidth.large),
    xlarge: toMediaQuery(ScreenMinWidth.xlarge),
};
