/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 6H6v12h12V6zM6 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H6z"
            fill={color}
        />
        <path
            d="M13 12a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0zM7.5 12a1.75 1.75 0 1 1 3.5 0 1.75 1.75 0 0 1-3.5 0z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
