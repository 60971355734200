import React from 'react';
import Switch, {SwitchProps} from '@material-ui/core/Switch';
import {makeStyles} from '@material-ui/styles';
import {Theme} from '@anywhere-expert/colors';

const switchUseStyles = (overrideCheckedColor?: string) =>
    makeStyles((theme: Theme) => ({
        switchBase: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        input: {
            backgroundColor: theme.colors.contrast.border,
            color: theme.colors.contrast.secondary,
        },
        checked: {
            color: overrideCheckedColor || theme.colors.semantic.green.deep,
        },
        track: {
            backgroundColor:
                theme.themeName === 'light' ? theme.colors.neutral.whites.w10 : theme.colors.neutral.blacks.b20,
            opacity: 1,
        },
    }));

type Props = SwitchProps & {checkedColor?: string};

const SwitchComp = ({checkedColor, ...props}: Props) => {
    const classes = switchUseStyles(checkedColor)();

    return <Switch {...props} classes={classes} color="default" />;
};

export default SwitchComp;
