import React, {useContext, FunctionComponent, useMemo, useCallback} from 'react';
import {observer} from 'mobx-react';
import {ThemeProvider as StyledComponentsThemeProvider} from 'styled-components';
import {StylesProvider, ThemeProvider as MuiStylesThemeProvider} from '@material-ui/styles';
import {UserStorageStore} from '@anywhere-expert/user-storage-store';
import {Theme, light, dark} from './themes';

export type ThemeProps = {
    theme: Theme;
    toggleTheme: () => void;
    themeName: string;
};

export const ThemeContext = React.createContext<ThemeProps>({
    theme: light,
    toggleTheme: () => null,
    themeName: 'light',
});

export const getTheme = (name: string) => {
    switch (name) {
        case 'dark':
            return dark;
        default:
            return light;
    }
};

interface ThemeProviderProps {
    children: React.ReactElement<any>;
}

export const AppThemeProvider = observer(({children}) => {
    const themeName = UserStorageStore.getValue('theme', 'light');

    const toggleTheme = useCallback(() => {
        const newThemeName = themeName === 'light' ? 'dark' : 'light';

        UserStorageStore.setValue('theme', newThemeName);
    }, [themeName]);

    const themeValue = useMemo(
        () => ({
            toggleTheme,
            themeName,
            theme: getTheme(themeName),
        }),
        [toggleTheme, themeName]
    );

    return (
        <StylesProvider injectFirst>
            <ThemeProviders {...themeValue}>{children}</ThemeProviders>
        </StylesProvider>
    );
});

export const InvertedThemeProvider: FunctionComponent<ThemeProviderProps> = ({children}) => {
    const [, , themeName] = useThemeContext();

    const invertedThemeName = themeName === 'light' ? 'dark' : 'light';

    const state = {
        theme: getTheme(invertedThemeName),
        toggleTheme: () => null,
        themeName: invertedThemeName,
    };

    return <ThemeProviders {...state}>{children}</ThemeProviders>;
};

export const ThemeProviders: React.FunctionComponent<ThemeProps> = ({children, ...state}) => (
    <ThemeContext.Provider value={state}>
        <StyledComponentsThemeProvider theme={state.theme}>
            <MuiStylesThemeProvider theme={state.theme}>{children}</MuiStylesThemeProvider>
        </StyledComponentsThemeProvider>
    </ThemeContext.Provider>
);

export const LightThemeProvider: React.FunctionComponent = ({children}) => (
    <ThemeProviders theme={light} toggleTheme={() => null} themeName="light">
        {children}
    </ThemeProviders>
);

export const DarkThemeProvider: React.FunctionComponent = ({children}) => (
    <ThemeProviders theme={dark} toggleTheme={() => null} themeName="dark">
        {children}
    </ThemeProviders>
);

export function useTheme() {
    const {theme} = useContext(ThemeContext);
    return theme;
}

export function useThemeContext(): [Theme, () => void, string] {
    const {theme, toggleTheme, themeName} = useContext(ThemeContext);
    return [theme, toggleTheme, themeName];
}
