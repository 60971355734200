import {UserManager, Log, WebStorageStateStore} from 'oidc-client';
import logger from '@anywhere-expert/logging';
import {setUserProfile, userProfile$} from '../api/user-profile';
import {userProfileCreator, User} from '../api';
import queryString from 'query-string';

// TODO: Remove this, added for debugging token expiration issue
const loginDebugCache: Storage = window.localStorage;
const useShortTokenDebuggingParams = queryString.parse(location.search).useShortTokenDebugging;
useShortTokenDebuggingParams == 'True' && loginDebugCache.setItem('isShortTokenDebuggingEnabled', JSON.stringify(true));
useShortTokenDebuggingParams == 'False' &&
    loginDebugCache.setItem('isShortTokenDebuggingEnabled', JSON.stringify(false));
const isShortTokenDebuggingEnabled = JSON.parse(loginDebugCache.getItem('isShortTokenDebuggingEnabled') || 'false');

const homeUserStore = new WebStorageStateStore({prefix: 'oidc.home'});

const silentRedirectUri = new URL('silent_auth.html', window.location.origin).href;

let silentRenewMaxRetries = 5;
let silentRenewRetryAttemt = 0;
Log.level = Log.WARN;
if (logger) {
    Log.logger = logger;
}

if (isShortTokenDebuggingEnabled) {
    Log.level = Log.DEBUG;
} else if (process.env.NODE_ENV === 'development') {
    // If you're having auth problems, set this to debug
    Log.level = Log.INFO;
}

localStorage.setItem('BASE_PATH', JSON.stringify(process.env.BASE_PATH));

export const homeUserManager = new UserManager({
    authority: process.env.AUTH_API_BASE_URL + '/openid',
    client_id: isShortTokenDebuggingEnabled ? 'tech-app-web-short-token' : 'tech-app-web',
    client_secret: 'c0818058-2218-47d6-959b-751e154c281f',
    response_type: 'token id_token',
    scope: 'openid profile email expert-identity expert-resource home',
    redirect_uri: new URL('auth', window.location.origin).href,
    post_logout_redirect_uri: window.location.origin,
    loadUserInfo: false,
    automaticSilentRenew: true,
    includeIdTokenInSilentRenew: false,
    prompt: 'select_account',
    silent_redirect_uri: silentRedirectUri,
    userStore: homeUserStore,
    accessTokenExpiringNotificationTime: (isShortTokenDebuggingEnabled ? 1 : 3) * 60, // 3 minutes before the access token gets expired, start to try to renew the token
});

homeUserManager.events.addAccessTokenExpiring(async () => {
    const user = await homeUserManager.getUser();
    logger.info('UserManager: Access token expiring', {extra: {user}});
});

homeUserManager.events.addAccessTokenExpired(async () => {
    const user = await homeUserManager.getUser();
    logger.info('UserManager: Access token expired - login required', {extra: {user}});
    await removeStoredTokens();

    setUserProfile({...userProfile$.value, loginRequired: true, accessToken: undefined} as User);

    silentRenewRetryAttemt = 0;
    homeUserManager.stopSilentRenew();
});

homeUserManager.events.addUserLoaded(homeTokens => {
    silentRenewRetryAttemt = 0;
    if (homeTokens) {
        logger.info('UserManager: User loaded', {extra: {silentRenewRetryAttemt}});

        const userProfile = userProfileCreator(homeTokens.access_token, homeTokens.id_token);
        setUserProfile(userProfile);
    } else {
        logger.warn('UserManager: Empty homeTokens');
    }
});
homeUserManager.events.addSilentRenewError(err => {
    logger.warn('UserManager: Failed to silent renew', {err, extra: {silentRenewRetryAttemt}});

    homeUserManager.stopSilentRenew();
    if (silentRenewRetryAttemt < silentRenewMaxRetries) {
        silentRenewRetryAttemt++;
        homeUserManager.startSilentRenew();
    }
});

export async function getStoredTokens() {
    const user = await homeUserManager.getUser();
    return user && {homeTokens: {access_token: user.access_token, id_token: user.id_token}};
}

export async function removeStoredTokens() {
    try {
        await homeUserManager.removeUser();
    } catch (error) {}
}
