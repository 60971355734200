import React from 'react';
import styled from 'styled-components';
import {PlusIcon, MinusIcon} from '@anywhere-expert/icons';
import MUISlider, {SliderProps} from '@material-ui/core/Slider';
import {makeStyles} from '@material-ui/styles';
import {Theme, LightThemeProvider} from '@anywhere-expert/colors';
import CoreIconButton from './CoreIconButton';
import {getBoxShadowStyle} from './shared/styles';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    ${({theme}) => getBoxShadowStyle(theme)};
    background-color: ${({theme}: {theme: Theme}) => theme.colors.neutral.white};
    border-radius: 20px;
    padding: 4px;
    opacity: 0.6;
    &:hover {
        opacity: 1;
    }
`;

const sliderUserStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '160px',
        margin: '0px 12px',
    },
    thumb: {
        backgroundColor: theme.colors.neutral.black,
    },
    track: {
        backgroundColor: theme.colors.neutral.black,
    },
    rail: {
        backgroundColor: theme.colors.neutral.black,
        opacity: 0.3,
    },
}));

export type CoreSliderProps = SliderProps & {
    onClickMinus?: () => void;
    onClickPlus?: () => void;
    buttonsOnly?: boolean;
    onSlide?: (x: number) => void;
};

const CoreSlider = ({onClickMinus, onClickPlus, onSlide, buttonsOnly, ...others}: CoreSliderProps) => {
    const sliderClasses = sliderUserStyles();

    const onChange = React.useCallback((_e, value) => onSlide?.(value), [onSlide]);

    return (
        <LightThemeProvider>
            <Container>
                {onClickMinus && <CoreIconButton icon={MinusIcon} onClick={onClickMinus} variant="icon" size="s" />}
                {!buttonsOnly && (
                    <MUISlider min={1} max={3} step={0.1} classes={sliderClasses} onChange={onChange} {...others} />
                )}
                {onClickPlus && <CoreIconButton icon={PlusIcon} onClick={onClickPlus} variant="icon" size="s" />}
            </Container>
        </LightThemeProvider>
    );
};

export default CoreSlider;
