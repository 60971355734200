/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M20.794 16.157c1.187 0 2.15-.948 2.15-2.117 0-1.17-.963-2.118-2.15-2.118-1.187 0-2.15.948-2.15 2.118 0 1.169.963 2.117 2.15 2.117zM4.797 12.318c1.187 0 2.15-.947 2.15-2.117s-.963-2.117-2.15-2.117c-1.187 0-2.149.948-2.149 2.117 0 1.17.962 2.117 2.15 2.117zM22.327 10.527A1.66 1.66 0 0 0 24 8.879a1.66 1.66 0 0 0-1.673-1.648 1.66 1.66 0 0 0-1.673 1.648c0 .91.75 1.648 1.673 1.648zM3.173 17.234a1.66 1.66 0 0 0 1.672-1.648 1.66 1.66 0 0 0-1.672-1.648A1.66 1.66 0 0 0 1.5 15.586c0 .91.749 1.648 1.673 1.648zM12.796 21a1.27 1.27 0 0 0 1.28-1.262 1.27 1.27 0 0 0-1.28-1.261c-.707 0-1.28.564-1.28 1.26 0 .698.573 1.262 1.28 1.262zM12.796 5.523a1.27 1.27 0 0 0 1.28-1.262A1.27 1.27 0 0 0 12.796 3c-.707 0-1.28.565-1.28 1.261 0 .697.573 1.262 1.28 1.262zM12.797 17.757a.657.657 0 0 1-.662-.652v-3.2c0-.36.296-.653.662-.653.366 0 .662.292.662.652v3.199a.657.657 0 0 1-.662.654zM12.797 10.718a.657.657 0 0 1-.662-.652V6.962c0-.36.296-.652.662-.652.366 0 .662.292.662.652v3.104c0 .36-.296.652-.662.652zM8.797 7.528A1.66 1.66 0 0 0 10.47 5.88a1.66 1.66 0 0 0-1.673-1.648A1.66 1.66 0 0 0 7.125 5.88c0 .91.748 1.648 1.672 1.648zM8.798 18.619a.987.987 0 0 0 .994-.98c0-.54-.445-.979-.994-.979a.987.987 0 0 0-.994.98c0 .54.445.979.994.979zM8.798 10.602a.657.657 0 0 1-.662-.652v-.853c0-.36.296-.652.662-.652.366 0 .662.291.662.652v.853c0 .36-.296.652-.662.652zM8.798 15.816a.657.657 0 0 1-.662-.652V11.88c0-.36.296-.652.662-.652.366 0 .662.292.662.652v3.282a.657.657 0 0 1-.662.654zM16.795 7.88a.987.987 0 0 0 .994-.979c0-.54-.445-.98-.994-.98a.987.987 0 0 0-.994.98c0 .54.445.98.994.98zM16.795 20.009a1.66 1.66 0 0 0 1.672-1.648 1.66 1.66 0 0 0-1.672-1.648 1.66 1.66 0 0 0-1.673 1.648c0 .91.75 1.648 1.673 1.648zM16.796 12.633a.657.657 0 0 1-.662-.652V9.331c0-.36.296-.652.662-.652.366 0 .662.292.662.652v2.648c0 .36-.296.652-.662.652zM16.796 15.851a.657.657 0 0 1-.662-.652v-1.266c0-.36.296-.652.662-.652.366 0 .662.292.662.652V15.2c0 .36-.296.652-.662.652z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
