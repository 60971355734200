/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M12 4a7.943 7.943 0 0 0-5.65 2.351L9 9H3V3l1.937 1.938A9.93 9.93 0 0 1 12 2c5.515 0 10 4.486 10 10h-2c0-4.411-3.588-8-8-8zM12 20a7.943 7.943 0 0 0 5.649-2.35L15 15h6v6l-1.938-1.938A9.927 9.927 0 0 1 12 22C6.485 22 2 17.515 2 12h2c0 4.41 3.588 8 8 8z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
