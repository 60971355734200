/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import {useTheme} from '@anywhere-expert/colors';

const Icon = () => {
    const {
        colors: {
            neutral: {black},
        },
    } = useTheme();

    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.4317 41.835C19.1804 43.95 26.1199 38.6614 27.0921 37.1528C27.0921 37.1528 36.1737 37.7365 38.7702 36.0214C41.391 34.2903 41.8771 31.5542 42.4359 28.7305C43.5156 19.584 43.271 11.1941 39.6932 9.02785C34.1475 5.67047 14.1714 4.59041 7.75744 8.46544C3.99097 10.7505 4.65915 28.2344 6.36507 32.3534C7.00708 33.9138 7.52066 34.5202 8.77411 35.097C10.2846 35.7911 15.2514 36.0735 16.9508 36.8325C17.1706 36.9307 17.3357 37.0368 17.4317 37.1528C17.4623 37.1824 16.8508 41.1325 17.4317 41.835Z"
                stroke={black}
                strokeWidth="1.5"
                strokeMiterlimit="9.4426"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g clipPath="url(#clip0_901:63221)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26.0806 20.285C26.0806 21.296 26.1068 22.1391 25.5825 23.0369C25.1593 23.7669 24.489 24.2158 23.74 24.2158C22.7177 24.2158 22.1222 23.4566 22.1222 22.3362C22.1222 20.1244 24.1556 19.7229 26.0806 19.7229V20.285ZM28.7656 26.6101C28.5896 26.7633 28.3349 26.7743 28.1365 26.6721C27.2527 25.9567 27.0954 25.6246 26.6086 24.9421C25.1481 26.3947 24.1145 26.829 22.2196 26.829C19.9802 26.829 18.2351 25.4822 18.2351 22.7851C18.2351 20.6792 19.4073 19.2448 21.0737 18.5441C22.5192 17.9236 24.5377 17.8141 26.0806 17.6426V17.3068C26.0806 16.6899 26.1292 15.96 25.7585 15.4272C25.4327 14.949 24.811 14.7519 24.2643 14.7519C23.2495 14.7519 22.3432 15.2592 22.1222 16.3104C22.0772 16.544 21.9013 16.774 21.6616 16.7849L19.0777 16.5148C18.8605 16.4673 18.6209 16.2958 18.6808 15.9709C19.2762 12.9198 22.1035 12 24.6349 12C25.9307 12 27.6233 12.3358 28.6457 13.2921C29.9414 14.4709 29.8178 16.0439 29.8178 17.7557V21.7996C29.8178 23.015 30.3346 23.5478 30.8214 24.2048C30.9936 24.4384 31.0311 24.7195 30.8139 24.8946C30.2709 25.3362 29.3047 26.1575 28.773 26.6174L28.7655 26.6101"
                    fill={black}
                />
                <path
                    d="M31.6662 28.147C23.3593 32 18.204 28.7763 14.9039 26.8183C14.6997 26.6949 14.3526 26.8472 14.6538 27.1843C15.7532 28.4835 19.3562 31.6151 24.0592 31.6151C28.7655 31.6151 31.5653 29.1123 31.9155 28.6757C32.2634 28.2428 32.0177 28.004 31.6661 28.147H31.6662ZM33.9991 26.8913C33.776 26.6082 32.6427 26.5555 31.9294 26.6409C31.215 26.7238 30.1428 27.1493 30.236 27.4048C30.2838 27.5005 30.3815 27.4576 30.8722 27.4146C31.3643 27.3667 32.7429 27.1971 33.0301 27.5631C33.3187 27.9316 32.5904 29.6871 32.4574 29.9702C32.3289 30.2533 32.5065 30.3263 32.7479 30.1377C32.986 29.9492 33.417 29.4611 33.7062 28.7703C33.9934 28.0758 34.1686 27.1069 33.999 26.8913H33.9991Z"
                    fill="#FF9900"
                />
            </g>
            <defs>
                <clipPath id="clip0_901:63221">
                    <rect width="20.521" height="20" fill={black} transform="translate(14 12)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Icon;
