import React, {memo} from 'react';

const Icon = ({color}) => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.242 33.38c-.225.303-.494.536-.825.523-.225-.006-.419-.194-.513-.407-.531-1.223-.606-3.14-.775-4.46-.328-5.562-.411-7.187.41-13 .82-5.815 3.437-9.328 9.907-9.716 9.455-.567 11.78 5.637 11.677 13.76 0 4.383-.013 7.038-.013 10.511 0 1.061.032 3.053-1.562 3.073-1.219.013-1.719-1.708-2.3-2.458-.144-.181-.3-.369-.531-.434-.363-.103-.725.143-.931.44-.594.86-1.407 3.215-2.894 2.439a1.755 1.755 0 01-.532-.46c-.993-1.183-1.668-3.525-3.356-1.623-.4.446-.662.996-1.05 1.449-.381.453-.937.815-1.531.737-.737-.103-1.212-.815-1.637-1.43-.425-.614-1.05-1.254-1.75-1.125-.52.097-.85.582-1.125 1.029-.175.284-.388.77-.67 1.151z"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.25"
        />
        <path
            d="M11.125 33.59c.286-.28.502-.638.68-.847.28-.328.617-.685 1.145-.756.711-.095 1.347.376 1.78.828.431.452.915.975 1.665 1.05.604.058 1.17-.209 1.557-.541.394-.333.661-.738 1.068-1.066 1.716-1.398 2.403.323 3.413 1.194.153.133.325.257.54.338 1.514.57 2.34-1.16 2.944-1.794.21-.218.578-.4.947-.323.235.048 1.136 1.008 1.282 1.142M24.478 18.431c-.09-1.496-.2-2.406-.22-3.757zM16.328 18.21c.077-1.497.177-2.408.185-3.76z"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="1.25"
        />
        <path
            d="M22.804 22.125c-.69-.812-1.12-1.047-2.361-1.022-1.24.025-1.952.435-2.405 1.266"
            stroke={color}
            strokeLinecap="round"
            strokeWidth=".938"
        />
    </svg>
);

export default memo(Icon);
