/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M12 20H2.488l1.478-3.622.18-.443-.231-.418A7.238 7.238 0 0 1 3 12c0-4.322 3.928-8 9-8s9 3.678 9 8-3.928 8-9 8z"
                stroke={color}
                strokeWidth="2"
                fill="none"
            />
        </BaseIcon>
    ))
);

export default Icon;
