/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.861 16.276l-1.51-1.51A4 4 0 0 1 8 11V9.414L4.293 5.707a1 1 0 0 1 1.414-1.414l14 14a1 1 0 0 1-1.414 1.414l-1.973-1.973A7.954 7.954 0 0 1 13 18.938V20h2a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2h2v-1.062A8.001 8.001 0 0 1 4 11a1 1 0 1 1 2 0 6 6 0 0 0 8.861 5.276zm4.538-2.23a1 1 0 1 1-1.849-.762A5.98 5.98 0 0 0 18 11a1 1 0 1 1 2 0 7.978 7.978 0 0 1-.6 3.046zM8.423 4.208A4 4 0 0 1 16 6v5c0 .246-.022.487-.065.721L8.423 4.208z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
