/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M12.042 2c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.478-10-10-10zm4.707 13.293l-1.414 1.414-3.293-3.292-3.293 3.292-1.414-1.414L10.627 12 7.334 8.707l1.414-1.413 3.293 3.293 3.293-3.293 1.414 1.413L13.456 12l3.293 3.293z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
