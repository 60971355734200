import React from 'react';
import styled from 'styled-components';
import {Size} from '../compoundTypes';
import CoreIcon, {CoreIconProps} from '../../CoreIcon';

export type CompoundButtonIconProps = {
    size: Size;
    isInProgress?: boolean;
    location?: 'left' | 'right';
    hasText?: boolean;
    [x: string]: any;
    icon: CoreIconProps['icon'];
    iconProps?: Omit<CoreIconProps, 'icon'>;
};

const IconContainer = styled.div<CompoundButtonIconProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${({isInProgress}) => (isInProgress ? '0' : '1')};
    margin-left: ${({hasText, location}) => (hasText && location === 'left' ? '8px' : '0px')};
    margin-right: ${({hasText, location}) => (hasText && location === 'right' ? '8px' : '0px')};
    width: ${({size}) => (size === 's' ? '16px' : '24px')};
    height: ${({size}) => (size === 's' ? '16px' : '24px')};
`;

const CompoundIconContainer = ({
    size,
    location = 'left',
    isInProgress,
    icon,
    iconProps = {},
    children,
    hasText = true,
    ...others
}: CompoundButtonIconProps) => {
    return (
        <IconContainer isInProgress={isInProgress} hasText={hasText} location={location} size={size} {...others}>
            <CoreIcon icon={icon} {...iconProps} />
        </IconContainer>
    );
};

export default CompoundIconContainer as React.ComponentType<CompoundButtonIconProps>;
