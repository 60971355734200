/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M20.42 4.593c1.994 2.012 1.823 5.151-.487 7.482l-6.32 6.378c-2.905 2.931-7.028 3.128-9.607.526-2.58-2.604-2.385-6.768.52-9.7L9.59 4.17l1.16 1.17-5.066 5.113c-2.214 2.233-2.449 5.315-.475 7.306 1.975 1.993 5.032 1.755 7.246-.479l6.32-6.378c.67-.676 1.18-1.538 1.323-2.44.144-.91-.088-1.85-.882-2.65-.794-.802-1.727-1.036-2.63-.89-.895.144-1.75.658-2.42 1.334l-6.32 6.379c-.23.232-.6.66-.783 1.17a1.664 1.664 0 00-.09.839c.05.298.196.591.467.864.27.273.562.42.858.472.294.05.579.002.835-.09.506-.185.929-.56 1.16-.792l5.806-5.86 1.16 1.17-5.81 5.863c-.863.872-1.818 1.339-2.724 1.414-.902.075-1.774-.237-2.488-.958-.715-.72-1.024-1.601-.95-2.514.074-.916.538-1.88 1.402-2.751l6.32-6.379c2.31-2.33 5.417-2.502 7.41-.49z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
