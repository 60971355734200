/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path d="M20 5.414L14.707 10.707L13.293 9.293L18.586 4H16V2H22V8H20V5.414Z" fill={color} />
        <path d="M5.414 20H8V22H2V16H4V18.586L9.293 13.293L10.707 14.707L5.414 20Z" fill={color} />
        <path d="M4 5.414L9.293 10.707L10.707 9.293L5.414 4H8V2H2V8H4V5.414Z" fill={color} />
        <path d="M16 22V20H18.586L13.293 14.707L14.707 13.293L20 18.586V16H22V22H16Z" fill={color} />
    </BaseIcon>
));

export default Icon;
