import {serializeError} from 'serialize-error';
import axios, {AxiosInstance} from 'axios';
import {configuration as config} from '@anywhere-expert/core';
import {LogLevel, LogData} from '../types';
import {fullstory} from '@anywhere-expert/fullstory';

type Context = {OsType?: string; appVersion?: string};

class Logger {
    private context: Context;
    private axiosClient: AxiosInstance;

    constructor(options: {url: string; context: Context}) {
        this.context = options.context || {};
        this.axiosClient = axios.create({
            baseURL: options.url,
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }

    log = async (logLevel: LogLevel, message: string, logInfo: LogData = {}) => {
        if (logInfo.err instanceof Error) {
            logInfo.err = serializeError(logInfo.err) as Error;
        }

        if (typeof message !== 'string') {
            console.error('Cannot log message of type' + typeof message);
            return;
        }

        const headers = {
            'X-Api-Client': this.context.OsType,
            'X-Api-Client-Version': this.context.appVersion,
        };

        let data: any = {};
        if (logInfo.err) data.error = logInfo.err;
        if (logInfo.extra) data.extra = logInfo.extra;

        if (logLevel === 'error') {
            data.fullstoryUrl = fullstory.isInitialized() && fullstory.getCurrentSessionURL();
        }

        try {
            const body = JSON.stringify({
                Data: {...data, ...this.context},
                Timestamp: new Date().toISOString(),
                ApplicationName: 'AnywhereExpertApp',
                Content: message,
            });

            await this.axiosClient.post(logLevel === 'warn' ? 'warning' : logLevel, body, {headers});
        } catch (err) {
            console.warn('Error sending log to LoggingApi', err.message, logLevel, message, logInfo);
        }
    };

    updateContext = (update: Context) => {
        this.context = {...this.context, ...update};
    };
}

const logger = new Logger({
    url: config.loggingUrl,
    context: {},
});

export const updateContext = logger.updateContext;

export default logger.log;
