/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.998 5h2c1.654 0 3 1.346 3 3s-1.346 3-3 3v1h-2v-1h-2V9h4a1 1 0 0 0 0-2h-2c-1.654 0-3-1.346-3-3s1.346-3 3-3V0h2v1h2v2h-4a1 1 0 0 0 0 2zm-16 19l2-1.5L8.331 20h6.667c1.103 0 2-.897 2-2v-4h-1c-1.1 0-2-.9-2-2V7h-12c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h1v4zm4.133-5.6L4.998 20v-2h-3V9h10v3a4.009 4.009 0 0 0 3 3.873V18H7.664l-.533.4z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
