import * as React from 'react';
import styled from 'styled-components';
import {fade} from '@material-ui/core/styles/colorManipulator';
import {Theme, palette} from '@anywhere-expert/colors';
import CoreText from './CoreText';
import CoreIcon from './CoreIcon';
import {CheckMarkIcon} from '@anywhere-expert/icons';

type TextSize = 's' | 'm';
export type TextBubbleType = 'expert' | 'customer';

export type CoreTimelineTextBubbleProps = {
    textBubbleType?: TextBubbleType;
    size?: TextSize;
    timestamp?: string;
    seenAt?: string;
    timestampColor?: string;
    flash?: boolean;
    isHover?: boolean;
    [x: string]: any;
};

const TimestampsContainer = styled.div<{hasSeenAt: boolean; textBubbleType: TextBubbleType}>`
    display: flex;
    flex-direction: row;
    min-width: 50px;
    align-items: center;
    align-self: ${({hasSeenAt}) => (hasSeenAt ? 'normal' : 'flex-end')};
    justify-content: ${({hasSeenAt}) => (hasSeenAt ? 'space-between' : 'flex-end')};
    margin-top: 2px;
    ${({textBubbleType}) =>
        textBubbleType === 'expert' && `span {color: ${fade(palette.neutral.white, 0.6)}!important;};`};
`;

const SeenAtContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 6px;
`;

const CoreTimelineTextBubbleContainer = styled.div<{
    textBubbleType: TextBubbleType;
    size: TextSize;
    flash: boolean;
    isHover?: boolean;
}>`
    padding: 8px 12px;
    border-radius: 8px;
    flex-direction: column;
    background-color: ${props => getBackgroundColor(props)};
    ${({theme, textBubbleType}) => getExtraStyle(theme, textBubbleType)}
    max-width: ${({size}) => (size === 's' ? '228px' : '565px')};
    max-height: ${({size}) => (size === 's' ? '38px' : '1000px')};
    display: flex;
    text-align: left;
    line-height: 20px;
    margin: 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;
`;

const Timestamps = ({
    timestamp,
    seenAt,
    extraInfoComponent: ExtraInfoComponent,
    textBubbleType,
}: {
    timestamp?: string;
    seenAt?: string;
    extraInfoComponent?: React.ComponentType<any>;
    textBubbleType: TextBubbleType;
}) => (
    <TimestampsContainer hasSeenAt={!!seenAt} textBubbleType={textBubbleType}>
        {ExtraInfoComponent && <ExtraInfoComponent />}
        {seenAt && (
            <SeenAtContainer>
                <CoreIcon size="s" icon={CheckMarkIcon} color={palette.primary.green.bright} />
                <CoreText size="xs" textType="tertiary" italic>
                    {seenAt}
                </CoreText>
            </SeenAtContainer>
        )}
        <CoreText size="xs" textType="tertiary" italic>
            {timestamp}
        </CoreText>
    </TimestampsContainer>
);

const CoreTimelineTextBubble = ({
    size = 's',
    children,
    textBubbleType = 'expert',
    seenAt,
    timestamp,
    flash = false,
    isHover,
    extraInfoComponent = undefined,
    ...others
}: CoreTimelineTextBubbleProps) => {
    return (
        <CoreTimelineTextBubbleContainer
            textBubbleType={textBubbleType}
            size={size}
            flash={flash}
            isHover={isHover}
            {...others}
        >
            {children}
            {!!timestamp && (
                <Timestamps
                    timestamp={timestamp}
                    seenAt={seenAt}
                    textBubbleType={textBubbleType}
                    extraInfoComponent={extraInfoComponent}
                />
            )}
        </CoreTimelineTextBubbleContainer>
    );
};

export default CoreTimelineTextBubble;

export function getBackgroundColor({
    theme,
    textBubbleType,
    flash,
    isHover,
}: {
    theme: Theme;
    textBubbleType: TextBubbleType;
    flash: boolean;
    isHover?: boolean;
}) {
    if (isHover) {
        return palette.neutral.black;
    }

    return textBubbleType === 'expert'
        ? flash
            ? theme.colors.neutral.grey.deeper
            : theme.colors.foreground.secondary
        : flash
        ? theme.colors.neutral.grey.deep
        : theme.colors.foreground.primary;
}
function getExtraStyle(theme: Theme, textBubbleType: TextBubbleType) {
    return theme.themeName === 'dark' && textBubbleType === 'customer'
        ? `border: 2px solid rgba(255, 255, 255, 0.03);`
        : null;
}
