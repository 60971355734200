/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path d="M3 11v2h18v-2H3z" fill={color} />
        </BaseIcon>
    ))
);

export default Icon;
