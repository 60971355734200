/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                d="M15.55 7.405L18 11.1l-3.09 7.646L10.717 22l-7.778-3.317L1 8.96l2.751-.443 1.755 2.91L8.394 4.36l3.665 1.554-3.199 7.502m0 .017l3.199-7.5 3.491 1.436-3.217 7.547m-8.49-9.968a5.336 5.336 0 011.354-2.34 5.423 5.423 0 012.337-1.389 5.47 5.47 0 012.725-.086c.9.202 1.734.628 2.421 1.239"
                fillOpacity="0"
                stroke={color}
                strokeWidth="2"
            />
        </BaseIcon>
    ))
);

export default Icon;
