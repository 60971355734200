import loggingApi from './loggers/logLoggingApi';
import logMonitor from './loggers/logMonitor';
import logConsole from './loggers/logConsole';
import errorLogConsole from './loggers/errorLogConsole';
import logBugsnag, {init, setUser} from './src/bugsnag';

export * from './shared';
export {default} from './shared';

export const bugsnagProvider = {init, setUser};
export const loggers = {loggingApi, logConsole, logMonitor, logBugsnag, errorLogConsole};

export {LoggingErrorBoundary} from './src/LoggingErrorBoundary';
