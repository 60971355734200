import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M8 4H21V17C22.1046 17 23 16.1046 23 15V4C23 2.89543 22.1046 2 21 2H10C8.89543 2 8 2.89543 8 4Z"
            fill={color}
        />
        <path
            d="M11.5 15.5C12.6046 15.5 13.5 14.6046 13.5 13.5C13.5 12.3954 12.6046 11.5 11.5 11.5C10.3954 11.5 9.5 12.3954 9.5 13.5C9.5 14.6046 10.3954 15.5 11.5 15.5Z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 8C4 6.89543 4.89543 6 6 6H17C18.1046 6 19 6.89543 19 8V19C19 20.1046 18.1046 21 17 21H6C4.89543 21 4 20.1046 4 19V8ZM6 8H17V19H6V8Z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
