import {observable, action, makeObservable} from 'mobx';
import {ModalContentComponent, OpenModalOptions, UpdateModalOptions} from './types';
import ModalModel from './ModalModel';

class ModalStore {
    modals = new Map<string, ModalModel>();

    open = (component: ModalContentComponent, options?: OpenModalOptions) => {
        const newModal = new ModalModel(component, options);
        this.modals.set(newModal.id, newModal);

        return newModal.id;
    };

    close = (id: string) => {
        const modal = this.modals.get(id);

        if (modal) {
            modal.close();

            setTimeout(() => {
                window.requestAnimationFrame(() => {
                    this.remove(id);
                });
            }, 700);
        }
    };

    update = (id: string, options?: UpdateModalOptions, mergeOldOptions: boolean = true) => {
        const modal = this.modals.get(id);

        if (modal) {
            modal.update(options, mergeOldOptions);
        }
    };

    private remove = (id: string) => {
        this.modals.delete(id);
    };

    constructor() {
        makeObservable<ModalStore, 'remove'>(this, {
            modals: observable,
            open: action,
            close: action,
            update: action,
            remove: action,
        });
    }
}

const store = new ModalStore();

export default store;

export const openModal = store.open;
export const closeModal = store.close;
export const updateModal = store.update;
