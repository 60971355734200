import * as React from 'react';
import {CoreTextArea} from './CoreTextArea';
import CoreText from './CoreText';
import styled from 'styled-components';
import debounce from 'lodash.debounce';

const debounceTime = 1000;

const TextAreaWithInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const InputInfo = styled.div`
    display: flex;
    flex: 1;
    margin-bottom: 14px;
    justify-content: space-between;
`;

const WarningMessage = styled(CoreText)`
    opacity: 0;
    display: flex;
    user-select: none;
    justify-content: flex-start;
    ${({isInputValid}) =>
        !isInputValid &&
        `
        user-select: default;
        opacity: 1;
        transition: opacity 100ms linear;
    `};
`;

const isMinCharacters = (min: number, text?: string) => !!text && text.length >= min;
const isMaxCharacters = (max: number, text?: string) => !!text && text.length > max;
const validateInput = (min, max, text) => isMinCharacters(min, text) && !isMaxCharacters(max, text);

const debouncedValidation = debounce((setIsInputValid, min, max, value) => {
    setIsInputValid(validateInput(min, max, value));
}, debounceTime);

type WarningMessageType = 'atLeastMore' | 'minimumCharacter';

export type CoreInputAreaProps = {
    value?: string;
    min?: number;
    max?: number;
    disabled?: boolean;
    onChange: (value: string, isValid: boolean) => void;
    placeholder?: string;
    warningMessageType?: WarningMessageType;
};

const useFocus = (disabled?: boolean) => {
    const inputEl = React.useRef<HTMLTextAreaElement>(null);

    React.useEffect(() => {
        if (!disabled && inputEl) {
            inputEl.current && inputEl.current.focus();
        }
    }, [disabled]);

    return inputEl;
};

const getWarningText = (min, valueLength, warningMessageType) => {
    const atLeastMore = min - valueLength;
    if (atLeastMore <= 0) {
        return;
    }
    switch (warningMessageType) {
        case 'atLeastMore':
            return `${atLeastMore} more character${atLeastMore === 1 ? '' : 's'} needed...`;
        case 'minimumCharacter':
            return `${min} character${min === 1 ? '' : 's'} minimum`;
    }
};

const CoreInputArea = ({
    value,
    disabled,
    onChange,
    min = 50,
    max = 1250,
    placeholder,
    warningMessageType = 'atLeastMore',
    ...others
}: CoreInputAreaProps) => {
    const [isInputValid, setIsInputValid] = React.useState(true);
    const inputEl = useFocus(disabled);

    const onDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        const isEnoughChars = !!value && isMinCharacters(min, value);
        setIsInputValid(true);
        debouncedValidation(setIsInputValid, min, max, value);
        onChange(value.slice(0, max), isEnoughChars);
    };

    const valueLength = !!value ? value!.length : 0;
    const warningText = getWarningText(min, valueLength, warningMessageType);

    return (
        <TextAreaWithInfo>
            <CoreTextArea
                value={value}
                ref={inputEl}
                isInputValid={isInputValid}
                placeholder={placeholder}
                onChange={onDescriptionChange}
                disabled={disabled}
                {...others}
            />

            <InputInfo>
                <WarningMessage size="xs" textType="tertiary" isInputValid={isInputValid}>
                    {warningText}
                </WarningMessage>
                <CoreText size="xs" textType="tertiary">
                    {valueLength}/{max}
                </CoreText>
            </InputInfo>
        </TextAreaWithInfo>
    );
};

export default CoreInputArea;
