/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path fillRule="evenodd" clipRule="evenodd" d="M13 5l-6 8h4v6l6-8h-4V5z" fill={color} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 20c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm0-18C6.486 2 2 6.486 2 12c0 5.515 4.486 10 10 10s10-4.485 10-10c0-5.514-4.486-10-10-10z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
