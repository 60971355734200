/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M8 9a7.51 7.51 0 0 0 .531 2.641l-2.976 2.975A10.889 10.889 0 0 1 4 9V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H8zM16 14h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-5c-2.571 0-4.931-.889-6.804-2.367l-4.075 4.074-1.414-1.414 15.971-15.97 1.414 1.414-9.07 9.07C12.131 15.562 13.489 16 15 16v-1a1 1 0 0 1 1-1z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
