/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 4H4v16h16V4H7zM2 4v16c0 1.102.897 2 2 2h16c1.103 0 2-.898 2-2V4c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2zm11.528 5L18 16.174H6l2.478-3.977 1.528 2.45L13.528 9z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
