/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M23.419 10.606l-3-7A.999.999 0 0 0 19.5 3h-14c-.4 0-.762.238-.919.606l-3 7A.998.998 0 0 0 2.5 12h1v8a1 1 0 0 0 1 1h10v-7h4v7h2a1 1 0 0 0 1-1v-8h1a.999.999 0 0 0 .919-1.394zM11.5 18h-5v-4h5v4zm9.483-8h-2.729l-1.428-5h2.015l2.142 5zM8.826 10l1.428-5H11.5v5H8.826zM13.5 5h1.246l1.428 5H13.5V5zM6.159 5h2.015l-1.428 5h-2.73l2.143-5z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
