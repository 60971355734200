/** GENERATED FILE - DO NOT CHANGE **/
/** If you would like to add an icon to AE, please follow the icons/readme.md guide **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(
    React.forwardRef(({color, ...baseIconProps}: IconProps, ref: React.Ref<any>) => (
        <BaseIcon {...baseIconProps} ref={ref}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.707 11.707l-1.414-1.414L13 12.586V4h-2v8.586l-2.293-2.293-1.414 1.414L12 16.414l4.707-4.707z"
                fill={color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 20h12c1.103 0 2-.897 2-2V7c0-1.103-.897-2-2-2h-1v2h1v11H6V7h1V5H6c-1.103 0-2 .897-2 2v11c0 1.103.897 2 2 2z"
                fill={color}
            />
        </BaseIcon>
    ))
);

export default Icon;
