import React from 'react';
import styled from 'styled-components';
import CoreText from './CoreText';
import {palette} from '@anywhere-expert/colors';

type Type = 'light' | 'dark';

const KeysContainer = styled.div`
    display: flex;
`;

const KeyContainer = styled.div<{type: Type}>`
    border-radius: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({theme, type}) =>
        type === 'light' ? theme.colors.neutral.blacks.b20 : theme.colors.neutral.black};
    padding: 0 8px;
    margin-right: 4px;
`;

const Key = styled(CoreText)<{type: Type}>`
    color: ${({theme, type}) => (type === 'dark' ? theme.colors.neutral.white : palette.neutral.white)};
    font-family: Apercu Pro;
`;

export type CoreKeyboardKeysProps = {
    sequence: string[];
    type?: Type;
};

const CoreKeyboardKeys = ({sequence, type = 'light'}: CoreKeyboardKeysProps) => (
    <KeysContainer>
        {sequence.map(key => (
            <KeyContainer key={key} type={type}>
                <Key size="m" type={type}>
                    {key}
                </Key>
            </KeyContainer>
        ))}
    </KeysContainer>
);

export default CoreKeyboardKeys;
