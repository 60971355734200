/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path d="M7.999 1H5c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h1.999v-2H5V3h2.999V1z" fill={color} />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 3h-8c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h8c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zm-8 16h8.001v2H11v-2zm8.001-10v8H11V9h8.001zM19 7h-8V5h8v2z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
