import {updateContext as updateLoggingApiLoggerContext} from './loggers/logLoggingApi';
import {init as initCompositeLogger} from './logComposite';
import {LogLevel} from './types';

/**
 * sets the context and log level for all loggers
 * @memberof logging
 * @name init
 * @param {array} loggers an array of loggers to use
 * @param {object} context an object whos properties will be added as metadata to all log messages
 * @param {string} [logLevel = warn] the minimal level which gets logged
 * @example
 *
 * const context = {
            source: 'anywhere-expert-app',
            platform: 'web'
        }
 *
 * init([loggers.logConsole, loggers.loggingApi], context, 'info')
 */
export default async (loggers, context, logLevel: LogLevel = 'info') => {
    updateLoggingApiLoggerContext(context);
    initCompositeLogger(loggers, logLevel);
};
