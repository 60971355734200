/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.914 9.102l1.414-1.414A15.895 15.895 0 0 0 12.013 3 15.889 15.889 0 0 0 .7 7.688l1.414 1.414A13.908 13.908 0 0 1 12.013 5a13.91 13.91 0 0 1 9.901 4.102zM6.358 13.345a7.942 7.942 0 0 1 5.656-2.343c2.137 0 4.146.832 5.656 2.343l-1.414 1.414a5.96 5.96 0 0 0-4.242-1.757 5.955 5.955 0 0 0-4.242 1.757l-1.414-1.414zm2.656 5.657c0-1.655 1.346-3 3-3 1.655 0 3 1.345 3 3 0 1.654-1.346 3-3 3s-3-1.346-3-3zm2 0c0 .551.449 1 1 1a1.001 1.001 0 1 0-1-1zm9.485-8.486l-1.414 1.414c-3.9-3.899-10.242-3.899-14.142 0l-1.414-1.414c4.679-4.678 12.29-4.679 16.97 0z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
