const client = 'anywhere-expert-web';
const version = '1.0.0';

export const getAppMetadata = () => ({
    version,
    client,
});

export const getXApiClientHeaders = () => ({
    'x-api-client': client,
    'x-api-client-version': version,
});
