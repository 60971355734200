/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path fillRule="evenodd" clipRule="evenodd" d="M9 7.41L14 12l-5 4.59L10.41 18l6-6-6-6L9 7.41z" fill={color} />
    </BaseIcon>
));

export default Icon;
