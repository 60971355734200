import {LogFunc} from '../types';

const isErrorObject = x => x instanceof Error;

const appendMessageToError = (message, e) => {
    if (isErrorObject(e)) {
        if (e.message != message) {
            e.message = `${message}\n${e.message}`;
        }
        return e;
    }
    if (typeof e === 'string') {
        e = `${message}\n${e}`;
        return e;
    }

    return message;
};

const log: LogFunc = async (level, message, data) => {
    switch (level) {
        case 'error': {
            const error = appendMessageToError(message, data);
            console.error(error, data || '');
            break;
        }
        case 'warn': {
            if (isErrorObject(data)) {
                const error = appendMessageToError(message, data);
                console.warn(error);
                return;
            }
            console.warn(message, data || '');
            break;
        }
        default: {
            if (console[level]) {
                console[level](message, data || '');
            }
        }
    }
};

export default log;
