import React from 'react';

const SvgComponent = ({color, ...others}) => (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" {...others}>
        <path
            d="M20.749 4.70697L19.334 3.29297L12.042 10.586L4.74898 3.29297L3.33398 4.70697L10.627 12L3.33398 19.293L4.74898 20.707L12.042 13.414L19.334 20.707L20.749 19.293L13.456 12L20.749 4.70697Z"
            fill={color || '#4C5764'}
        />
    </svg>
);

export default SvgComponent;
