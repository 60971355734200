/** GENERATED FILE - DO NOT CHANGE IMPLANTATION **/

import React from 'react';
import BaseIcon from '../BaseIcon';
import {IconProps} from '../types';

const Icon = React.memo(({color, ...baseIconProps}: IconProps) => (
    <BaseIcon {...baseIconProps}>
        <path
            d="M9 7c0-1.102.897-2 2-2s2 .898 2 2c0 1.103-.897 2-2 2s-2-.897-2-2zM7 10c-1.103 0-2 .898-2 2 0 1.103.897 2 2 2s2-.897 2-2c0-1.102-.897-2-2-2zM14 10c0-1.102.897-2 2-2s2 .898 2 2c0 1.103-.897 2-2 2s-2-.897-2-2z"
            fill={color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 6.486 6.486 2 12 2s10 4.486 10 10c0 1.598-1.308 3-2.8 3h-4.655c-.614 0-.707.219-.739.293v.001a.799.799 0 0 0 .173.872l.988.987a2.78 2.78 0 0 1 .674 2.873 2.78 2.78 0 0 1-2.273 1.881A9.957 9.957 0 0 1 12 22C6.486 22 2 17.514 2 12zm12.545 1H19.2c.389 0 .8-.514.8-1 0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8h.098c.664.002 1.441.003 1.648-.612.188-.557-.373-1.066-.864-1.511a9.098 9.098 0 0 1-.317-.297 2.79 2.79 0 0 1-.606-3.052c.402-.97 1.344-1.528 2.586-1.528z"
            fill={color}
        />
    </BaseIcon>
));

export default Icon;
